import { ApolloError } from '@apollo/client'

// On the server, we overwrite the regular GraphQLError with our own formatting:
// https://github.com/gamma-app/gamma/blob/2ca7a4994e6384c49e83d7a0805c7b40cd86cd10/packages/server/src/app.module.ts#L107-L115
export type FormattedGraphQLError = {
  code: string
  message: string
}

// Event and ErrorEvent are not defined in node v14, so just use Error here instead
const BaseEvent = typeof ErrorEvent === 'undefined' ? Error : ErrorEvent

export class ApolloClientPromiseRejectionEvent extends BaseEvent {}
export class ReactQueryPromiseRejectionEvent extends BaseEvent {}

export const extractGraphQLErrorCodes = (error?: ApolloError) => {
  const graphqlErrors = (error?.graphQLErrors ||
    []) as unknown as FormattedGraphQLError[]
  return graphqlErrors?.map((err) => err.code)
}
