import { AbilityFactory, AppAbility } from '@gammatech/authorization'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import { config } from 'config'
import { Organization } from 'modules/api'
import { RootState } from 'modules/redux'
import { safeGetLocaleStorageValue } from 'utils/hooks/useLocalStorage'
import { USER_SETTINGS_CONSTANTS } from 'utils/userSettingsConstants'

import { FetchUserResponse } from './api/fetchUser'
import { GraphqlUser } from './context/UserContext'
import {
  ensureVisitorIdCookie,
  getAnonymousUser,
  getColorObject,
  getHasLoggedInCookie,
} from './utils'

const GAMMA_ADMIN_EDIT_EMAIL = 'gamma-admin@gamma.app'
const GAMMA_ADMIN_READONLY_EMAIL = 'gamma-admin-readonly@gamma.app'

type UserStatus = 'loggedIn' | 'loggedOut' | 'error' | 'loading'

// TODO(jordan): it feels a little weird to write a cookie as a side-effect
// here but the user reducer is quite early in the user init lifecycle and seems
// good enough
const visitorId = ensureVisitorIdCookie()
const anonymousUser = getAnonymousUser(visitorId)
const color = getColorObject(visitorId)

export type UserState = {
  user: GraphqlUser | undefined
  currentWorkspaceId: string | undefined
  userStatus: UserStatus
  userAbility: AppAbility
  isGammaAdminUser: boolean
  isGammaOrgUser: boolean
  anonymousUser: ReturnType<typeof getAnonymousUser>
  color: { value: string; isDark: boolean }
}

const abilityFactory = new AbilityFactory()
const getInitialUserState = (): UserState => {
  const hasLoggedInCookie = getHasLoggedInCookie()
  let userStatus: UserStatus
  if (config.IS_CLIENT_SIDE) {
    // if we're client side then we can know immediately if we're loggedOut (no cookie)
    // or we should be in the loading status
    userStatus = !hasLoggedInCookie ? 'loggedOut' : 'loading'
  } else {
    // otherwise if we're on the server we dont know the presence of the
    // logged in cookie so assume that we're loading
    userStatus = 'loading'
  }

  return {
    userStatus,
    user: undefined,
    currentWorkspaceId: safeGetLocaleStorageValue(
      USER_SETTINGS_CONSTANTS.currentWorkspaceId,
      undefined
    ),
    // default to empty AppAbility
    userAbility: abilityFactory.createForUser(),
    isGammaAdminUser: false,
    isGammaOrgUser: false,
    // these are generated once and not needed again
    anonymousUser: anonymousUser,
    color,
  }
}

const UserSlice = createSlice({
  name: 'User',
  initialState: getInitialUserState(),
  reducers: {
    userFetched(
      state: UserState,
      action: PayloadAction<{ response: FetchUserResponse }>
    ) {
      const { status, user } = action.payload.response
      state.userStatus = status
      state.user = user ?? undefined
      state.userAbility = abilityFactory.createForUser(
        user ?? undefined,
        config.SHARE_TOKEN
      )
      state.isGammaAdminUser = [
        GAMMA_ADMIN_EDIT_EMAIL,
        GAMMA_ADMIN_READONLY_EMAIL,
      ].includes(user?.email || '')
      state.isGammaOrgUser = user?.email.endsWith('@gamma.app') || false
    },

    setCurrentWorkspaceId(
      state: UserState,
      action: PayloadAction<{
        currentWorkspaceId?: string
      }>
    ) {
      const { currentWorkspaceId } = action.payload
      state.currentWorkspaceId = currentWorkspaceId
    },
  },
})

export const { userFetched, setCurrentWorkspaceId } = UserSlice.actions

type SliceState = Pick<RootState, 'User'>

// Selectors
export const selectUserState = (state: SliceState) => state.User

export const selectUserIsGammaAdminReadOnly = (state: SliceState) =>
  state.User.user?.email === GAMMA_ADMIN_READONLY_EMAIL

export const selectUser = (state: SliceState) => state.User.user

export const selectUserStatus = (state: SliceState) => state.User.userStatus

export const selectUserLocale = (state: SliceState) => {
  const user = selectUser(state)
  return user?.settings?.locale
}

export const selectCurrentWorkspaceId = (state: SliceState) =>
  state.User.currentWorkspaceId

export const selectCurrentWorkspace = createSelector(
  selectUser,
  selectCurrentWorkspaceId,
  (user, currentWorkspaceId) => {
    return user?.workspaceMemberships?.find(
      (membership) => membership.workspace?.id === currentWorkspaceId
    )?.workspace
  }
)

export const selectIsMemberOfCurrentDocWorkspace = (docWorkspaceId: string) =>
  createSelector(selectUser, (user) => {
    if (!user?.workspaceMemberships) return false
    return Boolean(
      user.workspaceMemberships.find(
        (membership) => membership.workspace?.id === docWorkspaceId
      )
    )
  })

export const selectDocOrMostPermissiveWorkspaceForUser = (
  docWorkspaceId?: string
) =>
  createSelector(
    selectUser,
    selectCurrentWorkspace,
    (user, currentWorkspace): Organization | undefined => {
      if (!user || !user.workspaceMemberships) {
        return
      }
      const docWorkspace = user.workspaceMemberships.find(
        (membership) => membership.workspace?.id === docWorkspaceId
      )
      if (docWorkspace) {
        return docWorkspace?.workspace
      }
      const sortedMemberships = [...user.workspaceMemberships].sort((a, b) => {
        const aWorkspaceProducts = new Set(a.workspace?.products)
        const bWorkspaceProducts = new Set(b.workspace?.products)
        if (isEqual(aWorkspaceProducts, bWorkspaceProducts)) {
          return 0
        } else if (aWorkspaceProducts.size) {
          return -1
        }
        return 1
      })
      const mostPermissiveWorkspace = sortedMemberships?.[0]?.workspace
      if (
        mostPermissiveWorkspace?.products?.includes('pro') ||
        mostPermissiveWorkspace?.products?.includes('plus')
      ) {
        return mostPermissiveWorkspace
      }

      return currentWorkspace
    }
  )

// Reducer
export const UserReducer = UserSlice.reducer
