export const GammaIcon = () => {
  return (
    <svg
      viewBox="-10 0 101 101"
      preserveAspectRatio="xMinYMin meet"
      fill="none"
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.792 68.733V43.964L65.08 50.157V62.54"
        fill="currentColor"
      />
      <path
        d="M75.792 68.733V43.964L65.08 50.157V62.54"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.08 62.54L43.469 74.988l.124 12.323 32.2-18.577"
        fill="currentColor"
      />
      <path
        d="M65.08 62.54L43.469 74.988l.124 12.323 32.2-18.577"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.456 68.733L43.593 87.31l-.124-12.323-21.301-12.446"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.168 62.54V37.464l32.137-18.33V6.812l-43.16 24.274.31 37.648"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.469 87.31v12.384L.557 74.925V25.388L43.47.619l10.712 6.192-42.85 24.77v37.152L43.47 87.31z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.456 31.58l42.85-24.769L43.468.62.557 25.388l10.899 6.192z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M43.469 50.157V62.54l32.2-18.577v24.77l-32.2 18.576v12.384l42.912-24.769V25.388L43.469 50.157z"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.88 43.964V56.35l10.713 6.192V50.157L32.88 43.964z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.88 43.964l10.589 6.193 42.912-24.77-10.713-6.191L32.88 43.964z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="1.177"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
