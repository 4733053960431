import { ProductFeatureMap, ProductFeatures } from '@gammatech/authorization'
import { t } from '@lingui/macro'

import {
  Currency,
  FrequencyUnit,
  ProductPrice,
  WorkspaceSubscription,
} from 'modules/api/generated/graphql'
import { ExistingWorkspace } from 'modules/api/rest'
import { formatCurrency } from 'modules/i18n/utils/currency'

import { PaidProductKey, PriceDisplay, ProductKey } from './types'

// Reference: https://docs.stripe.com/currencies#zero-decimal
export const ZERO_DECIMAL_CURRENCIES: Currency[] = [
  Currency.Jpy,
  Currency.Krw,
  Currency.Vnd,
]

export const getCurrencyDivisor = (currency: Currency | undefined) => {
  return currency ? (ZERO_DECIMAL_CURRENCIES.includes(currency) ? 1 : 100) : 100
}

export const shouldShowCurrencyDisplayAsCode = (
  currency: Currency | undefined
) => {
  return currency === Currency.Twd
}

export const getNotation = (
  currency: Currency | undefined
): Intl.NumberFormatOptions['notation'] => {
  return getCurrencyDivisor(currency) < 100 ? 'standard' : 'compact'
}

export const getPriceDisplayMonthly = (
  productPrice?: ProductPrice,
  seatCount = 1
): PriceDisplay => {
  if (
    !productPrice ||
    !productPrice.price ||
    !productPrice.frequencyInterval ||
    !productPrice.frequencyUnit
  ) {
    return { price: null, discountedPrice: null }
  }

  const { currency, frequencyInterval, frequencyUnit, price } = productPrice

  const discountedPrice = productPrice.discount?.price ?? null

  return {
    price: getMonthlyPriceForCurrency({
      frequencyUnit,
      price,
      currency,
      frequencyInterval,
      seatCount,
    }),
    discountedPrice: discountedPrice
      ? getMonthlyPriceForCurrency({
          frequencyUnit,
          price: discountedPrice,
          currency,
          frequencyInterval,
          seatCount,
        })
      : null,
  }
}

/**
 * E.g. The value displayed in "$12 per seat per month"
 */
const getMonthlyPriceForCurrency = ({
  frequencyUnit,
  price,
  currency,
  frequencyInterval,
  seatCount,
}: {
  frequencyUnit: FrequencyUnit
  price: number
  currency?: Currency
  frequencyInterval: number
  seatCount: number
}) => {
  const notation = getNotation(currency)
  const pricePerMonth =
    (price * seatCount) /
    getCurrencyDivisor(currency) /
    // If frequency is yearly, divide by 12
    (frequencyUnit === FrequencyUnit.Year ? 12 : 1) /
    frequencyInterval
  return formatCurrency(pricePerMonth, {
    currency,
    notation,
  })
}
/**
 * E.g. The value displayed in "Billed annually at $50"
 */
export const getYearlyPriceForCurrency = ({
  frequencyUnit,
  price,
  currency,
  frequencyInterval,
  seatCount,
}: {
  frequencyUnit: FrequencyUnit
  price: number
  currency?: Currency
  frequencyInterval: number
  seatCount: number
}) => {
  const notation = getNotation(currency)
  const pricePerYear =
    ((price * seatCount) / getCurrencyDivisor(currency) / frequencyInterval) *
    (frequencyUnit === FrequencyUnit.Month ? 12 : 1)
  return formatCurrency(pricePerYear, {
    currency,
    notation,
  })
}

export const getPriceDisplayAnnually = (
  productPrice?: ProductPrice,
  seatCount = 1
): PriceDisplay => {
  if (
    !productPrice ||
    !productPrice.price ||
    !productPrice.frequencyInterval ||
    !productPrice.frequencyUnit
  ) {
    return {
      price: null,
      discountedPrice: null,
    }
  }

  const { currency, frequencyInterval, frequencyUnit, price, discount } =
    productPrice
  const discountedPrice = discount?.price || null

  return {
    price: getYearlyPriceForCurrency({
      frequencyUnit,
      price,
      currency,
      frequencyInterval,
      seatCount,
    }),
    discountedPrice: discountedPrice
      ? getYearlyPriceForCurrency({
          frequencyUnit,
          price: discountedPrice,
          currency,
          frequencyInterval,
          seatCount,
        })
      : null,
  }
}

const subtractMonths = (date: Date, months: number) => {
  const d = new Date(date)
  d.setMonth(d.getMonth() - months)
  return d
}

const getPercentageRemaining = (
  subscription: Pick<WorkspaceSubscription, 'nextBillingTime' | 'products'>,
  productPrice: ProductPrice
) => {
  const nextBillingTime = subtractMonths(
    new Date(subscription?.nextBillingTime),
    0 // Change this number to debug proration
  )
  const subscriptionNextBillingTime = new Date(nextBillingTime)
  const subscriptionPreviousBillingTime = subtractMonths(
    subscriptionNextBillingTime,
    productPrice.frequencyUnit === 'month' ? 1 : 12
  )

  const percentageRemaining =
    1 -
    (new Date().getTime() - subscriptionPreviousBillingTime.getTime()) /
      (subscriptionNextBillingTime.getTime() -
        subscriptionPreviousBillingTime.getTime())

  return percentageRemaining
}

export const computeSubscriptionChangeProductData = (
  subscription?: Pick<WorkspaceSubscription, 'nextBillingTime' | 'products'>,
  newProductPrice?: ProductPrice | null
) => {
  const productPrice = getProOrPlusProductPrice(subscription)
  if (
    !subscription ||
    !newProductPrice ||
    !newProductPrice.price ||
    !productPrice?.price ||
    !productPrice?.frequencyUnit
  ) {
    return {}
  }

  const seatCount = getProOrPlusSubscriptionSeatCount(subscription) || 1
  const prorationFactor = getPercentageRemaining(subscription, productPrice)
  const proratedDays = Math.round(
    prorationFactor * (productPrice.frequencyUnit === 'month' ? 30 : 365)
  )
  const proratedUpgradePrice = formatCurrency(
    +(
      (newProductPrice.price / getCurrencyDivisor(productPrice.currency)) *
      prorationFactor *
      seatCount
    ).toFixed(2),
    { notation: 'standard', currency: productPrice.currency }
  )

  const newPerSeatPrice =
    newProductPrice.frequencyUnit === FrequencyUnit.Month
      ? getPriceDisplayMonthly(newProductPrice, 1).discountedPrice ||
        getPriceDisplayMonthly(newProductPrice, 1).price
      : getPriceDisplayAnnually(newProductPrice, 1).discountedPrice ||
        getPriceDisplayAnnually(newProductPrice, 1).price

  const newTotalPrice =
    newProductPrice.frequencyUnit === FrequencyUnit.Month
      ? getPriceDisplayMonthly(newProductPrice, seatCount).discountedPrice ||
        getPriceDisplayMonthly(newProductPrice, seatCount).price
      : getPriceDisplayAnnually(newProductPrice, seatCount).discountedPrice ||
        getPriceDisplayAnnually(newProductPrice, seatCount).price

  return {
    newPerSeatPrice,
    newTotalPrice,
    seatCount,
    proratedUpgradePrice,
    proratedDays,
  }
}

/**
 * A client side utility to compute the charge for adding users to a workspace.
 * This is a best effort since the actual charge isn't known until the invitation is accepted.
 * Related issue: https://linear.app/gamma-app/issue/G-4965/show-a-preview-invoice-when-changing-user-numbers
 */
export const computeSubscriptionChangeSeatCountData = (
  subscription?: Pick<
    WorkspaceSubscription,
    'nextBillingTime' | 'products' | 'discountPercent'
  >,
  seatCount = 1
) => {
  const productPrice = getProOrPlusProductPrice(subscription)
  if (!subscription || !productPrice?.price || !productPrice?.frequencyUnit) {
    return {}
  }

  const discountPercent = subscription.discountPercent || 0
  const perSeatCost =
    (productPrice.price / getCurrencyDivisor(productPrice.currency)) *
    (1 - discountPercent / 100)
  const prorationFactor = getPercentageRemaining(subscription, productPrice)
  const proratedDays = Math.round(
    prorationFactor * (productPrice.frequencyUnit === 'month' ? 30 : 365)
  )
  const totalPrice = formatCurrency(+(perSeatCost * seatCount).toFixed(2), {
    currency: productPrice.currency,
  })
  const proratedTotalPrice = formatCurrency(
    +(perSeatCost * seatCount * prorationFactor).toFixed(2),
    { notation: 'standard', currency: productPrice.currency }
  )

  const perSeatPrice = formatCurrency(+perSeatCost.toFixed(2), {
    currency: productPrice.currency,
  })

  return {
    perSeatPrice,
    totalPrice,
    proratedTotalPrice,
    productPrice,
    proratedDays,
  }
}

export const getSubscriptionPriceAndInterval = (
  subscription?: Pick<WorkspaceSubscription, 'products'>
) => {
  const subscribedProduct = subscription?.products?.[0]
  const frequency = subscribedProduct?.productPrice?.frequencyUnit
  const subscriptionFrequencyDisplay =
    frequency === FrequencyUnit.Month
      ? 'monthly'
      : frequency === FrequencyUnit.Year
      ? 'annually'
      : '---'

  return {
    subscriptionPriceDisplay: getPriceDisplayMonthly(
      subscribedProduct?.productPrice
    ),
    subscriptionFrequencyDisplay,
    frequency,
  }
}

export const getProOrPlusSubscriptionProduct = (
  subscription?: Pick<WorkspaceSubscription, 'products'>
) => {
  return subscription?.products?.find(
    (p) => p?.key === 'pro' || p?.key === 'plus'
  )
}

export const getProOrPlusProductPrice = (
  subscription?: Pick<WorkspaceSubscription, 'products'>
) => {
  return subscription?.products?.find(
    (p) => p?.key === 'pro' || p?.key === 'plus'
  )?.productPrice
}

export const getProOrPlusSubscriptionSeatCount = (
  subscription?: Pick<WorkspaceSubscription, 'products'>
) => {
  return subscription?.products?.find(
    (p) => p?.key === 'pro' || p?.key === 'plus'
  )?.units
}

export const workspaceHasGammaProProduct = (workspace?: ExistingWorkspace) => {
  return Boolean(workspace?.products?.some((product) => product === 'pro'))
}

export const workspaceHasGammaPlusProduct = (workspace?: ExistingWorkspace) => {
  return Boolean(workspace?.products?.some((product) => product === 'plus'))
}

export const getProductRequiredForFeature = (
  productFeature: ProductFeatures
): PaidProductKey | null => {
  if (ProductFeatureMap.plus.includes(productFeature)) {
    return 'plus'
  }

  if (ProductFeatureMap.pro.includes(productFeature)) {
    return 'pro'
  }

  return null
}

export const getProductForWorkspace = (
  workspace?: ExistingWorkspace
): PaidProductKey | null => {
  if (workspaceHasGammaProProduct(workspace)) {
    return 'pro'
  }

  if (workspaceHasGammaPlusProduct(workspace)) {
    return 'plus'
  }

  return null
}

export const workspaceHasPaidProduct = (workspace?: ExistingWorkspace) => {
  return Boolean(workspace?.products?.length)
}

export const descriptionsForAiImageFunctionalityByProductKey = (
  productKey: ProductKey
) => {
  switch (productKey) {
    case 'free':
      return {
        header: t`Basic models`,
        pricingModalLabel: t`Basic AI image generation`,
      }
    case 'plus':
      return {
        header: t`Advanced models`,
        pricingModalLabel: t`Advanced AI image generation`,
      }
    case 'pro':
      return {
        header: t`Premium models`,
        pricingModalLabel: t`Premium AI image generation`,
      }
  }
}
