import { useEffect, useRef } from 'react'
import * as workerTimers from 'worker-timers'

import { useAppSelector, useAppStore } from 'modules/redux'

import {
  ConnectionEventEmitter,
  ConnectionEvents,
} from '../ConnectionEventEmitter'
import { selectConnectionState, updateConnectionState } from '../reducer'

export const useEmitNavigatorOnlineEvents = () => {
  const store = useAppStore()

  // load the initial state into redux
  useEffect(() => {
    const initialOnline = window.navigator.onLine
    // load initial state into redux
    store.dispatch(
      updateConnectionState({
        navigatorOnline: initialOnline,
        online: initialOnline,
      })
    )
  }, [store])

  // handle syncing navigator.onLine with redux
  // also sync with the online redux state since we are currently only relying on navigator.onLine
  useEffect(() => {
    const onlineHandler = () => {
      const isOnline = window.navigator.onLine

      store.dispatch(
        updateConnectionState({
          navigatorOnline: isOnline,
          online: isOnline,
        })
      )

      if (isOnline) {
        ConnectionEventEmitter.emit('navigatorOnline', {})
      } else {
        ConnectionEventEmitter.emit('navigatorOffline', {})
      }
    }

    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', onlineHandler)
    return () => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', onlineHandler)
    }
  }, [store])
}

/**
 * This hook forwards online redux state to the ConnectionEventEmitter
 */
const useEmitOnlineEvents = () => {
  const { online } = useAppSelector(selectConnectionState)

  const timeOfflineRef = useRef<number | null>(null)
  // In general it's okay for this to be in a useEffect, since notifying
  // the events are the last thing to happen
  useEffect(() => {
    // only emit online if we've emitted the offline event before
    if (online && timeOfflineRef.current) {
      ConnectionEventEmitter.emit('online', {
        timeOffline: Date.now() - timeOfflineRef.current,
      })
      timeOfflineRef.current = null
    } else if (!online) {
      timeOfflineRef.current = Date.now()
      ConnectionEventEmitter.emit('offline', {})
    }
  }, [online])
}

/**
 * This hook handles the logic for when the user is in the background
 */
const useEmitBackgroundEvents = () => {
  const store = useAppStore()
  const backgroundTimeRef = useRef<number | null>(null)

  useEffect(() => {
    const backgroundHandler = () => {
      const documentIsHidden = document.hidden
      store.dispatch(
        updateConnectionState({
          backgrounded: documentIsHidden,
        })
      )

      if (documentIsHidden) {
        backgroundTimeRef.current = Date.now()
        ConnectionEventEmitter.emit('background', {
          backgroundTime: Date.now(),
        })
      } else {
        let timeInBackground: number | null = null
        if (backgroundTimeRef.current) {
          timeInBackground = Date.now() - backgroundTimeRef.current
        }

        ConnectionEventEmitter.emit('foreground', {
          timeInBackground,
        })
      }
    }

    document.addEventListener('visibilitychange', backgroundHandler)
    return () => {
      document.removeEventListener('visibilitychange', backgroundHandler)
    }
  }, [store])
}

const WAKEUP_INTERVAL_PERIOD = 1000
const WAKEUP_SKEW_FACTOR = 30

// Use a 30s skew time. Note that during heavy computations the skew
// of an active browser actually gets into the 5-10s range
const MINIMUM_SKEW_TIME = WAKEUP_INTERVAL_PERIOD * WAKEUP_SKEW_FACTOR

/**
 * Hook to call a Callback whenever we "wake up".
 *
 * Detect waking up from sleeping by computing the difference between
 * where we expect the clock to be and where it actually is (skew), leaving
 * some wiggle room of a couple seconds to account for imperfect javascript timing
 */
export const useEmitWakeupEvents = () => {
  // Setup intervals to refresh the session cookie and detect "waking up"
  useEffect(() => {
    let lastKnownTime = Date.now()

    const wakeupDetectionInterval = workerTimers.setInterval(() => {
      const currentTime = Date.now()
      if (
        currentTime >
        lastKnownTime + WAKEUP_INTERVAL_PERIOD + MINIMUM_SKEW_TIME
      ) {
        ConnectionEventEmitter.emit('wakeup', {})
      }
      lastKnownTime = currentTime
    }, WAKEUP_INTERVAL_PERIOD)

    return () => {
      workerTimers.clearInterval(wakeupDetectionInterval)
    }
  }, [])
}

export const useEmitConnectionEvents = () => {
  useEmitBackgroundEvents()
  useEmitNavigatorOnlineEvents()
  useEmitOnlineEvents()
  useEmitWakeupEvents()
}

export const useOnConnectionEvent = <K extends keyof ConnectionEvents>(
  event: K,
  handler: (payload: ConnectionEvents[K]) => void
) => {
  useEffect(() => {
    ConnectionEventEmitter.on(event, handler)
    return () => ConnectionEventEmitter.off(event, handler)
  }, [event, handler])
}
