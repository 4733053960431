import {
  faAlignLeft,
  faBarsSort,
  faGripLines,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'

import { DocFormatKey } from 'modules/tiptap_editor/extensions/Document/DocumentAttrs/DocFormats'

import { GeneratorInput, TextAmount } from './types'

export const DEFAULT_NUM_CARDS = 8
export const HIGH_CARDS_THRESHOLD = 15
export const HIGH_CARDS_INCREMENT = 5
const SEARCH_PROVIDER_DEFAULT = 'exa-keyword'

export const GENERATE_SETTING_DEFAULTS: GeneratorInput['settings'] = {
  numCards: DEFAULT_NUM_CARDS,
  locale: 'en',
  editorMode: 'freeform',

  imageProvider: 'web',
  imageLicense: 'All',

  audience: '',
  tone: '',
  textAmount: 'md',
  textMode: 'generate',

  styleTemplate: 'deck_default',

  search: false,
  searchProvider: SEARCH_PROVIDER_DEFAULT,
}

// If you update this here, make sure you update the server value for the same variable too.
export const GENERATE_SETTINGS_MAX_CHARS = 500

export const getGenerateSettingsFormatDefaults = (
  format: DocFormatKey
): Partial<GeneratorInput['settings']> => {
  const defaults = {
    deck: {},
    document: {
      textAmount: 'lg',
    },
    webpage: {},
    social: {},
  } as const

  return defaults[format]
}

export const GENERATE_INPUT_DEFAULTS: GeneratorInput = {
  id: '',

  interactionId: '',
  status: 'draft',
  docGenerationId: '',

  content: '',
  prompt: '',
  format: 'deck',

  settings: GENERATE_SETTING_DEFAULTS,
}

export const getTextAmounts = (): Record<
  TextAmount,
  {
    label: () => string
    description: () => string
    icon: FontAwesomeIconProps['icon']
  }
> => {
  return {
    sm: {
      label: () =>
        t({
          message: 'Brief',
          context:
            'Button indicating the desired length of text the AI should generate for a new presentation',
          comment:
            'This is the shortest of the three length options for the AI generator',
        }),
      icon: faGripLines,
      description: () =>
        t({
          message: 'Short sentences',
          context:
            'Tooltip description of the "Brief" option for the AI generator',
          comment: 'This text appears above the "Brief" option on hover',
        }),
    },
    md: {
      label: () =>
        t({
          message: 'Medium',
          context:
            'Button indicating the desired length of text the AI should generate for a new presentation',
          comment: 'This is the middle length option for the AI generator',
        }),
      icon: faBarsSort,
      description: () =>
        t({
          message: 'A few concise paragraphs',
          context:
            'Tooltip description of the "Medium" option for the AI generator',
          comment: 'This text appears above the "Medium" option on hover',
        }),
    },
    lg: {
      label: () =>
        t({
          message: 'Detailed',
          context:
            'Button indicating the desired length of text the AI should generate for a new presentation',
          comment:
            'This is the longest of the three length options for the AI generator',
        }),
      icon: faAlignLeft,
      description: () =>
        t({
          message: 'Multiple detailed paragraphs',
          context:
            'Tooltip description of the "Detailed" option for the AI generator',
          comment: 'This text appears above the "Detailed" option on hover',
        }),
    },
  }
}
