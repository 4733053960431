import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useUserContext } from 'modules/user'
import { useEffectWhen } from 'utils/hooks'

import { AnalyticsWrapper, analytics } from './AnalyticsWrapper'

export const SegmentAnalyticsContext = createContext<
  AnalyticsWrapper | undefined
>(undefined)

type SegmentIdentifyTraits = {
  is_gamma_employee: boolean
  user_org_id: string | undefined
  anonymous_user: boolean
  gamma_employee_first_name: string | undefined
  gamma_user_id: string | null
  anonymous_user_id: string | null
}

const shouldTrackPage = () => {
  // track page if window.location.href contains UTM parameters.
  const url = new URL(window.location.href)
  return (
    url.searchParams.has('utm_campaign') ||
    url.searchParams.has('utm_source') ||
    url.searchParams.has('utm_medium') ||
    url.searchParams.has('utm_term') ||
    url.searchParams.has('utm_content')
  )
}

export const SegmentContextProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [analyticsSdk, setAnalyticsSdk] = useState<
    AnalyticsWrapper | undefined
  >(undefined)
  const {
    user,
    anonymousUser,
    isUserLoading,
    currentWorkspace,
    isGammaAdminUser,
    isGammaOrgUser,
  } = useUserContext()

  const userId = user?.id
  const currentWorkspaceId = currentWorkspace?.id
  const anonymousUserId = anonymousUser.id
  const firstName = user?.firstName
  const email = user?.email

  useEffect(() => {
    if (isUserLoading) {
      return
    }

    if (isGammaAdminUser) {
      // Dont track analytics on docs accessed by the admin accounts
      analytics.setEnabled(false)
      return
    }

    analytics.loadSdkPromise.then((sdk) => {
      if (!sdk) {
        return
      }
      let traits: SegmentIdentifyTraits
      if (userId) {
        traits = {
          is_gamma_employee: isGammaOrgUser,
          user_org_id: currentWorkspaceId,
          anonymous_user: false,
          anonymous_user_id: anonymousUserId,
          gamma_user_id: userId,
          gamma_employee_first_name: isGammaOrgUser ? firstName : undefined,
        }
      } else {
        traits = {
          is_gamma_employee: false,
          user_org_id: 'no_org_id',
          anonymous_user: true,
          anonymous_user_id: anonymousUserId,
          gamma_user_id: null,
          gamma_employee_first_name: undefined,
        }
      }
      // initial identify call
      analytics.setAnonymousId(anonymousUserId)
      analytics.identify(userId, traits)

      // We need to call page after we issue the anonymousId, otherwise analytics.js
      // issues a random anonymousId and we can't associate the `page` with the correct user.
      if (shouldTrackPage()) {
        analytics?.page(window.location.pathname + window.location.search)
      }
      setAnalyticsSdk(analytics)
    })
  }, [
    userId,
    currentWorkspaceId,
    anonymousUserId,
    isUserLoading,
    firstName,
    email,
    isGammaAdminUser,
    isGammaOrgUser,
  ])

  return (
    <SegmentAnalyticsContext.Provider value={analyticsSdk}>
      {children}
    </SegmentAnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  return useContext(SegmentAnalyticsContext)
}

/**
 * Helper function around useEffect and useAnalytics
 *
 * Will only run the specified fn when the deps change, useful for tracking
 * something like an ID changing
 */
export const useAnalyticsEffect = (
  fn: (analytics: AnalyticsWrapper) => void,
  deps: any[] = []
) => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const analytics = useAnalytics()
  useEffectWhen(
    () => {
      if (!analytics) {
        return
      }

      fn(analytics)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analytics, fn, ...deps],
    [analytics, ...deps]
  )
}
