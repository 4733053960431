import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum AbuseReason {
  DeceptiveOrMisleading = 'deceptive_or_misleading',
  Explicit = 'explicit',
  Other = 'other',
  Spam = 'spam',
  Inappropriate = 'inappropriate'
}

export type AbuseReportInput = {
  docId: Scalars['String'];
  reason: AbuseReason;
  description?: Maybe<Scalars['String']>;
};

export type AccessLink = {
  __typename?: 'AccessLink';
  id: Scalars['ID'];
  type?: Maybe<AccessLinkType>;
  token: Scalars['String'];
  docId: Scalars['ID'];
  permission?: Maybe<Permission>;
  url: Scalars['String'];
};

export type AccessLinkInput = {
  permission?: Maybe<Permission>;
};

export enum AccessLinkType {
  Password = 'password',
  Token = 'token'
}

export type AddDomainInput = {
  siteId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  method?: Maybe<DomainSetupMethod>;
};

export type AddReactionInput = {
  docId: Scalars['String'];
  cardId?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  targetType: TargetType;
  emoji: Scalars['String'];
};

export type BaseSnapshotInput = {
  ydoc: Scalars['String'];
  schemaVersion: Scalars['Int'];
  editors: Array<Scalars['ID']>;
};

export type CanRunChatCompletionModelResponse = {
  __typename?: 'CanRunChatCompletionModelResponse';
  canRun: Scalars['Boolean'];
  model: Scalars['String'];
};

export type CanRunChatCompletionRequest = {
  messages: Array<ChatMessage>;
  maxTokens?: Maybe<Scalars['Int']>;
  models: Array<Scalars['String']>;
};

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  docId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  text?: Maybe<Scalars['String']>;
  depth?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ID']>;
  previewUrl?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<Comment>>;
  reactions?: Maybe<Array<Reaction>>;
  createdTime: Scalars['DateTime'];
  updatedTime: Scalars['DateTime'];
};

export type CardStats = {
  __typename?: 'CardStats';
  cardId: Scalars['ID'];
  viewTimePercent?: Maybe<Scalars['Float']>;
  viewersPercent?: Maybe<Scalars['Float']>;
};

export type ChangeBuiltinDomainInput = {
  siteId: Scalars['ID'];
  name: Scalars['String'];
};

export type ChangePasswordInput = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type Channel = {
  __typename?: 'Channel';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  members?: Maybe<Array<ChannelMember>>;
  memberCount?: Maybe<Scalars['Int']>;
  isMember?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  workspaceId: Scalars['String'];
  activity?: Maybe<ChannelActivity>;
  isActive?: Maybe<Scalars['Boolean']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type ChannelActivity = {
  __typename?: 'ChannelActivity';
  activeUsers?: Maybe<Array<ChannelMember>>;
};

export type ChannelConnection = {
  __typename?: 'ChannelConnection';
  edges: Array<ChannelEdge>;
  nodes: Array<Channel>;
  pageInfo: PageInfo;
};

export type ChannelEdge = {
  __typename?: 'ChannelEdge';
  node: Channel;
  cursor?: Maybe<Scalars['String']>;
};

export type ChannelMember = {
  __typename?: 'ChannelMember';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum ChannelSortField {
  MemberCount = 'memberCount'
}

export type ChannelSortOrder = {
  field?: Maybe<ChannelSortField>;
  direction?: Maybe<SortDirection>;
};

export type ChatCompletionInput = {
  workspaceId?: Maybe<Scalars['ID']>;
  interactionId: Scalars['String'];
  messages: Array<ChatMessage>;
  params?: Maybe<ChatCompletionInputParams>;
  requiredCredits?: Maybe<Scalars['Int']>;
};

export type ChatCompletionInputParams = {
  model?: Maybe<Scalars['String']>;
  provider?: Maybe<ChatCompletionProvider>;
  maxTokens?: Maybe<Scalars['Int']>;
  temperature?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Int']>;
  topP?: Maybe<Scalars['Int']>;
  stop?: Maybe<Array<Scalars['String']>>;
  presencePenalty?: Maybe<Scalars['Int']>;
  frequencyPenalty?: Maybe<Scalars['Int']>;
  bestOf?: Maybe<Scalars['Int']>;
};

export enum ChatCompletionProvider {
  Azure = 'azure',
  Openai = 'openai'
}

export type ChatMessage = {
  role: ChatRole;
  content?: Maybe<Scalars['String']>;
  operation?: Maybe<ChatOperation>;
  variables?: Maybe<Scalars['JSON']>;
};

export type ChatMessageResponse = {
  __typename?: 'ChatMessageResponse';
  role: ChatRole;
  content: Scalars['String'];
  finish_reason: Scalars['String'];
};

export enum ChatOperation {
  GetShortenedSystem = 'getShortenedSystem',
  GetShortenedUser = 'getShortenedUser',
  GetOutlineSystem = 'getOutlineSystem',
  GetOutlineUser = 'getOutlineUser',
  GenerateDocumentSystem = 'generateDocumentSystem',
  GenerateDocumentUser = 'generateDocumentUser',
  GeneratePresentationSystem = 'generatePresentationSystem',
  GeneratePresentationUser = 'generatePresentationUser',
  GenerateWebpageSystem = 'generateWebpageSystem',
  GenerateWebpageUser = 'generateWebpageUser',
  GenerateDocumentAdvancedSystem = 'generateDocumentAdvancedSystem',
  GenerateDocumentAdvancedUser = 'generateDocumentAdvancedUser',
  GeneratePresentationAdvancedSystem = 'generatePresentationAdvancedSystem',
  GeneratePresentationAdvancedUser = 'generatePresentationAdvancedUser',
  GenerateWebpageAdvancedSystem = 'generateWebpageAdvancedSystem',
  GenerateWebpageAdvancedUser = 'generateWebpageAdvancedUser',
  ClassifySystem = 'classifySystem',
  ClassifyUser = 'classifyUser',
  AddCardSystem = 'addCardSystem',
  AddCardUser = 'addCardUser',
  BasicReplyChatSystem = 'basicReplyChatSystem',
  BasicReplyChatUser = 'basicReplyChatUser',
  SuggestTextSystem = 'suggestTextSystem',
  SuggestTextUser = 'suggestTextUser',
  SuggestThemeSystem = 'suggestThemeSystem',
  SuggestThemeUser = 'suggestThemeUser',
  SuggestImagesSystem = 'suggestImagesSystem',
  SuggestImagesUser = 'suggestImagesUser',
  RateImagesSystem = 'rateImagesSystem',
  RateImagesUser = 'rateImagesUser',
  ReplaceContentSystem = 'replaceContentSystem',
  ReplaceContentUser = 'replaceContentUser',
  ChangeAccentImageLayoutSystem = 'changeAccentImageLayoutSystem',
  ChangeAccentImageLayoutUser = 'changeAccentImageLayoutUser',
  ChangeContentLayoutSystem = 'changeContentLayoutSystem',
  ChangeContentLayoutUser = 'changeContentLayoutUser',
  ChangeContentSystem = 'changeContentSystem',
  ChangeContentUser = 'changeContentUser',
  GenerateImagePromptSystem = 'generateImagePromptSystem',
  GenerateImagePromptUser = 'generateImagePromptUser',
  Raw = 'raw'
}

export enum ChatRole {
  Assistant = 'assistant',
  System = 'system',
  User = 'user'
}

export type CheckError = {
  __typename?: 'CheckError';
  code: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type CheckResponse = {
  __typename?: 'CheckResponse';
  ok: Scalars['Boolean'];
  errors?: Maybe<Array<CheckError>>;
};

export type CheckoutItemInput = {
  productPriceId: Scalars['ID'];
};

export type CheckoutSessionResponse = {
  __typename?: 'CheckoutSessionResponse';
  url?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  content?: Maybe<Scalars['JSON']>;
  cardId?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetText?: Maybe<Scalars['String']>;
  targetHtml?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  mentionedUsers?: Maybe<Array<Scalars['String']>>;
  replies?: Maybe<Array<Comment>>;
  reactions?: Maybe<Array<Reaction>>;
  archived?: Maybe<Scalars['Boolean']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CommentStatus>;
};

export enum CommentStatus {
  Open = 'open',
  Closed = 'closed'
}

export type CreateChannelInput = {
  name: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type CreateCommentInput = {
  docId?: Maybe<Scalars['String']>;
  cardId?: Maybe<Scalars['String']>;
  targetId?: Maybe<Scalars['String']>;
  targetText?: Maybe<Scalars['String']>;
  targetHtml?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
};

export type CreateDocGenerateInput = {
  interactionId?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  format?: Maybe<DocGenerateFormat>;
  settings?: Maybe<Scalars['JSON']>;
};

export type CreateDocGenerationInput = {
  workspaceId: Scalars['ID'];
  generationType: GenerationType;
  input: CreateDocGenerateInput;
};

export type CreateDocInput = {
  orgId: Scalars['ID'];
  channels?: Maybe<Array<DocChannelInput>>;
  title?: Maybe<Scalars['String']>;
  initialContent?: Maybe<BaseSnapshotInput>;
  themeId?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSON']>;
  source?: Maybe<DocSource>;
  orgAccess?: Maybe<Permission>;
  mainPage?: Maybe<Scalars['Boolean']>;
};

export type CreateFontFileInput = {
  name: Scalars['String'];
  weight?: Maybe<Scalars['Int']>;
  isItalic?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<FontFileType>;
  sourceUrl?: Maybe<Scalars['String']>;
};

export type CreateFontInput = {
  name: Scalars['String'];
  workspaceId: Scalars['String'];
  fontFiles?: Maybe<Array<CreateFontFileInput>>;
};

export type CreateRouteInput = {
  path: Scalars['String'];
  docId: Scalars['ID'];
};

export type CreateSavedMediaInput = {
  userWorkspaceId: Scalars['ID'];
  context?: Maybe<SavedMediaContext>;
  docId?: Maybe<Scalars['ID']>;
  themeId?: Maybe<Scalars['ID']>;
  interactionId?: Maybe<Scalars['ID']>;
  type: MediaType;
  source: Scalars['String'];
  attrs: Scalars['JSON'];
  s3Bucket?: Maybe<Scalars['String']>;
  s3Key?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentLength?: Maybe<Scalars['Int']>;
};

export type CreateSiteInput = {
  workspaceId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  routes?: Maybe<Array<CreateRouteInput>>;
  themeId?: Maybe<Scalars['String']>;
};

export type CreateSnapshotInput = {
  docId: Scalars['ID'];
  ydoc: Scalars['String'];
  schemaVersion: Scalars['Int'];
  editors: Array<Scalars['ID']>;
};

export type CreateThemeInput = {
  workspaceId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  headingFont?: Maybe<Scalars['String']>;
  headingFontWeight?: Maybe<Scalars['Int']>;
  bodyFont?: Maybe<Scalars['String']>;
  bodyFontWeight?: Maybe<Scalars['Int']>;
  accentColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  priority?: Maybe<Scalars['Int']>;
};

export type Credits = {
  __typename?: 'Credits';
  userId: Scalars['String'];
  credits: Scalars['Int'];
};

export enum Currency {
  Aud = 'AUD',
  Brl = 'BRL',
  Cad = 'CAD',
  Cny = 'CNY',
  Eur = 'EUR',
  Gbp = 'GBP',
  Idr = 'IDR',
  Inr = 'INR',
  Jpy = 'JPY',
  Krw = 'KRW',
  Kzt = 'KZT',
  Mxn = 'MXN',
  Twd = 'TWD',
  Usd = 'USD',
  Vnd = 'VND'
}



export type Deployment = {
  __typename?: 'Deployment';
  id: Scalars['ID'];
  siteId: Scalars['ID'];
  buildHost?: Maybe<Scalars['String']>;
  siteSettings?: Maybe<Scalars['JSON']>;
  deploymentTime?: Maybe<Scalars['DateTime']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type Discount = {
  __typename?: 'Discount';
  price?: Maybe<Scalars['Int']>;
  discountPercent?: Maybe<Scalars['Float']>;
  discountAmount?: Maybe<Scalars['Int']>;
};

export type DnsCheckResponse = {
  __typename?: 'DnsCheckResponse';
  status: DnsStatus;
  errors?: Maybe<Array<CheckError>>;
  proxy?: Maybe<Scalars['String']>;
};

export enum DnsStatus {
  Ok = 'ok',
  ChangeRecommended = 'changeRecommended',
  Degraded = 'degraded',
  Failed = 'failed',
  Unknown = 'unknown'
}

export type Doc = {
  __typename?: 'Doc';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleEdited?: Maybe<Scalars['Boolean']>;
  currentSnapshotId?: Maybe<Scalars['String']>;
  publishedSnapshotId?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  disabled?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<User>;
  organization?: Maybe<Organization>;
  currentSnapshot?: Maybe<Snapshot>;
  publishedSnapshot?: Maybe<Snapshot>;
  collaborators?: Maybe<Array<DocCollaborator>>;
  invitations?: Maybe<Array<DocInvitation>>;
  accessLinks?: Maybe<Array<AccessLink>>;
  cards?: Maybe<Array<Card>>;
  titleCard?: Maybe<Card>;
  theme?: Maybe<Theme>;
  attributes?: Maybe<Scalars['JSON']>;
  channels?: Maybe<Array<DocChannel>>;
  comments?: Maybe<Array<Comment>>;
  reactions?: Maybe<Array<Reaction>>;
  sites?: Maybe<Array<Site>>;
  site?: Maybe<Site>;
  editors?: Maybe<Array<DocEditor>>;
  docUser?: Maybe<DocUser>;
  orgAccess?: Maybe<Permission>;
  publicAccess?: Maybe<Permission>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  editedTime?: Maybe<Scalars['DateTime']>;
  publishedTime?: Maybe<Scalars['DateTime']>;
  savedTime?: Maybe<Scalars['DateTime']>;
  docGenerateInput?: Maybe<DocGenerateInput>;
  links?: Maybe<Array<Link>>;
};

export type DocAiOptions = {
  preserveLayouts?: Maybe<Scalars['Boolean']>;
  imageOptions?: Maybe<TransformImageOptions>;
};

export type DocActivity = {
  __typename?: 'DocActivity';
  editors?: Maybe<Array<User>>;
  presenters?: Maybe<Array<User>>;
  doc: Doc;
};

export type DocActivityConnection = {
  __typename?: 'DocActivityConnection';
  edges: Array<DocActivityEdge>;
  nodes: Array<DocActivity>;
  pageInfo: PageInfo;
};

export type DocActivityEdge = {
  __typename?: 'DocActivityEdge';
  node: DocActivity;
  cursor?: Maybe<Scalars['String']>;
};

export type DocCardsStats = {
  __typename?: 'DocCardsStats';
  docId?: Maybe<Scalars['String']>;
  uniqueVisitors?: Maybe<Scalars['Int']>;
  uniqueEditors?: Maybe<Scalars['Int']>;
  cardCount?: Maybe<Scalars['Int']>;
  everyone?: Maybe<Array<CardStats>>;
  viewers?: Maybe<Array<CardStats>>;
};

export type DocChannel = {
  __typename?: 'DocChannel';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  permission?: Maybe<Permission>;
};

export type DocChannelInput = {
  channelId: Scalars['ID'];
  permission?: Maybe<Permission>;
};

export type DocCollaborator = {
  __typename?: 'DocCollaborator';
  user: User;
  docId: Scalars['ID'];
  permission: Permission;
  guest?: Maybe<Scalars['Boolean']>;
  accessLinkId?: Maybe<Scalars['ID']>;
};

export type DocCollaboratorInput = {
  userId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['ID']>;
  permission: Permission;
};

export type DocCollaboratorRemovalInput = {
  userId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['ID']>;
};

export type DocCommentsSubscription = {
  __typename?: 'DocCommentsSubscription';
  id: Scalars['ID'];
  comments?: Maybe<Array<Comment>>;
};

export type DocConnection = {
  __typename?: 'DocConnection';
  edges: Array<DocEdge>;
  nodes: Array<Doc>;
  pageInfo: PageInfo;
};

export type DocDailyViews = {
  __typename?: 'DocDailyViews';
  dayCount?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  everyone?: Maybe<Array<DocDayStats>>;
  viewers?: Maybe<Array<DocDayStats>>;
};

export type DocDayStats = {
  __typename?: 'DocDayStats';
  date?: Maybe<Scalars['Date']>;
  uniqueVisitors?: Maybe<Scalars['Int']>;
};

export type DocEdge = {
  __typename?: 'DocEdge';
  node: Doc;
  cursor?: Maybe<Scalars['String']>;
};

export type DocEditor = {
  __typename?: 'DocEditor';
  user: User;
  docId: Scalars['ID'];
  lastViewed?: Maybe<Scalars['DateTime']>;
  lastEdited?: Maybe<Scalars['DateTime']>;
  editCount?: Maybe<Scalars['Int']>;
};

export enum DocGenerateFormat {
  Deck = 'deck',
  Webpage = 'webpage',
  Document = 'document',
  Social = 'social'
}

export type DocGenerateInput = {
  __typename?: 'DocGenerateInput';
  id: Scalars['ID'];
  docGenerationId: Scalars['ID'];
  docGeneration?: Maybe<DocGeneration>;
  interactionId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  prompt: Scalars['String'];
  content: Scalars['String'];
  format: Scalars['String'];
  settings: Scalars['JSON'];
  docId?: Maybe<Scalars['ID']>;
  docGenerateOutputId?: Maybe<Scalars['ID']>;
  createdTime: Scalars['DateTime'];
  updatedTime: Scalars['DateTime'];
};

export type DocGenerateOutput = {
  __typename?: 'DocGenerateOutput';
  id: Scalars['ID'];
  docGenerateInputId: Scalars['ID'];
  output: Scalars['String'];
};

export type DocGeneration = {
  __typename?: 'DocGeneration';
  id: Scalars['ID'];
  generationType: GenerationType;
  userId: Scalars['ID'];
  workspaceId: Scalars['ID'];
  draftInput?: Maybe<DocGenerateInput>;
  docGenerateInputs: Array<DocGenerateInput>;
  createdTime: Scalars['DateTime'];
  updatedTime: Scalars['DateTime'];
};

export type DocGenerationConnection = {
  __typename?: 'DocGenerationConnection';
  edges: Array<DocGenerationEdge>;
  nodes: Array<DocGeneration>;
  pageInfo: PageInfo;
};

export type DocGenerationEdge = {
  __typename?: 'DocGenerationEdge';
  node: DocGeneration;
  cursor?: Maybe<Scalars['String']>;
};

export type DocInvitation = {
  __typename?: 'DocInvitation';
  id: Scalars['ID'];
  email: Scalars['String'];
  docId: Scalars['ID'];
  docTitle: Scalars['String'];
  permission: Permission;
  invitedBy: User;
  acceptedTime?: Maybe<Scalars['DateTime']>;
  revokedTime?: Maybe<Scalars['DateTime']>;
};

export type DocJsonPatch = {
  op?: Maybe<PatchOperation>;
  path?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type DocLinksSubscription = {
  __typename?: 'DocLinksSubscription';
  id: Scalars['ID'];
  links?: Maybe<Array<Link>>;
};

export type DocReactionsSubscription = {
  __typename?: 'DocReactionsSubscription';
  id: Scalars['ID'];
  reactions?: Maybe<Array<Reaction>>;
};

export type DocResult = {
  __typename?: 'DocResult';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  previewUrl?: Maybe<Scalars['String']>;
  createdBy?: Maybe<User>;
  organization?: Maybe<Organization>;
  editors?: Maybe<Array<DocEditor>>;
  docUser?: Maybe<DocUser>;
  orgAccess?: Maybe<Permission>;
  publicAccess?: Maybe<Permission>;
  createdTime?: Maybe<Scalars['DateTime']>;
  editedTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
};

export type DocSharingInput = {
  docId: Scalars['ID'];
  sharing?: Maybe<Array<DocJsonPatch>>;
};

export enum DocSortField {
  Title = 'title',
  LastViewed = 'lastViewed',
  LastEdited = 'lastEdited',
  Favorited = 'favorited',
  CreatedTime = 'createdTime',
  EditedTime = 'editedTime',
  UpdatedTime = 'updatedTime'
}

export type DocSortOrder = {
  field?: Maybe<DocSortField>;
  direction?: Maybe<SortDirection>;
};

export enum DocSource {
  AiGenerated = 'ai_generated',
  Blank = 'blank',
  DuplicateDoc = 'duplicate_doc',
  DuplicateInspiration = 'duplicate_inspiration',
  DuplicateTemplate = 'duplicate_template',
  GammaGenerated = 'gamma_generated',
  ImportGoogleDoc = 'import_google_doc',
  ImportGoogleSlides = 'import_google_slides',
  ImportDocx = 'import_docx',
  ImportPptx = 'import_pptx',
  ImportWebpage = 'import_webpage',
  ImportPdf = 'import_pdf',
  SlashNew = 'slash_new',
  SitesAddPage = 'sites_add_page',
  SitesCopyToSite = 'sites_copy_to_site',
  SitesDuplicatePage = 'sites_duplicate_page'
}

export type DocSubscription = {
  __typename?: 'DocSubscription';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleEdited?: Maybe<Scalars['Boolean']>;
  archived?: Maybe<Scalars['Boolean']>;
  previewUrl?: Maybe<Scalars['String']>;
  titleCard?: Maybe<Card>;
  theme?: Maybe<Theme>;
  channels?: Maybe<Array<DocChannel>>;
  collaborators?: Maybe<Array<DocCollaborator>>;
  invitations?: Maybe<Array<DocInvitation>>;
  orgAccess?: Maybe<Permission>;
  publicAccess?: Maybe<Permission>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  editedTime?: Maybe<Scalars['DateTime']>;
  savedTime?: Maybe<Scalars['DateTime']>;
  accessLinks?: Maybe<Array<AccessLink>>;
  reactions?: Maybe<Array<Reaction>>;
  editors?: Maybe<Array<DocEditor>>;
  publishedTime?: Maybe<Scalars['DateTime']>;
};

export type DocSubscriptionResult = DocSubscription | DocCommentsSubscription | DocLinksSubscription | DocReactionsSubscription;

export type DocUser = {
  __typename?: 'DocUser';
  id: Scalars['ID'];
  user?: Maybe<User>;
  docId: Scalars['ID'];
  lastViewed?: Maybe<Scalars['DateTime']>;
  lastEdited?: Maybe<Scalars['DateTime']>;
  favorited?: Maybe<Scalars['DateTime']>;
  editCount?: Maybe<Scalars['Int']>;
};

export type DocUserInput = {
  docId: Scalars['ID'];
  userId: Scalars['ID'];
  lastViewed?: Maybe<Scalars['DateTime']>;
  lastEdited?: Maybe<Scalars['DateTime']>;
  favorited?: Maybe<Scalars['DateTime']>;
};

export type DocUserPermissions = {
  __typename?: 'DocUserPermissions';
  user?: Maybe<User>;
  docId: Scalars['ID'];
  workspaceId: Scalars['ID'];
  permission?: Maybe<Permission>;
};

export type Domain = {
  __typename?: 'Domain';
  id: Scalars['ID'];
  siteId: Scalars['ID'];
  name: Scalars['String'];
  status: DomainVerificationStatus;
  builtin: Scalars['Boolean'];
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  siteName?: Maybe<Scalars['String']>;
  siteArchived?: Maybe<Scalars['Boolean']>;
};

export type DomainAction = {
  __typename?: 'DomainAction';
  code: DomainActionCode;
  message?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
};

export enum DomainActionCode {
  AddARecord = 'add_a_record',
  AddCnameRecordSubdomain = 'add_cname_record_subdomain',
  AddCnameRecordWww = 'add_cname_record_www',
  EditCnameRecordSubdomain = 'edit_cname_record_subdomain',
  EditCnameRecordWww = 'edit_cname_record_www',
  RemoveARecord = 'remove_a_record',
  RemoveAaaaRecord = 'remove_aaaa_record',
  EditWwwCnameRecord = 'edit_www_cname_record',
  ContactGammaSupport = 'contact_gamma_support',
  SslModeFullCloudflare = 'ssl_mode_full_cloudflare',
  CheckDnsCloudflare = 'check_dns_cloudflare'
}

export type DomainCheck = {
  __typename?: 'DomainCheck';
  ok: Scalars['Boolean'];
  dns?: Maybe<DnsCheckResponse>;
  ssl?: Maybe<CheckResponse>;
  http?: Maybe<CheckResponse>;
  actions?: Maybe<Array<DomainAction>>;
  domain?: Maybe<Domain>;
  site?: Maybe<Site>;
};

export enum DomainSetupMethod {
  Manual = 'manual',
  Automatic = 'automatic',
  Purchase = 'purchase'
}

export enum DomainVerificationStatus {
  Pending = 'pending',
  Verified = 'verified',
  Failed = 'failed'
}

export type DuplicateDocInput = {
  orgId: Scalars['ID'];
  source?: Maybe<DocSource>;
  sourceDocId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  initialContent?: Maybe<BaseSnapshotInput>;
  channels?: Maybe<Array<DocChannelInput>>;
  orgAccess?: Maybe<Permission>;
  themeId?: Maybe<Scalars['String']>;
  mainPage?: Maybe<Scalars['Boolean']>;
};

export type Font = {
  __typename?: 'Font';
  id: Scalars['ID'];
  name: Scalars['String'];
  workspaceId?: Maybe<Scalars['ID']>;
  cssUrl?: Maybe<Scalars['String']>;
  fontFiles?: Maybe<Array<FontFile>>;
  fontFileCount?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type FontFile = {
  __typename?: 'FontFile';
  id: Scalars['ID'];
  name: Scalars['String'];
  weight: Scalars['Int'];
  isItalic: Scalars['Boolean'];
  fileType: FontFileType;
  sourceUrl?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export enum FontFileType {
  Otf = 'otf',
  Ttf = 'ttf'
}

export enum FontSortField {
  Name = 'name',
  UpdatedTime = 'updatedTime'
}

export type FontSortOrder = {
  field?: Maybe<FontSortField>;
  direction?: Maybe<SortDirection>;
};

export type Frequency = {
  interval?: Maybe<Scalars['Int']>;
  unit?: Maybe<FrequencyUnit>;
};

export enum FrequencyUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year'
}

export type GammaInvitation = {
  __typename?: 'GammaInvitation';
  email: Scalars['String'];
};

export type GenerateDocFromImportOptions = {
  themeId?: Maybe<Scalars['String']>;
  orgAccess?: Maybe<Permission>;
  source: DocSource;
  content: Scalars['JSON'];
};

export type GenerateDocOptions = {
  themeId?: Maybe<Scalars['String']>;
  orgAccess?: Maybe<Permission>;
  docSettings?: Maybe<Scalars['JSON']>;
  aiOptions?: Maybe<DocAiOptions>;
  channels?: Maybe<Array<DocChannelInput>>;
};

export type GeneratePptTheme = {
  __typename?: 'GeneratePptTheme';
  headingFont: SuggesedThemeFont;
  headingFontWeight: Scalars['Int'];
  bodyFont: SuggesedThemeFont;
  bodyFontWeight: Scalars['Int'];
  headingColors: Array<Scalars['String']>;
  bodyColors: Array<Scalars['String']>;
  accentColors: Array<Scalars['String']>;
  cardColors: Array<Scalars['String']>;
  backgroundColors: Array<Scalars['String']>;
  logos: Array<SuggestedThemeLogo>;
};

export type GeneratePptThemeInput = {
  interactionId: Scalars['String'];
  workspaceId: Scalars['String'];
  fileUrl: Scalars['String'];
};

export type GeneratePptThemeResponse = {
  __typename?: 'GeneratePptThemeResponse';
  theme: GeneratePptTheme;
  thumbnails: Array<SlideThumbnail>;
};

export enum GenerationType {
  Generate = 'generate',
  PasteText = 'paste_text',
  ImportGoogleDoc = 'import_google_doc',
  ImportGoogleSlides = 'import_google_slides',
  UploadWordFile = 'upload_word_file',
  UploadPpt = 'upload_ppt',
  UploadPptV2 = 'upload_ppt_v2',
  ImportWebpage = 'import_webpage',
  ImportPdf = 'import_pdf'
}

export type GetPptHtmlInput = {
  fileUrl: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type GetPptHtmlResponse = {
  __typename?: 'GetPptHtmlResponse';
  slideHtml: Array<Scalars['String']>;
  htmlUrl: Scalars['String'];
};

export type InvitationInput = {
  userId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
};

export type Invite = {
  flow: Scalars['String'];
  inviteCode?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['String']>;
};


export type JsonPatch = {
  op?: Maybe<JsonPatchOperation>;
  path?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export enum JsonPatchOperation {
  Add = 'add',
  Remove = 'remove',
  Replace = 'replace',
  Copy = 'copy',
  Move = 'move',
  Test = 'test'
}

export type Link = {
  __typename?: 'Link';
  nodeType: Scalars['String'];
  url: Scalars['String'];
  status?: Maybe<Scalars['Int']>;
};

export type LinkInput = {
  url: Scalars['String'];
  nodeType: Scalars['String'];
};

export enum MediaType {
  Video = 'video',
  Embed = 'embed',
  Image = 'image'
}

export type MemoStats = {
  __typename?: 'MemoStats';
  memoId: Scalars['ID'];
  uniqueViewers?: Maybe<Scalars['Int']>;
  uniqueEditors?: Maybe<Scalars['Int']>;
  cardCount?: Maybe<Scalars['Int']>;
  lastOpened?: Maybe<Scalars['DateTime']>;
  viewers?: Maybe<Array<MemoViewerStats>>;
  dailyViews?: Maybe<DocDailyViews>;
};

export type MemoViewerStats = {
  __typename?: 'MemoViewerStats';
  user?: Maybe<User>;
  visitorId?: Maybe<Scalars['ID']>;
  lastOpened?: Maybe<Scalars['DateTime']>;
  cardsViewed?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  reportAbuse: Scalars['ID'];
  chatCompletion: Array<ChatMessageResponse>;
  createDocGeneration: DocGeneration;
  updateDocGenerationDraftInput: DocGeneration;
  generateDoc: Doc;
  generateDocFromImport: Doc;
  generateImportPptTheme: GeneratePptThemeResponse;
  getPptHtml: GetPptHtmlResponse;
  createCheckoutSession: CheckoutSessionResponse;
  updateSubscriptionProduct?: Maybe<WorkspaceSubscription>;
  createCard: Card;
  updateCard: Card;
  updateCardPreviewUrl: Card;
  archiveChannel: Channel;
  createChannel: Channel;
  updateChannel: Channel;
  joinChannel: Channel;
  leaveChannel: Channel;
  createComment: Comment;
  updateComment: Comment;
  updateCommentStatus: Comment;
  archiveComment: Comment;
  unarchiveComment: Comment;
  deductCredits?: Maybe<Credits>;
  createDoc: Doc;
  updateDoc: Doc;
  addCollaborators: Doc;
  removeCollaborators: Doc;
  addCollaboratorByAccessLink?: Maybe<Doc>;
  acceptDocInvitation?: Maybe<Doc>;
  duplicateDoc: Doc;
  archiveDoc: Doc;
  unarchiveDoc: Doc;
  deleteDoc: Scalars['ID'];
  updateDocUser?: Maybe<DocUser>;
  updatePublicChannels: Doc;
  setDocPassword: Doc;
  removeDocPassword: Doc;
  createFont: Font;
  updateFont: Font;
  archiveFont: Font;
  inviteMembers: Organization;
  inviteUsersToGamma: GammaInvitation;
  updateWorkspace: Organization;
  removeMember: Organization;
  revokeWorkspaceInvitation: Organization;
  updateMemberRole: User;
  updateUser: User;
  deleteUser: User;
  joinWorkspace: SigninResponse;
  leaveWorkspace: User;
  signin: SigninResponse;
  sendCode: SendCodeResponse;
  signup: User;
  resetPassword: SigninResponse;
  changePassword: SigninResponse;
  updateUserSettings?: Maybe<UserSettings>;
  createSavedMedia: SavedMedia;
  archiveSavedMedia: SavedMedia;
  deleteSavedMedia: Scalars['ID'];
  addReaction: Reaction;
  removeReaction: Reaction;
  createSite: Site;
  addDomain: Site;
  changeBuiltinDomain: Site;
  deleteDomain: Site;
  verifyDomain: Site;
  transferDomain: Domain;
  updateRoutes: Site;
  deploy: Site;
  setRoute: Site;
  updateSite: Site;
  archiveSite: Site;
  unarchiveSite: Site;
  publishDoc?: Maybe<PublishedDoc>;
  archivePage: PublishedDoc;
  publishSite: Site;
  unpublishSite: Site;
  enableSite: Site;
  disableSite: Site;
  createSnapshot: Snapshot;
  createTheme: Theme;
  updateTheme: Theme;
  makeThemeStandard: Theme;
  duplicateTheme: Theme;
  archiveTheme: Theme;
  unarchiveTheme: Theme;
};


export type MutationReportAbuseArgs = {
  input: AbuseReportInput;
};


export type MutationChatCompletionArgs = {
  input: ChatCompletionInput;
};


export type MutationCreateDocGenerationArgs = {
  input: CreateDocGenerationInput;
};


export type MutationUpdateDocGenerationDraftInputArgs = {
  docGenerateInputId: Scalars['ID'];
  input: UpdateDocGenerateInput;
};


export type MutationGenerateDocArgs = {
  docGenerateInputId: Scalars['ID'];
  options: GenerateDocOptions;
};


export type MutationGenerateDocFromImportArgs = {
  docGenerateInputId: Scalars['ID'];
  options: GenerateDocFromImportOptions;
};


export type MutationGenerateImportPptThemeArgs = {
  input: GeneratePptThemeInput;
};


export type MutationGetPptHtmlArgs = {
  input: GetPptHtmlInput;
};


export type MutationCreateCheckoutSessionArgs = {
  workspaceId: Scalars['ID'];
  items: Array<CheckoutItemInput>;
};


export type MutationUpdateSubscriptionProductArgs = {
  id: Scalars['ID'];
  productPriceId: Scalars['String'];
};


export type MutationCreateCardArgs = {
  id: Scalars['ID'];
  docId: Scalars['ID'];
};


export type MutationUpdateCardArgs = {
  input: UpdateCardInput;
};


export type MutationUpdateCardPreviewUrlArgs = {
  id: Scalars['ID'];
  docId: Scalars['ID'];
  previewUrl?: Maybe<Scalars['String']>;
};


export type MutationArchiveChannelArgs = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type MutationCreateChannelArgs = {
  input?: Maybe<CreateChannelInput>;
};


export type MutationUpdateChannelArgs = {
  input?: Maybe<UpdateChannelInput>;
};


export type MutationJoinChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationLeaveChannelArgs = {
  channelId: Scalars['ID'];
};


export type MutationCreateCommentArgs = {
  input?: Maybe<CreateCommentInput>;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpdateCommentStatusArgs = {
  input: UpdateCommentStatusInput;
};


export type MutationArchiveCommentArgs = {
  id: Scalars['ID'];
};


export type MutationUnarchiveCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeductCreditsArgs = {
  value: Scalars['Int'];
  workspaceId: Scalars['ID'];
};


export type MutationCreateDocArgs = {
  input?: Maybe<CreateDocInput>;
};


export type MutationUpdateDocArgs = {
  input: UpdateDocInput;
};


export type MutationAddCollaboratorsArgs = {
  docId: Scalars['ID'];
  collaborators: Array<DocCollaboratorInput>;
};


export type MutationRemoveCollaboratorsArgs = {
  docId: Scalars['ID'];
  collaborators: Array<DocCollaboratorRemovalInput>;
};


export type MutationAddCollaboratorByAccessLinkArgs = {
  docId: Scalars['ID'];
};


export type MutationAcceptDocInvitationArgs = {
  docId: Scalars['ID'];
  email: Scalars['String'];
  inviteCode: Scalars['String'];
};


export type MutationDuplicateDocArgs = {
  input: DuplicateDocInput;
};


export type MutationArchiveDocArgs = {
  id: Scalars['ID'];
};


export type MutationUnarchiveDocArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDocArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateDocUserArgs = {
  input?: Maybe<DocUserInput>;
};


export type MutationUpdatePublicChannelsArgs = {
  input?: Maybe<DocSharingInput>;
};


export type MutationSetDocPasswordArgs = {
  id: Scalars['ID'];
  password: Scalars['String'];
};


export type MutationRemoveDocPasswordArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFontArgs = {
  input?: Maybe<CreateFontInput>;
};


export type MutationUpdateFontArgs = {
  input?: Maybe<UpdateFontInput>;
};


export type MutationArchiveFontArgs = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type MutationInviteMembersArgs = {
  orgId: Scalars['ID'];
  invitees: Array<InvitationInput>;
};


export type MutationInviteUsersToGammaArgs = {
  emails: Array<Scalars['String']>;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceInput;
};


export type MutationRemoveMemberArgs = {
  workspaceId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRevokeWorkspaceInvitationArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateMemberRoleArgs = {
  workspaceId: Scalars['ID'];
  userId: Scalars['ID'];
  role?: Maybe<WorkspaceRole>;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationJoinWorkspaceArgs = {
  input: Invite;
};


export type MutationLeaveWorkspaceArgs = {
  workspaceId: Scalars['ID'];
};


export type MutationSigninArgs = {
  input?: Maybe<SigninInput>;
};


export type MutationSendCodeArgs = {
  input?: Maybe<SendCodeInput>;
};


export type MutationSignupArgs = {
  input?: Maybe<SignupInput>;
};


export type MutationResetPasswordArgs = {
  input?: Maybe<ResetPasswordInput>;
};


export type MutationChangePasswordArgs = {
  input?: Maybe<ChangePasswordInput>;
};


export type MutationUpdateUserSettingsArgs = {
  patch?: Maybe<Array<JsonPatch>>;
};


export type MutationCreateSavedMediaArgs = {
  input: CreateSavedMediaInput;
};


export type MutationArchiveSavedMediaArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSavedMediaArgs = {
  id: Scalars['ID'];
};


export type MutationAddReactionArgs = {
  input?: Maybe<AddReactionInput>;
};


export type MutationRemoveReactionArgs = {
  input?: Maybe<RemoveReactionInput>;
};


export type MutationCreateSiteArgs = {
  input?: Maybe<CreateSiteInput>;
};


export type MutationAddDomainArgs = {
  input?: Maybe<AddDomainInput>;
};


export type MutationChangeBuiltinDomainArgs = {
  input?: Maybe<ChangeBuiltinDomainInput>;
};


export type MutationDeleteDomainArgs = {
  id: Scalars['ID'];
};


export type MutationVerifyDomainArgs = {
  id: Scalars['ID'];
};


export type MutationTransferDomainArgs = {
  id: Scalars['ID'];
  siteId: Scalars['ID'];
};


export type MutationUpdateRoutesArgs = {
  input?: Maybe<UpdateSiteRoutesInput>;
};


export type MutationDeployArgs = {
  siteId: Scalars['ID'];
};


export type MutationSetRouteArgs = {
  input?: Maybe<SetRouteInput>;
};


export type MutationUpdateSiteArgs = {
  input?: Maybe<UpdateSiteInput>;
};


export type MutationArchiveSiteArgs = {
  id: Scalars['ID'];
};


export type MutationUnarchiveSiteArgs = {
  id: Scalars['ID'];
};


export type MutationPublishDocArgs = {
  id: Scalars['ID'];
};


export type MutationArchivePageArgs = {
  id: Scalars['ID'];
};


export type MutationPublishSiteArgs = {
  id: Scalars['ID'];
};


export type MutationUnpublishSiteArgs = {
  id: Scalars['ID'];
};


export type MutationEnableSiteArgs = {
  id: Scalars['ID'];
};


export type MutationDisableSiteArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSnapshotArgs = {
  input: CreateSnapshotInput;
};


export type MutationCreateThemeArgs = {
  input: CreateThemeInput;
};


export type MutationUpdateThemeArgs = {
  input: UpdateThemeInput;
};


export type MutationMakeThemeStandardArgs = {
  id: Scalars['ID'];
  newId?: Maybe<Scalars['ID']>;
};


export type MutationDuplicateThemeArgs = {
  id: Scalars['ID'];
  workspaceId?: Maybe<Scalars['ID']>;
};


export type MutationArchiveThemeArgs = {
  id: Scalars['ID'];
};


export type MutationUnarchiveThemeArgs = {
  id: Scalars['ID'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  inviteCode?: Maybe<Scalars['String']>;
  adminCount?: Maybe<Scalars['Int']>;
  memberCount?: Maybe<Scalars['Int']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  invitations?: Maybe<Array<WorkspaceInvitation>>;
  products?: Maybe<Array<Scalars['ID']>>;
  logoUrl?: Maybe<Scalars['String']>;
  defaultThemeId?: Maybe<Scalars['String']>;
  workspaceMemberPermission?: Maybe<Permission>;
  accessLinkPermission?: Maybe<Permission>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export enum PatchOperation {
  Add = 'add',
  Remove = 'remove',
  Replace = 'replace'
}

export enum Permission {
  Manage = 'manage',
  Edit = 'edit',
  Comment = 'comment',
  View = 'view'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  key: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<ProductPrice>>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  id: Scalars['ID'];
  price?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  discount?: Maybe<Discount>;
  frequencyUnit?: Maybe<FrequencyUnit>;
  frequencyInterval?: Maybe<Scalars['Int']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type ProductPriceInput = {
  frequency?: Maybe<Frequency>;
};

export type PublicDoc = {
  __typename?: 'PublicDoc';
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
};

export type PublicDocEditor = {
  __typename?: 'PublicDocEditor';
  user: PublicUser;
  docId: Scalars['ID'];
  lastEdited?: Maybe<Scalars['DateTime']>;
};

export type PublicRoute = {
  __typename?: 'PublicRoute';
  path: Scalars['String'];
  docId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type PublicSite = {
  __typename?: 'PublicSite';
  routes?: Maybe<Array<PublicRoute>>;
  hasCustomDomain: Scalars['Boolean'];
  faviconUrl?: Maybe<Scalars['String']>;
  navEnabled: Scalars['Boolean'];
  navContent?: Maybe<Scalars['JSON']>;
  gtmContainerId?: Maybe<Scalars['String']>;
};

export type PublicUser = {
  __typename?: 'PublicUser';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
};

export type PublishedDoc = {
  __typename?: 'PublishedDoc';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  publishedTime?: Maybe<Scalars['DateTime']>;
  editedTime?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<Organization>;
  publishedSnapshot?: Maybe<Snapshot>;
  cards?: Maybe<Array<Card>>;
  titleCard?: Maybe<Card>;
  theme?: Maybe<Theme>;
  editors?: Maybe<Array<PublicDocEditor>>;
  sites?: Maybe<Array<PublicSite>>;
  site: PublicSite;
  links?: Maybe<Array<Link>>;
};

export type Query = {
  __typename?: 'Query';
  assessRisk: RiskAssessment;
  canRunChatCompletion: Array<CanRunChatCompletionModelResponse>;
  docGeneration: DocGeneration;
  docGenerations: DocGenerationConnection;
  memoStats: MemoStats;
  docCardsStats: DocCardsStats;
  products?: Maybe<Array<Product>>;
  subscription?: Maybe<WorkspaceSubscription>;
  card?: Maybe<Card>;
  docCards: Array<Card>;
  channel?: Maybe<Channel>;
  channels?: Maybe<Array<Channel>>;
  workspaceChannels: ChannelConnection;
  credits?: Maybe<Credits>;
  docs: DocConnection;
  sharedDocs: DocConnection;
  channelDocActivity: DocActivityConnection;
  previewChannelDocs: DocConnection;
  doc?: Maybe<Doc>;
  docPermission?: Maybe<DocUserPermissions>;
  docInvitation?: Maybe<DocInvitation>;
  publishedDoc?: Maybe<PublishedDoc>;
  getDocPassword: Scalars['String'];
  publicDoc?: Maybe<PublicDoc>;
  font?: Maybe<Font>;
  fonts?: Maybe<Array<Font>>;
  workspace?: Maybe<Organization>;
  workspaceInvitation?: Maybe<WorkspaceInvitation>;
  workspaceMembers: WorkspaceMembersConnection;
  user?: Maybe<User>;
  verifyCode: VerifyCodeResponse;
  /** @deprecated Use userSavedMedia or workspaceSavedMedia instead */
  savedMedia: SavedMediaConnection;
  userSavedMedia: SavedMediaConnection;
  workspaceSavedMedia: SavedMediaConnection;
  search: Array<SearchResult>;
  site: Site;
  sites?: Maybe<Array<Site>>;
  domains?: Maybe<Array<Domain>>;
  checkDomain: DomainCheck;
  snapshots: SnapshotConnection;
  snapshot?: Maybe<Snapshot>;
  themes: Array<Theme>;
  theme?: Maybe<Theme>;
  themePreviewData?: Maybe<ThemePreviewData>;
};


export type QueryAssessRiskArgs = {
  token: Scalars['String'];
  action: RecaptchaAction;
};


export type QueryCanRunChatCompletionArgs = {
  input: CanRunChatCompletionRequest;
};


export type QueryDocGenerationArgs = {
  id: Scalars['ID'];
};


export type QueryDocGenerationsArgs = {
  workspaceId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  generationTypes?: Maybe<Array<GenerationType>>;
};


export type QueryMemoStatsArgs = {
  id: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
  viewerStatsSortBy?: Maybe<ViewerStatsSortOrder>;
};


export type QueryDocCardsStatsArgs = {
  docId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
};


export type QueryProductsArgs = {
  workspaceId?: Maybe<Scalars['ID']>;
  productPriceFilter?: Maybe<ProductPriceInput>;
};


export type QuerySubscriptionArgs = {
  workspaceId: Scalars['ID'];
  includeExpired?: Maybe<Scalars['Boolean']>;
};


export type QueryCardArgs = {
  id: Scalars['ID'];
  docId: Scalars['ID'];
};


export type QueryDocCardsArgs = {
  docId: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
  maxDepth?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
};


export type QueryChannelArgs = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
};


export type QueryChannelsArgs = {
  workspaceId: Scalars['ID'];
};


export type QueryWorkspaceChannelsArgs = {
  workspaceId: Scalars['ID'];
  sortBy?: Maybe<ChannelSortOrder>;
  query?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryCreditsArgs = {
  workspaceId: Scalars['ID'];
};


export type QueryDocsArgs = {
  workspaceId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<Scalars['ID']>;
  channelId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<DocSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


export type QuerySharedDocsArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<DocSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryChannelDocActivityArgs = {
  channelId: Scalars['ID'];
  sortBy?: Maybe<DocSortOrder>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryPreviewChannelDocsArgs = {
  channelId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryDocArgs = {
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
};


export type QueryDocPermissionArgs = {
  id: Scalars['ID'];
};


export type QueryDocInvitationArgs = {
  docId: Scalars['ID'];
  email: Scalars['String'];
  inviteCode: Scalars['String'];
};


export type QueryPublishedDocArgs = {
  id: Scalars['ID'];
};


export type QueryGetDocPasswordArgs = {
  id: Scalars['ID'];
};


export type QueryPublicDocArgs = {
  id: Scalars['ID'];
};


export type QueryFontArgs = {
  id: Scalars['ID'];
  workspaceId?: Maybe<Scalars['ID']>;
};


export type QueryFontsArgs = {
  workspaceId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<FontSortOrder>;
  archived?: Maybe<Scalars['Boolean']>;
};


export type QueryWorkspaceArgs = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryWorkspaceInvitationArgs = {
  email: Scalars['String'];
  inviteCode: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
};


export type QueryWorkspaceMembersArgs = {
  id: Scalars['ID'];
  disabled?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<WorkspaceMembersSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryVerifyCodeArgs = {
  input?: Maybe<VerifyCodeInput>;
};


export type QuerySavedMediaArgs = {
  docId?: Maybe<Scalars['ID']>;
  themeId?: Maybe<Scalars['ID']>;
  interactionId?: Maybe<Scalars['ID']>;
  type?: Maybe<MediaType>;
  context?: Maybe<SavedMediaContext>;
  source?: Maybe<Scalars['String']>;
  sortBy?: Maybe<SavedMediaSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryUserSavedMediaArgs = {
  workspaceId: Scalars['ID'];
  types: Array<MediaType>;
  source?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['ID']>;
  themeId?: Maybe<Scalars['ID']>;
  interactionId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<SavedMediaSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QueryWorkspaceSavedMediaArgs = {
  workspaceId: Scalars['ID'];
  types: Array<MediaType>;
  source?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['ID']>;
  themeId?: Maybe<Scalars['ID']>;
  interactionId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<SavedMediaSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QuerySearchArgs = {
  query: Scalars['String'];
  types: Array<SearchType>;
  orgId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
};


export type QuerySiteArgs = {
  id: Scalars['ID'];
};


export type QuerySitesArgs = {
  workspaceId: Scalars['ID'];
};


export type QueryDomainsArgs = {
  workspaceId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type QueryCheckDomainArgs = {
  name: Scalars['String'];
};


export type QuerySnapshotsArgs = {
  docId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
};


export type QuerySnapshotArgs = {
  docId: Scalars['ID'];
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
};


export type QueryThemesArgs = {
  workspaceId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
};


export type QueryThemeArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryThemePreviewDataArgs = {
  id?: Maybe<Scalars['ID']>;
};

export type Reaction = {
  __typename?: 'Reaction';
  docId: Scalars['String'];
  cardId?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  targetType: TargetType;
  emoji: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
  users?: Maybe<Array<User>>;
};

export enum RecaptchaAction {
  Signin = 'signin',
  Signup = 'signup',
  Reset = 'reset'
}

export type RemoveReactionInput = {
  docId: Scalars['String'];
  cardId?: Maybe<Scalars['String']>;
  targetId: Scalars['String'];
  emoji: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export enum Risk {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Unknown = 'unknown'
}

export type RiskAssessment = {
  __typename?: 'RiskAssessment';
  risk: Risk;
};

export type Route = {
  __typename?: 'Route';
  id: Scalars['ID'];
  siteId: Scalars['ID'];
  path: Scalars['String'];
  docId: Scalars['String'];
  offline?: Maybe<Scalars['Boolean']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  doc?: Maybe<Doc>;
};

export type SavedMedia = {
  __typename?: 'SavedMedia';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  workspaceId: Scalars['ID'];
  interactionId?: Maybe<Scalars['ID']>;
  context?: Maybe<SavedMediaContext>;
  docId?: Maybe<Scalars['ID']>;
  themeId?: Maybe<Scalars['ID']>;
  type: MediaType;
  source: Scalars['String'];
  attrs: Scalars['JSON'];
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type SavedMediaConnection = {
  __typename?: 'SavedMediaConnection';
  edges: Array<SavedMediaEdge>;
  nodes: Array<SavedMedia>;
  pageInfo: PageInfo;
};

export enum SavedMediaContext {
  Doc = 'doc',
  Theme = 'theme',
  Dashboard = 'dashboard'
}

export type SavedMediaEdge = {
  __typename?: 'SavedMediaEdge';
  node: SavedMedia;
  cursor?: Maybe<Scalars['String']>;
};

export enum SavedMediaSortField {
  CreatedTime = 'createdTime'
}

export type SavedMediaSortOrder = {
  field?: Maybe<SavedMediaSortField>;
  direction?: Maybe<SortDirection>;
};

export type SearchResult = Channel | DocResult | User;

export enum SearchType {
  User = 'user',
  Doc = 'doc',
  Channel = 'channel'
}

export type SendCodeInput = {
  email: Scalars['String'];
  token: Scalars['String'];
  scenario?: Maybe<SendCodeScenario>;
  invite?: Maybe<Invite>;
  referredByUserId?: Maybe<Scalars['String']>;
  partnerStackPartnerId?: Maybe<Scalars['String']>;
  partnerStackClickId?: Maybe<Scalars['String']>;
  settings?: Maybe<SendCodeSettings>;
};

export type SendCodeResponse = {
  __typename?: 'SendCodeResponse';
  emailSent?: Maybe<Scalars['Boolean']>;
  provider?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export enum SendCodeScenario {
  Reset = 'reset',
  Setup = 'setup'
}

export type SendCodeSettings = {
  locale?: Maybe<Scalars['String']>;
};

export type SetRouteInput = {
  path: Scalars['String'];
  docId: Scalars['String'];
  siteId: Scalars['String'];
  offline?: Maybe<Scalars['Boolean']>;
};

export type SigninInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  invite?: Maybe<Invite>;
};

export type SigninResponse = {
  __typename?: 'SigninResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type SignupInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  code: Scalars['String'];
  invite?: Maybe<Invite>;
};

export type Site = {
  __typename?: 'Site';
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  pageCount?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  domains: Array<Domain>;
  routes: Array<Route>;
  currentDeployment?: Maybe<Deployment>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  archived?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  faviconUrl?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  navEnabled: Scalars['Boolean'];
  navContent?: Maybe<Scalars['JSON']>;
  gtmContainerId?: Maybe<Scalars['String']>;
  hasCustomDomain?: Maybe<Scalars['Boolean']>;
};

export type SlideThumbnail = {
  __typename?: 'SlideThumbnail';
  index: Scalars['Int'];
  height: Scalars['Int'];
  width: Scalars['Int'];
  url: Scalars['String'];
};

export type Snapshot = {
  __typename?: 'Snapshot';
  id: Scalars['ID'];
  docId: Scalars['ID'];
  ydoc?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  text: Scalars['String'];
  schemaVersion: Scalars['Int'];
  sha1: Scalars['String'];
  editors: Array<User>;
  createdTime: Scalars['DateTime'];
  updatedTime: Scalars['DateTime'];
};

export type SnapshotConnection = {
  __typename?: 'SnapshotConnection';
  edges: Array<SnapshotEdge>;
  nodes: Array<Snapshot>;
  pageInfo: PageInfo;
};

export type SnapshotEdge = {
  __typename?: 'SnapshotEdge';
  node: Snapshot;
  cursor?: Maybe<Scalars['String']>;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type Subscription = {
  __typename?: 'Subscription';
  docCards: Array<Card>;
  channels?: Maybe<Array<Channel>>;
  credits?: Maybe<Credits>;
  doc?: Maybe<DocSubscriptionResult>;
  channelDocActivity?: Maybe<Array<DocActivity>>;
};


export type SubscriptionDocCardsArgs = {
  docId: Scalars['ID'];
};


export type SubscriptionChannelsArgs = {
  workspaceId: Scalars['ID'];
};


export type SubscriptionCreditsArgs = {
  workspaceId: Scalars['ID'];
};


export type SubscriptionDocArgs = {
  id: Scalars['ID'];
};


export type SubscriptionChannelDocActivityArgs = {
  channelId: Scalars['ID'];
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  id: Scalars['ID'];
  key: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  productPrice?: Maybe<ProductPrice>;
  units?: Maybe<Scalars['Int']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Paused = 'paused',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
  UnpaidPartial = 'unpaid_partial'
}

export type SuggesedThemeFont = {
  __typename?: 'SuggesedThemeFont';
  original: Scalars['String'];
  suggested: Scalars['String'];
};

export type SuggestedThemeLogo = {
  __typename?: 'SuggestedThemeLogo';
  url: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export enum TargetType {
  Card = 'card',
  Comment = 'comment',
  Decoration = 'decoration'
}

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['ID'];
  workspaceId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  headingFont?: Maybe<Scalars['String']>;
  headingFontWeight?: Maybe<Scalars['Int']>;
  bodyFont?: Maybe<Scalars['String']>;
  bodyFontWeight?: Maybe<Scalars['Int']>;
  accentColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  config: Scalars['JSON'];
  priority: Scalars['Int'];
  docCount?: Maybe<Scalars['Int']>;
  siteCount?: Maybe<Scalars['Int']>;
  archived: Scalars['Boolean'];
  createdBy?: Maybe<User>;
  fonts?: Maybe<Array<ThemeFont>>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type ThemeFont = {
  __typename?: 'ThemeFont';
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type ThemePreviewData = {
  __typename?: 'ThemePreviewData';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  accentColor?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  bodyFont?: Maybe<ThemePreviewFont>;
  headingFont?: Maybe<ThemePreviewFont>;
};

export type ThemePreviewFont = {
  __typename?: 'ThemePreviewFont';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type TransformImageOptions = {
  license?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  generateStyle?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  stylePreset?: Maybe<Scalars['String']>;
};

export type UpdateCardInput = {
  id: Scalars['ID'];
  docId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  text?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  depth?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
};

export type UpdateChannelInput = {
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type UpdateCommentInput = {
  id: Scalars['ID'];
  cardId?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['JSON']>;
  targetText?: Maybe<Scalars['String']>;
  targetHtml?: Maybe<Scalars['String']>;
};

export type UpdateCommentStatusInput = {
  id: Scalars['ID'];
  status: CommentStatus;
  docId: Scalars['String'];
};

export type UpdateDocGenerateInput = {
  interactionId?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  format?: Maybe<DocGenerateFormat>;
  settings?: Maybe<Scalars['JSON']>;
};

export type UpdateDocInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleCardId?: Maybe<Scalars['ID']>;
  titleEdited?: Maybe<Scalars['Boolean']>;
  themeId?: Maybe<Scalars['String']>;
  orgAccess?: Maybe<Permission>;
  publicAccess?: Maybe<Permission>;
  accessLinks?: Maybe<Array<AccessLinkInput>>;
  sharing?: Maybe<Array<DocJsonPatch>>;
  attributes?: Maybe<Scalars['JSON']>;
  links?: Maybe<Array<LinkInput>>;
};

export type UpdateFontInput = {
  id: Scalars['String'];
  workspaceId: Scalars['String'];
  data?: Maybe<Array<JsonPatch>>;
};

export type UpdateRouteInput = {
  path: Scalars['String'];
  docId?: Maybe<Scalars['String']>;
  offline?: Maybe<Scalars['Boolean']>;
};

export type UpdateSiteInput = {
  siteId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  faviconUrl?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['String']>;
  navEnabled?: Maybe<Scalars['Boolean']>;
  navContent?: Maybe<Scalars['JSON']>;
  gtmContainerId?: Maybe<Scalars['String']>;
};

export type UpdateSiteRoutesInput = {
  siteId: Scalars['ID'];
  routes?: Maybe<Array<UpdateRouteInput>>;
};

export type UpdateThemeInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  headingFont?: Maybe<Scalars['String']>;
  headingFontWeight?: Maybe<Scalars['Int']>;
  bodyFont?: Maybe<Scalars['String']>;
  bodyFontWeight?: Maybe<Scalars['Int']>;
  accentColor?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSON']>;
  priority?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['ID']>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  jobFunction?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
};

export type UpdateWorkspaceInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  defaultThemeId?: Maybe<Scalars['String']>;
  workspaceMemberPermission?: Maybe<Permission>;
  accessLinkPermission?: Maybe<Permission>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  jobFunction?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  billingCustomerId?: Maybe<Scalars['String']>;
  channelIds?: Maybe<Array<Scalars['ID']>>;
  /** @deprecated Use workspaceMemberships instead. */
  organizations?: Maybe<Array<Organization>>;
  workspaceMemberships?: Maybe<Array<WorkspaceMembership>>;
  settings?: Maybe<UserSettings>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  aiEnabled?: Maybe<Scalars['Boolean']>;
  needsEditorOnboarding?: Maybe<Scalars['Boolean']>;
  showWorkspaceSetup?: Maybe<Scalars['Boolean']>;
  showGetStarted?: Maybe<Scalars['Boolean']>;
  welcomeDocId?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  localeToastSeen?: Maybe<Scalars['Boolean']>;
  unsubscribeAnnouncementEmails?: Maybe<Scalars['Boolean']>;
  unsubscribeOnboardingEmails?: Maybe<Scalars['Boolean']>;
  goals?: Maybe<Scalars['JSON']>;
  preferredAiImageModel?: Maybe<Scalars['String']>;
};

export type VerifyCodeInput = {
  email: Scalars['String'];
  code: Scalars['String'];
};

export type VerifyCodeResponse = {
  __typename?: 'VerifyCodeResponse';
  verified?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
};

export type ViewerCount = {
  __typename?: 'ViewerCount';
  everyone?: Maybe<Scalars['Int']>;
  viewersOnly?: Maybe<Scalars['Int']>;
};

export enum ViewerStatsSortField {
  LastOpened = 'lastOpened'
}

export type ViewerStatsSortOrder = {
  field?: Maybe<ViewerStatsSortField>;
  direction?: Maybe<SortDirection>;
};

export type WorkspaceInvitation = {
  __typename?: 'WorkspaceInvitation';
  id: Scalars['ID'];
  email: Scalars['String'];
  workspaceId?: Maybe<Scalars['ID']>;
  workspaceName?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<User>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type WorkspaceMemberEdge = {
  __typename?: 'WorkspaceMemberEdge';
  node: User;
  cursor?: Maybe<Scalars['String']>;
};

export type WorkspaceMembersConnection = {
  __typename?: 'WorkspaceMembersConnection';
  edges: Array<WorkspaceMemberEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export enum WorkspaceMembersSortField {
  CreatedTime = 'createdTime',
  DisplayName = 'displayName',
  Role = 'role'
}

export type WorkspaceMembersSortOrder = {
  field?: Maybe<WorkspaceMembersSortField>;
  direction?: Maybe<SortDirection>;
};

export type WorkspaceMembership = {
  __typename?: 'WorkspaceMembership';
  workspace?: Maybe<Organization>;
  role?: Maybe<WorkspaceRole>;
  disabled?: Maybe<Scalars['Boolean']>;
  createdTime?: Maybe<Scalars['DateTime']>;
};

export enum WorkspacePermission {
  Admin = 'admin',
  View = 'view'
}

export enum WorkspaceRole {
  Admin = 'admin',
  Member = 'member'
}

export type WorkspaceSubscription = {
  __typename?: 'WorkspaceSubscription';
  id: Scalars['ID'];
  status?: Maybe<SubscriptionStatus>;
  createdByUser?: Maybe<User>;
  billingSubscriptionId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<SubscriptionProduct>>>;
  discountPercent?: Maybe<Scalars['Int']>;
  nextBillingTime?: Maybe<Scalars['DateTime']>;
  scheduledUpdateTime?: Maybe<Scalars['DateTime']>;
  expirationTime?: Maybe<Scalars['DateTime']>;
  cancellationRequestedTime?: Maybe<Scalars['DateTime']>;
  createdTime?: Maybe<Scalars['DateTime']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
};

export type ReportAbuseMutationVariables = Exact<{
  input: AbuseReportInput;
}>;


export type ReportAbuseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reportAbuse'>
);

export type AssessRiskQueryVariables = Exact<{
  token: Scalars['String'];
  action: RecaptchaAction;
}>;


export type AssessRiskQuery = (
  { __typename?: 'Query' }
  & { assessRisk: (
    { __typename?: 'RiskAssessment' }
    & Pick<RiskAssessment, 'risk'>
  ) }
);

export type DocGenerateInputFragmentFragment = (
  { __typename?: 'DocGenerateInput' }
  & Pick<DocGenerateInput, 'id' | 'docGenerationId' | 'interactionId' | 'status' | 'prompt' | 'content' | 'format' | 'settings' | 'docId' | 'docGenerateOutputId' | 'createdTime' | 'updatedTime'>
);

export type DocGenerationFragmentFragment = (
  { __typename?: 'DocGeneration' }
  & Pick<DocGeneration, 'id' | 'generationType' | 'userId' | 'workspaceId' | 'createdTime' | 'updatedTime'>
  & { draftInput?: Maybe<(
    { __typename?: 'DocGenerateInput' }
    & DocGenerateInputFragmentFragment
  )>, docGenerateInputs: Array<(
    { __typename?: 'DocGenerateInput' }
    & DocGenerateInputFragmentFragment
  )> }
);

export type ChatCompletionMutationVariables = Exact<{
  input: ChatCompletionInput;
}>;


export type ChatCompletionMutation = (
  { __typename?: 'Mutation' }
  & { chatCompletion: Array<(
    { __typename?: 'ChatMessageResponse' }
    & Pick<ChatMessageResponse, 'role' | 'content'>
  )> }
);

export type CanRunChatCompletionQueryVariables = Exact<{
  input: CanRunChatCompletionRequest;
}>;


export type CanRunChatCompletionQuery = (
  { __typename?: 'Query' }
  & { canRunChatCompletion: Array<(
    { __typename?: 'CanRunChatCompletionModelResponse' }
    & Pick<CanRunChatCompletionModelResponse, 'model' | 'canRun'>
  )> }
);

export type CreateDocGenerationMutationVariables = Exact<{
  input: CreateDocGenerationInput;
}>;


export type CreateDocGenerationMutation = (
  { __typename?: 'Mutation' }
  & { createDocGeneration: (
    { __typename?: 'DocGeneration' }
    & DocGenerationFragmentFragment
  ) }
);

export type UpdateDocGenerationDraftInputMutationVariables = Exact<{
  docGenerateInputId: Scalars['ID'];
  input: UpdateDocGenerateInput;
}>;


export type UpdateDocGenerationDraftInputMutation = (
  { __typename?: 'Mutation' }
  & { updateDocGenerationDraftInput: (
    { __typename?: 'DocGeneration' }
    & DocGenerationFragmentFragment
  ) }
);

export type GenerateDocMutationVariables = Exact<{
  docGenerateInputId: Scalars['ID'];
  options: GenerateDocOptions;
}>;


export type GenerateDocMutation = (
  { __typename?: 'Mutation' }
  & { generateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { docGenerateInput?: Maybe<(
      { __typename?: 'DocGenerateInput' }
      & DocGenerateInputFragmentFragment
    )> }
  ) }
);

export type GenerateDocFromImportMutationVariables = Exact<{
  docGenerateInputId: Scalars['ID'];
  options: GenerateDocFromImportOptions;
}>;


export type GenerateDocFromImportMutation = (
  { __typename?: 'Mutation' }
  & { generateDocFromImport: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { docGenerateInput?: Maybe<(
      { __typename?: 'DocGenerateInput' }
      & DocGenerateInputFragmentFragment
    )> }
  ) }
);

export type GetDocGenerationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocGenerationQuery = (
  { __typename?: 'Query' }
  & { docGeneration: (
    { __typename?: 'DocGeneration' }
    & DocGenerationFragmentFragment
  ) }
);

export type GetDocGenerationsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  generationTypes?: Maybe<Array<GenerationType> | GenerationType>;
}>;


export type GetDocGenerationsQuery = (
  { __typename?: 'Query' }
  & { docGenerations: (
    { __typename?: 'DocGenerationConnection' }
    & { edges: Array<(
      { __typename?: 'DocGenerationEdge' }
      & Pick<DocGenerationEdge, 'cursor'>
      & { node: (
        { __typename?: 'DocGeneration' }
        & DocGenerationFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GenerateImportPptThemeMutationVariables = Exact<{
  input: GeneratePptThemeInput;
}>;


export type GenerateImportPptThemeMutation = (
  { __typename?: 'Mutation' }
  & { generateImportPptTheme: (
    { __typename?: 'GeneratePptThemeResponse' }
    & { thumbnails: Array<(
      { __typename?: 'SlideThumbnail' }
      & Pick<SlideThumbnail, 'url' | 'width' | 'height' | 'index'>
    )>, theme: (
      { __typename?: 'GeneratePptTheme' }
      & Pick<GeneratePptTheme, 'headingFontWeight' | 'bodyFontWeight' | 'headingColors' | 'bodyColors' | 'accentColors' | 'cardColors' | 'backgroundColors'>
      & { headingFont: (
        { __typename?: 'SuggesedThemeFont' }
        & Pick<SuggesedThemeFont, 'suggested' | 'original'>
      ), bodyFont: (
        { __typename?: 'SuggesedThemeFont' }
        & Pick<SuggesedThemeFont, 'suggested' | 'original'>
      ), logos: Array<(
        { __typename?: 'SuggestedThemeLogo' }
        & Pick<SuggestedThemeLogo, 'url' | 'width' | 'height'>
      )> }
    ) }
  ) }
);

export type GetPptHtmlMutationVariables = Exact<{
  input: GetPptHtmlInput;
}>;


export type GetPptHtmlMutation = (
  { __typename?: 'Mutation' }
  & { getPptHtml: (
    { __typename?: 'GetPptHtmlResponse' }
    & Pick<GetPptHtmlResponse, 'slideHtml' | 'htmlUrl'>
  ) }
);

export type GetCreditsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type GetCreditsQuery = (
  { __typename?: 'Query' }
  & { credits?: Maybe<(
    { __typename?: 'Credits' }
    & Pick<Credits, 'userId' | 'credits'>
  )> }
);

export type DeductCreditsMutationVariables = Exact<{
  value: Scalars['Int'];
  workspaceId: Scalars['ID'];
}>;


export type DeductCreditsMutation = (
  { __typename?: 'Mutation' }
  & { deductCredits?: Maybe<(
    { __typename?: 'Credits' }
    & Pick<Credits, 'userId' | 'credits'>
  )> }
);

export type SubscribeToCreditsSubscriptionVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type SubscribeToCreditsSubscription = (
  { __typename?: 'Subscription' }
  & { credits?: Maybe<(
    { __typename?: 'Credits' }
    & Pick<Credits, 'userId' | 'credits'>
  )> }
);

export type GetDocWithSnapshotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocWithSnapshotQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title'>
    & { theme?: Maybe<(
      { __typename?: 'Theme' }
      & DefaultThemeFieldsFragment
    )>, organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'products'>
    )>, currentSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'id' | 'docId' | 'content'>
    )>, titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl' | 'text'>
    )>, cards?: Maybe<Array<(
      { __typename?: 'Card' }
      & DefaultCardFieldsFragment
    )>> }
  )> }
);

export type GetDocPreviewUrlQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocPreviewUrlQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl'>
    )> }
  )> }
);

export type FontFragment = (
  { __typename?: 'Font' }
  & Pick<Font, 'id' | 'name' | 'cssUrl' | 'workspaceId' | 'archived' | 'fontFileCount'>
  & { fontFiles?: Maybe<Array<(
    { __typename?: 'FontFile' }
    & Pick<FontFile, 'id' | 'name' | 'weight' | 'isItalic' | 'fileType' | 'sourceUrl'>
  )>> }
);

export type GetWorkspaceFontsQueryVariables = Exact<{
  workspaceId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<FontSortOrder>;
}>;


export type GetWorkspaceFontsQuery = (
  { __typename?: 'Query' }
  & { fonts?: Maybe<Array<(
    { __typename?: 'Font' }
    & FontFragment
  )>> }
);

export type GetGlobalFontsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGlobalFontsQuery = (
  { __typename?: 'Query' }
  & { fonts?: Maybe<Array<(
    { __typename?: 'Font' }
    & FontFragment
  )>> }
);

export type GetFontQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetFontQuery = (
  { __typename?: 'Query' }
  & { font?: Maybe<(
    { __typename?: 'Font' }
    & FontFragment
  )> }
);

export type CreateFontMutationVariables = Exact<{
  input?: Maybe<CreateFontInput>;
}>;


export type CreateFontMutation = (
  { __typename?: 'Mutation' }
  & { createFont: (
    { __typename?: 'Font' }
    & FontFragment
  ) }
);

export type UpdateFontMutationVariables = Exact<{
  input?: Maybe<UpdateFontInput>;
}>;


export type UpdateFontMutation = (
  { __typename?: 'Mutation' }
  & { updateFont: (
    { __typename?: 'Font' }
    & FontFragment
  ) }
);

export type ArchiveFontMutationVariables = Exact<{
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type ArchiveFontMutation = (
  { __typename?: 'Mutation' }
  & { archiveFont: (
    { __typename?: 'Font' }
    & FontFragment
  ) }
);

export type SavedMediaFragmentFragment = (
  { __typename?: 'SavedMedia' }
  & Pick<SavedMedia, 'id' | 'userId' | 'workspaceId' | 'interactionId' | 'docId' | 'themeId' | 'context' | 'type' | 'source' | 'attrs' | 'createdTime' | 'updatedTime'>
);

export type CreateSavedMediaMutationVariables = Exact<{
  input: CreateSavedMediaInput;
}>;


export type CreateSavedMediaMutation = (
  { __typename?: 'Mutation' }
  & { createSavedMedia: (
    { __typename?: 'SavedMedia' }
    & SavedMediaFragmentFragment
  ) }
);

export type ArchiveSavedMediaMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveSavedMediaMutation = (
  { __typename?: 'Mutation' }
  & { archiveSavedMedia: (
    { __typename?: 'SavedMedia' }
    & Pick<SavedMedia, 'id'>
  ) }
);

export type DeleteSavedMediaMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSavedMediaMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteSavedMedia'>
);

export type GetSavedMediaQueryVariables = Exact<{
  docId?: Maybe<Scalars['ID']>;
  themeId?: Maybe<Scalars['ID']>;
  interactionId?: Maybe<Scalars['ID']>;
  context?: Maybe<SavedMediaContext>;
  type: MediaType;
  source: Scalars['String'];
  sortBy?: Maybe<SavedMediaSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type GetSavedMediaQuery = (
  { __typename?: 'Query' }
  & { savedMedia: (
    { __typename?: 'SavedMediaConnection' }
    & { edges: Array<(
      { __typename?: 'SavedMediaEdge' }
      & { node: (
        { __typename?: 'SavedMedia' }
        & SavedMediaFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetUserSavedMediaQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  types: Array<MediaType> | MediaType;
  source?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['ID']>;
  themeId?: Maybe<Scalars['ID']>;
  interactionId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<SavedMediaSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type GetUserSavedMediaQuery = (
  { __typename?: 'Query' }
  & { userSavedMedia: (
    { __typename?: 'SavedMediaConnection' }
    & { edges: Array<(
      { __typename?: 'SavedMediaEdge' }
      & { node: (
        { __typename?: 'SavedMedia' }
        & SavedMediaFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetWorkspaceSavedMediaQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  types: Array<MediaType> | MediaType;
  source?: Maybe<Scalars['String']>;
  docId?: Maybe<Scalars['ID']>;
  themeId?: Maybe<Scalars['ID']>;
  interactionId?: Maybe<Scalars['ID']>;
  sortBy?: Maybe<SavedMediaSortOrder>;
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
}>;


export type GetWorkspaceSavedMediaQuery = (
  { __typename?: 'Query' }
  & { workspaceSavedMedia: (
    { __typename?: 'SavedMediaConnection' }
    & { edges: Array<(
      { __typename?: 'SavedMediaEdge' }
      & { node: (
        { __typename?: 'SavedMedia' }
        & SavedMediaFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type ProductsQueryVariables = Exact<{
  workspaceId?: Maybe<Scalars['ID']>;
  productPriceFilter?: Maybe<ProductPriceInput>;
}>;


export type ProductsQuery = (
  { __typename?: 'Query' }
  & { products?: Maybe<Array<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'key'>
    & { prices?: Maybe<Array<(
      { __typename?: 'ProductPrice' }
      & Pick<ProductPrice, 'id' | 'price' | 'currency' | 'frequencyUnit' | 'frequencyInterval'>
      & { discount?: Maybe<(
        { __typename?: 'Discount' }
        & Pick<Discount, 'price' | 'discountPercent' | 'discountAmount'>
      )> }
    )>> }
  )>> }
);

export type CreateCheckoutSessionMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  items: Array<CheckoutItemInput> | CheckoutItemInput;
}>;


export type CreateCheckoutSessionMutation = (
  { __typename?: 'Mutation' }
  & { createCheckoutSession: (
    { __typename?: 'CheckoutSessionResponse' }
    & Pick<CheckoutSessionResponse, 'url'>
  ) }
);

export type UpdateSubscriptionProductMutationVariables = Exact<{
  id: Scalars['ID'];
  productPriceId: Scalars['String'];
}>;


export type UpdateSubscriptionProductMutation = (
  { __typename?: 'Mutation' }
  & { updateSubscriptionProduct?: Maybe<(
    { __typename?: 'WorkspaceSubscription' }
    & Pick<WorkspaceSubscription, 'id'>
  )> }
);

export type DocFieldsFragmentFragment = (
  { __typename?: 'Doc' }
  & Pick<Doc, 'id' | 'title' | 'archived' | 'createdTime' | 'updatedTime' | 'editedTime' | 'currentSnapshotId' | 'orgAccess'>
  & { titleCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'previewUrl' | 'createdTime' | 'updatedTime'>
  )>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'profileImageUrl' | 'displayName' | 'id'>
  )>, collaborators?: Maybe<Array<(
    { __typename?: 'DocCollaborator' }
    & Pick<DocCollaborator, 'docId' | 'permission'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )>>, docUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
  )>, channels?: Maybe<Array<(
    { __typename?: 'DocChannel' }
    & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
  )>>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, editors?: Maybe<Array<(
    { __typename?: 'DocEditor' }
    & Pick<DocEditor, 'docId' | 'lastEdited'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    ) }
  )>> }
);

export type SearchChannelResultFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id' | 'name' | 'slug' | 'memberCount' | 'isMember' | 'workspaceId' | 'createdTime' | 'updatedTime'>
  & { members?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'profileImageUrl' | 'displayName' | 'id'>
  )>> }
);

export type SearchDocResultFragment = (
  { __typename?: 'DocResult' }
  & Pick<DocResult, 'id' | 'title' | 'text' | 'archived' | 'previewUrl' | 'orgAccess' | 'updatedTime' | 'createdTime' | 'editedTime'>
  & { createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
  )>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
  )>, editors?: Maybe<Array<(
    { __typename?: 'DocEditor' }
    & Pick<DocEditor, 'lastViewed' | 'lastEdited'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
    ) }
  )>>, docUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'lastViewed' | 'lastEdited' | 'favorited'>
  )> }
);

export type SearchDocsAndChannelsQueryVariables = Exact<{
  workspaceId?: Maybe<Scalars['ID']>;
  query: Scalars['String'];
}>;


export type SearchDocsAndChannelsQuery = (
  { __typename?: 'Query' }
  & { search: Array<(
    { __typename: 'Channel' }
    & SearchChannelResultFragment
  ) | (
    { __typename: 'DocResult' }
    & SearchDocResultFragment
  ) | { __typename: 'User' }> }
);

export type GetDocsForGlobalSearchQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<DocSortOrder>;
}>;


export type GetDocsForGlobalSearchQuery = (
  { __typename?: 'Query' }
  & { docs: (
    { __typename?: 'DocConnection' }
    & { edges: Array<(
      { __typename?: 'DocEdge' }
      & { node: (
        { __typename?: 'Doc' }
        & DocFieldsFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type DocCollaboratorsUpdateFragment = (
  { __typename?: 'Doc' }
  & { collaborators?: Maybe<Array<(
    { __typename?: 'DocCollaborator' }
    & Pick<DocCollaborator, 'docId' | 'permission' | 'guest'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    ) }
  )>> }
);

export type ShareSearchUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
);

export type SharingSearchUserQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type SharingSearchUserQuery = (
  { __typename?: 'Query' }
  & { search: Array<{ __typename: 'Channel' } | { __typename: 'DocResult' } | (
    { __typename: 'User' }
    & ShareSearchUserFragment
  )> }
);

export type DocChannelsUpdateFragment = (
  { __typename?: 'Doc' }
  & { channels?: Maybe<Array<(
    { __typename?: 'DocChannel' }
    & Pick<DocChannel, 'id' | 'name' | 'slug'>
  )>> }
);

export type ShareSearchChannelFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id' | 'name' | 'slug' | 'isMember' | 'memberCount'>
  & { members?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  )>> }
);

export type SharingSearchChannelQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  query: Scalars['String'];
}>;


export type SharingSearchChannelQuery = (
  { __typename?: 'Query' }
  & { search: Array<(
    { __typename: 'Channel' }
    & ShareSearchChannelFragment
  ) | { __typename: 'DocResult' } | { __typename: 'User' }> }
);

export type UpdatePublicChannelsMutationVariables = Exact<{
  input: DocSharingInput;
}>;


export type UpdatePublicChannelsMutation = (
  { __typename?: 'Mutation' }
  & { updatePublicChannels: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { channels?: Maybe<Array<(
      { __typename?: 'DocChannel' }
      & Pick<DocChannel, 'id' | 'name' | 'permission'>
    )>> }
  ) }
);

export type UpdateDocSharingMutationVariables = Exact<{
  id: Scalars['ID'];
  sharing?: Maybe<Array<DocJsonPatch> | DocJsonPatch>;
}>;


export type UpdateDocSharingMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { channels?: Maybe<Array<(
      { __typename?: 'DocChannel' }
      & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
    )>> }
  ) }
);

export type DefaultDomainsFieldsFragment = (
  { __typename?: 'Domain' }
  & Pick<Domain, 'id' | 'name' | 'status' | 'builtin' | 'createdTime' | 'siteId'>
);

export type SiteFragmentFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'id' | 'workspaceId' | 'name' | 'hasCustomDomain' | 'createdTime' | 'updatedTime' | 'previewUrl' | 'pageCount' | 'archived' | 'enabled' | 'faviconUrl' | 'themeId' | 'navEnabled' | 'navContent' | 'gtmContainerId'>
  & { domains: Array<(
    { __typename?: 'Domain' }
    & DefaultDomainsFieldsFragment
  )>, routes: Array<(
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'siteId' | 'docId' | 'path' | 'offline'>
    & { doc?: Maybe<(
      { __typename?: 'Doc' }
      & Pick<Doc, 'id' | 'title' | 'orgAccess' | 'publishedTime' | 'publishedSnapshotId' | 'currentSnapshotId' | 'editedTime' | 'archived' | 'createdTime'>
    )> }
  )>, currentDeployment?: Maybe<(
    { __typename?: 'Deployment' }
    & Pick<Deployment, 'id' | 'buildHost' | 'deploymentTime' | 'siteSettings'>
  )>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'profileImageUrl' | 'displayName' | 'id'>
  )>, theme?: Maybe<(
    { __typename?: 'Theme' }
    & DefaultThemeFieldsFragment
  )> }
);

export type CreateSiteMutationVariables = Exact<{
  input: CreateSiteInput;
}>;


export type CreateSiteMutation = (
  { __typename?: 'Mutation' }
  & { createSite: (
    { __typename?: 'Site' }
    & SiteFragmentFragment
  ) }
);

export type UpdateSiteMutationVariables = Exact<{
  input: UpdateSiteInput;
}>;


export type UpdateSiteMutation = (
  { __typename?: 'Mutation' }
  & { updateSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'faviconUrl' | 'themeId' | 'navEnabled' | 'navContent'>
  ) }
);

export type ArchiveSiteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveSiteMutation = (
  { __typename?: 'Mutation' }
  & { archiveSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'archived'>
  ) }
);

export type UnarchiveSiteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnarchiveSiteMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'enabled' | 'archived'>
  ) }
);

export type DisableSiteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DisableSiteMutation = (
  { __typename?: 'Mutation' }
  & { disableSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'enabled'>
  ) }
);

export type EnableSiteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EnableSiteMutation = (
  { __typename?: 'Mutation' }
  & { enableSite: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'enabled'>
  ) }
);

export type UpdateRoutesMutationVariables = Exact<{
  input: UpdateSiteRoutesInput;
}>;


export type UpdateRoutesMutation = (
  { __typename?: 'Mutation' }
  & { updateRoutes: (
    { __typename?: 'Site' }
    & Pick<Site, 'id'>
    & { routes: Array<(
      { __typename?: 'Route' }
      & Pick<Route, 'path' | 'docId'>
    )> }
  ) }
);

export type AddDomainMutationVariables = Exact<{
  input: AddDomainInput;
}>;


export type AddDomainMutation = (
  { __typename?: 'Mutation' }
  & { addDomain: (
    { __typename?: 'Site' }
    & Pick<Site, 'id'>
    & { domains: Array<(
      { __typename?: 'Domain' }
      & Pick<Domain, 'name' | 'status'>
    )>, routes: Array<(
      { __typename?: 'Route' }
      & Pick<Route, 'path' | 'docId'>
    )> }
  ) }
);

export type DeleteDomainMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDomainMutation = (
  { __typename?: 'Mutation' }
  & { deleteDomain: (
    { __typename?: 'Site' }
    & Pick<Site, 'id'>
  ) }
);

export type ChangeBuiltinDomainMutationVariables = Exact<{
  input: ChangeBuiltinDomainInput;
}>;


export type ChangeBuiltinDomainMutation = (
  { __typename?: 'Mutation' }
  & { changeBuiltinDomain: (
    { __typename?: 'Site' }
    & Pick<Site, 'id'>
    & { domains: Array<(
      { __typename?: 'Domain' }
      & Pick<Domain, 'name' | 'status' | 'builtin'>
    )>, routes: Array<(
      { __typename?: 'Route' }
      & Pick<Route, 'path' | 'docId'>
    )> }
  ) }
);

export type GetSitesQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type GetSitesQuery = (
  { __typename?: 'Query' }
  & { sites?: Maybe<Array<(
    { __typename?: 'Site' }
    & SiteFragmentFragment
  )>> }
);

export type GetSiteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSiteQuery = (
  { __typename?: 'Query' }
  & { site: (
    { __typename?: 'Site' }
    & SiteFragmentFragment
  ) }
);

export type GetDomainsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  name: Scalars['String'];
}>;


export type GetDomainsQuery = (
  { __typename?: 'Query' }
  & { domains?: Maybe<Array<(
    { __typename?: 'Domain' }
    & Pick<Domain, 'siteId' | 'siteName' | 'siteArchived'>
    & DefaultDomainsFieldsFragment
  )>> }
);

export type GetDocWithPublishedSnapshotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocWithPublishedSnapshotQuery = (
  { __typename?: 'Query' }
  & { publishedDoc?: Maybe<(
    { __typename?: 'PublishedDoc' }
    & Pick<PublishedDoc, 'id' | 'title' | 'editedTime'>
    & { organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'products' | 'id'>
    )>, editors?: Maybe<Array<(
      { __typename?: 'PublicDocEditor' }
      & Pick<PublicDocEditor, 'docId' | 'lastEdited'>
      & { user: (
        { __typename?: 'PublicUser' }
        & Pick<PublicUser, 'id' | 'displayName' | 'profileImageUrl'>
      ) }
    )>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & DefaultThemeFieldsFragment
    )>, publishedSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'id' | 'docId' | 'content'>
    )>, titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl' | 'text' | 'title'>
    )>, cards?: Maybe<Array<(
      { __typename?: 'Card' }
      & DefaultCardFieldsFragment
    )>>, site: (
      { __typename?: 'PublicSite' }
      & Pick<PublicSite, 'hasCustomDomain' | 'faviconUrl' | 'navContent' | 'navEnabled' | 'gtmContainerId'>
      & { routes?: Maybe<Array<(
        { __typename?: 'PublicRoute' }
        & Pick<PublicRoute, 'path' | 'docId'>
      )>> }
    ), links?: Maybe<Array<(
      { __typename?: 'Link' }
      & Pick<Link, 'url' | 'nodeType' | 'status'>
    )>> }
  )> }
);

export type GetPublishedDocQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPublishedDocQuery = (
  { __typename?: 'Query' }
  & { publishedDoc?: Maybe<(
    { __typename?: 'PublishedDoc' }
    & Pick<PublishedDoc, 'id' | 'publishedTime'>
    & { publishedSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'id' | 'content'>
    )> }
  )> }
);

export type PublishSiteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublishSiteMutation = (
  { __typename?: 'Mutation' }
  & { publishSite: (
    { __typename?: 'Site' }
    & SiteFragmentFragment
  ) }
);

export type PublishDocMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublishDocMutation = (
  { __typename?: 'Mutation' }
  & { publishDoc?: Maybe<(
    { __typename?: 'PublishedDoc' }
    & Pick<PublishedDoc, 'id' | 'publishedTime'>
    & { publishedSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'id'>
    )> }
  )> }
);

export type ArchivePageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchivePageMutation = (
  { __typename?: 'Mutation' }
  & { archivePage: (
    { __typename?: 'PublishedDoc' }
    & Pick<PublishedDoc, 'id' | 'publishedTime'>
    & { publishedSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'id'>
    )> }
  ) }
);

export type VerifyDomainMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VerifyDomainMutation = (
  { __typename?: 'Mutation' }
  & { verifyDomain: (
    { __typename?: 'Site' }
    & Pick<Site, 'id'>
    & { domains: Array<(
      { __typename?: 'Domain' }
      & Pick<Domain, 'id' | 'name' | 'status'>
    )> }
  ) }
);

export type TransferDomainMutationVariables = Exact<{
  id: Scalars['ID'];
  siteId: Scalars['ID'];
}>;


export type TransferDomainMutation = (
  { __typename?: 'Mutation' }
  & { transferDomain: (
    { __typename?: 'Domain' }
    & Pick<Domain, 'id' | 'siteId' | 'name'>
  ) }
);

export type SetRouteMutationVariables = Exact<{
  input: SetRouteInput;
}>;


export type SetRouteMutation = (
  { __typename?: 'Mutation' }
  & { setRoute: (
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name'>
    & { routes: Array<(
      { __typename?: 'Route' }
      & Pick<Route, 'path' | 'docId' | 'offline'>
      & { doc?: Maybe<(
        { __typename?: 'Doc' }
        & Pick<Doc, 'title'>
      )> }
    )> }
  ) }
);

export type CheckDomainQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type CheckDomainQuery = (
  { __typename?: 'Query' }
  & { checkDomain: (
    { __typename?: 'DomainCheck' }
    & Pick<DomainCheck, 'ok'>
    & { dns?: Maybe<(
      { __typename?: 'DnsCheckResponse' }
      & Pick<DnsCheckResponse, 'status'>
      & { errors?: Maybe<Array<(
        { __typename?: 'CheckError' }
        & Pick<CheckError, 'code' | 'message'>
      )>> }
    )>, ssl?: Maybe<(
      { __typename?: 'CheckResponse' }
      & Pick<CheckResponse, 'ok'>
      & { errors?: Maybe<Array<(
        { __typename?: 'CheckError' }
        & Pick<CheckError, 'code' | 'message'>
      )>> }
    )>, http?: Maybe<(
      { __typename?: 'CheckResponse' }
      & Pick<CheckResponse, 'ok'>
      & { errors?: Maybe<Array<(
        { __typename?: 'CheckError' }
        & Pick<CheckError, 'code' | 'message'>
      )>> }
    )>, actions?: Maybe<Array<(
      { __typename?: 'DomainAction' }
      & Pick<DomainAction, 'code' | 'data'>
    )>> }
  ) }
);

export type DefaultThemeFontFieldsFragment = (
  { __typename?: 'ThemeFont' }
  & Pick<ThemeFont, 'id' | 'name' | 'url'>
);

export type DefaultThemeFieldsFragment = (
  { __typename?: 'Theme' }
  & Pick<Theme, 'id' | 'workspaceId' | 'name' | 'headingFont' | 'headingFontWeight' | 'bodyFont' | 'bodyFontWeight' | 'accentColor' | 'logoUrl' | 'config' | 'priority' | 'previewUrl' | 'archived' | 'createdTime' | 'updatedTime'>
  & { fonts?: Maybe<Array<(
    { __typename?: 'ThemeFont' }
    & DefaultThemeFontFieldsFragment
  )>> }
);

export type CreateThemeMutationVariables = Exact<{
  input: CreateThemeInput;
}>;


export type CreateThemeMutation = (
  { __typename?: 'Mutation' }
  & { createTheme: (
    { __typename?: 'Theme' }
    & Pick<Theme, 'docCount'>
    & DefaultThemeFieldsFragment
  ) }
);

export type UpdateThemeMutationVariables = Exact<{
  input: UpdateThemeInput;
}>;


export type UpdateThemeMutation = (
  { __typename?: 'Mutation' }
  & { updateTheme: (
    { __typename?: 'Theme' }
    & Pick<Theme, 'docCount'>
    & DefaultThemeFieldsFragment
  ) }
);

export type MakeThemeStandardMutationVariables = Exact<{
  id: Scalars['ID'];
  newId?: Maybe<Scalars['ID']>;
}>;


export type MakeThemeStandardMutation = (
  { __typename?: 'Mutation' }
  & { makeThemeStandard: (
    { __typename?: 'Theme' }
    & Pick<Theme, 'docCount'>
    & DefaultThemeFieldsFragment
  ) }
);

export type DuplicateThemeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DuplicateThemeMutation = (
  { __typename?: 'Mutation' }
  & { duplicateTheme: (
    { __typename?: 'Theme' }
    & DefaultThemeFieldsFragment
  ) }
);

export type GetThemeQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type GetThemeQuery = (
  { __typename?: 'Query' }
  & { theme?: Maybe<(
    { __typename?: 'Theme' }
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    )> }
    & DefaultThemeFieldsFragment
  )> }
);

export type GetThemeWithCountQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type GetThemeWithCountQuery = (
  { __typename?: 'Query' }
  & { theme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'docCount' | 'siteCount'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    )> }
    & DefaultThemeFieldsFragment
  )> }
);

export type GetThemesQueryVariables = Exact<{
  workspaceId?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
}>;


export type GetThemesQuery = (
  { __typename?: 'Query' }
  & { themes: Array<(
    { __typename?: 'Theme' }
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    )> }
    & DefaultThemeFieldsFragment
  )> }
);

export type ArchiveThemeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveThemeMutation = (
  { __typename?: 'Mutation' }
  & { archiveTheme: (
    { __typename?: 'Theme' }
    & Pick<Theme, 'docCount' | 'siteCount'>
    & DefaultThemeFieldsFragment
  ) }
);

export type UnarchiveThemeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnarchiveThemeMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveTheme: (
    { __typename?: 'Theme' }
    & DefaultThemeFieldsFragment
  ) }
);

export type DefaultCardFieldsFragment = (
  { __typename?: 'Card' }
  & Pick<Card, 'id' | 'archived' | 'docId' | 'title' | 'text' | 'previewUrl' | 'createdTime' | 'updatedTime'>
);

export type CreateCardMutationVariables = Exact<{
  id: Scalars['ID'];
  docId: Scalars['ID'];
}>;


export type CreateCardMutation = (
  { __typename?: 'Mutation' }
  & { createCard: (
    { __typename?: 'Card' }
    & Pick<Card, 'id'>
  ) }
);

export type GetCardQueryVariables = Exact<{
  id: Scalars['ID'];
  docId: Scalars['ID'];
}>;


export type GetCardQuery = (
  { __typename?: 'Query' }
  & { card?: Maybe<(
    { __typename?: 'Card' }
    & DefaultCardFieldsFragment
  )> }
);

export type GetCardsQueryVariables = Exact<{
  docId: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
}>;


export type GetCardsQuery = (
  { __typename?: 'Query' }
  & { docCards: Array<(
    { __typename?: 'Card' }
    & DefaultCardFieldsFragment
  )> }
);

export type SubscribeToCardsSubscriptionVariables = Exact<{
  docId: Scalars['ID'];
}>;


export type SubscribeToCardsSubscription = (
  { __typename?: 'Subscription' }
  & { docCards: Array<(
    { __typename?: 'Card' }
    & DefaultCardFieldsFragment
  )> }
);

export type GetDocMentionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocMentionQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title' | 'updatedTime' | 'editedTime' | 'createdTime'>
    & { titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    )>, docUser?: Maybe<(
      { __typename?: 'DocUser' }
      & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
    )>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & Pick<DocEditor, 'lastViewed' | 'lastEdited'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
      ) }
    )>> }
  )> }
);

export type SubscribeToDocMentionSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubscribeToDocMentionSubscription = (
  { __typename?: 'Subscription' }
  & { doc?: Maybe<(
    { __typename?: 'DocSubscription' }
    & Pick<DocSubscription, 'id' | 'title' | 'updatedTime' | 'editedTime'>
    & { titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl'>
    )>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & Pick<DocEditor, 'lastViewed' | 'lastEdited'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
      ) }
    )>> }
  ) | { __typename?: 'DocCommentsSubscription' } | { __typename?: 'DocLinksSubscription' } | { __typename?: 'DocReactionsSubscription' }> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'jobFunction' | 'referrer'>
  ) }
);

export type UpdateUserSettingsMutationVariables = Exact<{
  patch?: Maybe<Array<JsonPatch> | JsonPatch>;
}>;


export type UpdateUserSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateUserSettings?: Maybe<(
    { __typename?: 'UserSettings' }
    & Pick<UserSettings, 'showWorkspaceSetup' | 'showGetStarted' | 'welcomeDocId' | 'locale' | 'unsubscribeAnnouncementEmails' | 'unsubscribeOnboardingEmails' | 'goals'>
  )> }
);

export type GetGraphqlUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGraphqlUserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'profileImageUrl' | 'email'>
    & { workspaceMemberships?: Maybe<Array<(
      { __typename?: 'WorkspaceMembership' }
      & Pick<WorkspaceMembership, 'role' | 'disabled'>
      & { workspace?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'adminCount' | 'memberCount'>
      )> }
    )>> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type GetWorkspaceSubscriptionQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  includeExpired?: Maybe<Scalars['Boolean']>;
}>;


export type GetWorkspaceSubscriptionQuery = (
  { __typename?: 'Query' }
  & { subscription?: Maybe<(
    { __typename?: 'WorkspaceSubscription' }
    & Pick<WorkspaceSubscription, 'id' | 'status' | 'billingSubscriptionId' | 'discountPercent' | 'createdTime' | 'nextBillingTime' | 'expirationTime' | 'cancellationRequestedTime'>
    & { createdByUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'displayName' | 'billingCustomerId'>
    )>, products?: Maybe<Array<Maybe<(
      { __typename?: 'SubscriptionProduct' }
      & Pick<SubscriptionProduct, 'id' | 'name' | 'key' | 'units' | 'createdTime'>
      & { productPrice?: Maybe<(
        { __typename?: 'ProductPrice' }
        & Pick<ProductPrice, 'id' | 'price' | 'currency' | 'frequencyUnit' | 'frequencyInterval'>
      )> }
    )>>> }
  )> }
);

export type UpdateWorkspaceMutationVariables = Exact<{
  input: UpdateWorkspaceInput;
}>;


export type UpdateWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkspace: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'defaultThemeId' | 'logoUrl' | 'workspaceMemberPermission' | 'accessLinkPermission'>
  ) }
);

export type GetWorkspaceMembersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  sortBy?: Maybe<WorkspaceMembersSortOrder>;
  id: Scalars['ID'];
}>;


export type GetWorkspaceMembersQuery = (
  { __typename?: 'Query' }
  & { workspaceMembers: (
    { __typename?: 'WorkspaceMembersConnection' }
    & { edges: Array<(
      { __typename?: 'WorkspaceMemberEdge' }
      & { node: (
        { __typename?: 'User' }
        & Pick<User, 'displayName' | 'id' | 'createdTime' | 'profileImageUrl' | 'email'>
        & { workspaceMemberships?: Maybe<Array<(
          { __typename?: 'WorkspaceMembership' }
          & Pick<WorkspaceMembership, 'role' | 'createdTime' | 'disabled'>
          & { workspace?: Maybe<(
            { __typename?: 'Organization' }
            & Pick<Organization, 'id'>
          )> }
        )>> }
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetWorkspaceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetWorkspaceQuery = (
  { __typename?: 'Query' }
  & { workspace?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'adminCount' | 'memberCount' | 'defaultThemeId' | 'logoUrl' | 'workspaceMemberPermission' | 'accessLinkPermission'>
    & { invitations?: Maybe<Array<(
      { __typename?: 'WorkspaceInvitation' }
      & Pick<WorkspaceInvitation, 'id' | 'email' | 'createdTime'>
    )>> }
  )> }
);

export type UpdateMemberRoleMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  userId: Scalars['ID'];
  role?: Maybe<WorkspaceRole>;
}>;


export type UpdateMemberRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateMemberRole: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { workspaceMemberships?: Maybe<Array<(
      { __typename?: 'WorkspaceMembership' }
      & Pick<WorkspaceMembership, 'role' | 'disabled' | 'createdTime'>
      & { workspace?: Maybe<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      )> }
    )>> }
  ) }
);

export type RemoveMemberMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type RemoveMemberMutation = (
  { __typename?: 'Mutation' }
  & { removeMember: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'adminCount'>
  ) }
);

export type RevokeWorkspaceInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RevokeWorkspaceInvitationMutation = (
  { __typename?: 'Mutation' }
  & { revokeWorkspaceInvitation: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { invitations?: Maybe<Array<(
      { __typename?: 'WorkspaceInvitation' }
      & Pick<WorkspaceInvitation, 'id' | 'email' | 'createdTime'>
    )>> }
  ) }
);

export type SearchMentionQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  query: Scalars['String'];
  types?: Maybe<Array<SearchType> | SearchType>;
}>;


export type SearchMentionQuery = (
  { __typename?: 'Query' }
  & { search: Array<{ __typename: 'Channel' } | (
    { __typename: 'DocResult' }
    & Pick<DocResult, 'id' | 'title' | 'text' | 'archived' | 'previewUrl' | 'orgAccess' | 'updatedTime' | 'createdTime' | 'editedTime'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & Pick<DocEditor, 'lastViewed' | 'lastEdited'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
      ) }
    )>>, docUser?: Maybe<(
      { __typename?: 'DocUser' }
      & Pick<DocUser, 'lastViewed' | 'lastEdited' | 'favorited'>
    )> }
  ) | (
    { __typename: 'User' }
    & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  )> }
);

export type ReactionFieldsFragment = (
  { __typename?: 'Reaction' }
  & Pick<Reaction, 'emoji' | 'count' | 'targetId' | 'targetType' | 'docId'>
  & { users?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
  )>> }
);

export type CommentAuthorFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'displayName' | 'profileImageUrl' | 'email'>
);

export type ReplyFieldsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'commentId' | 'content' | 'createdTime' | 'updatedTime' | 'archived'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & CommentAuthorFieldsFragment
  )>, reactions?: Maybe<Array<(
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  )>> }
);

export type CommentFieldsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'docId' | 'cardId' | 'content' | 'targetText' | 'targetHtml' | 'createdTime' | 'updatedTime' | 'archived' | 'targetId' | 'status'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & CommentAuthorFieldsFragment
  )>, replies?: Maybe<Array<(
    { __typename?: 'Comment' }
    & ReplyFieldsFragment
  )>>, reactions?: Maybe<Array<(
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  )>> }
);

export type DocCollaboratorFieldsFragment = (
  { __typename?: 'DocCollaborator' }
  & Pick<DocCollaborator, 'docId' | 'permission' | 'guest' | 'accessLinkId'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  ) }
);

export type DocInvitationsFieldsFragment = (
  { __typename?: 'DocInvitation' }
  & Pick<DocInvitation, 'id' | 'docId' | 'email' | 'permission'>
);

export type DocChannelFieldsFragment = (
  { __typename?: 'DocChannel' }
  & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
);

export type DocEditorsFieldsFragment = (
  { __typename?: 'DocEditor' }
  & Pick<DocEditor, 'lastEdited'>
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  ) }
);

export type DocSiteFieldsFragment = (
  { __typename?: 'Site' }
  & Pick<Site, 'id' | 'archived' | 'workspaceId' | 'name' | 'hasCustomDomain' | 'createdTime' | 'updatedTime' | 'previewUrl' | 'pageCount' | 'enabled' | 'faviconUrl' | 'themeId' | 'navEnabled' | 'navContent' | 'gtmContainerId'>
  & { domains: Array<(
    { __typename?: 'Domain' }
    & Pick<Domain, 'id' | 'siteId' | 'name' | 'status' | 'builtin'>
  )>, routes: Array<(
    { __typename?: 'Route' }
    & Pick<Route, 'id' | 'siteId' | 'docId' | 'path' | 'offline'>
    & { doc?: Maybe<(
      { __typename?: 'Doc' }
      & Pick<Doc, 'id' | 'title'>
    )> }
  )>, currentDeployment?: Maybe<(
    { __typename?: 'Deployment' }
    & Pick<Deployment, 'id' | 'siteId' | 'buildHost' | 'deploymentTime'>
  )> }
);

export type AddCollaboratorsMutationVariables = Exact<{
  docId: Scalars['ID'];
  collaborators: Array<DocCollaboratorInput> | DocCollaboratorInput;
}>;


export type AddCollaboratorsMutation = (
  { __typename?: 'Mutation' }
  & { addCollaborators: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>>, invitations?: Maybe<Array<(
      { __typename?: 'DocInvitation' }
      & DocInvitationsFieldsFragment
    )>> }
  ) }
);

export type RemoveCollaboratorsMutationVariables = Exact<{
  docId: Scalars['ID'];
  collaborators: Array<DocCollaboratorRemovalInput> | DocCollaboratorRemovalInput;
}>;


export type RemoveCollaboratorsMutation = (
  { __typename?: 'Mutation' }
  & { removeCollaborators: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>>, invitations?: Maybe<Array<(
      { __typename?: 'DocInvitation' }
      & DocInvitationsFieldsFragment
    )>> }
  ) }
);

export type AddCollaboratorByAccessLinkMutationVariables = Exact<{
  docId: Scalars['ID'];
}>;


export type AddCollaboratorByAccessLinkMutation = (
  { __typename?: 'Mutation' }
  & { addCollaboratorByAccessLink?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>> }
  )> }
);

export type GetDocQueryVariables = Exact<{
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
}>;


export type GetDocQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title' | 'titleEdited' | 'archived' | 'disabled' | 'createdTime' | 'updatedTime' | 'editedTime' | 'savedTime' | 'currentSnapshotId' | 'publishedSnapshotId' | 'publishedTime' | 'orgAccess'>
    & { publishedSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'content'>
    )>, comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & CommentFieldsFragment
    )>>, reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>>, accessLinks?: Maybe<Array<(
      { __typename?: 'AccessLink' }
      & Pick<AccessLink, 'id' | 'docId' | 'type' | 'permission' | 'token' | 'url'>
    )>>, links?: Maybe<Array<(
      { __typename?: 'Link' }
      & Pick<Link, 'url' | 'status'>
    )>>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'products'>
    )>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & { fonts?: Maybe<Array<(
        { __typename?: 'ThemeFont' }
        & Pick<ThemeFont, 'id' | 'name' | 'url'>
      )>> }
      & DefaultThemeFieldsFragment
    )>, collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>>, invitations?: Maybe<Array<(
      { __typename?: 'DocInvitation' }
      & DocInvitationsFieldsFragment
    )>>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & DocEditorsFieldsFragment
    )>>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName' | 'id' | 'email'>
    )>, channels?: Maybe<Array<(
      { __typename?: 'DocChannel' }
      & DocChannelFieldsFragment
    )>>, site?: Maybe<(
      { __typename?: 'Site' }
      & DocSiteFieldsFragment
    )>, docGenerateInput?: Maybe<(
      { __typename?: 'DocGenerateInput' }
      & Pick<DocGenerateInput, 'id' | 'settings'>
      & { docGeneration?: Maybe<(
        { __typename?: 'DocGeneration' }
        & Pick<DocGeneration, 'id' | 'generationType'>
      )> }
    )> }
  )> }
);

export type GetDocRobotsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocRobotsQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title' | 'createdTime' | 'updatedTime'>
    & { currentSnapshot?: Maybe<(
      { __typename?: 'Snapshot' }
      & Pick<Snapshot, 'id' | 'docId' | 'content'>
    )>, titleCard?: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'previewUrl' | 'text'>
    )>, organization?: Maybe<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'products'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName' | 'id' | 'email'>
    )> }
  )> }
);

export type SubscribeToDocSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SubscribeToDocSubscription = (
  { __typename?: 'Subscription' }
  & { doc?: Maybe<(
    { __typename?: 'DocSubscription' }
    & Pick<DocSubscription, 'id' | 'title' | 'titleEdited' | 'archived' | 'updatedTime' | 'editedTime' | 'savedTime' | 'publishedTime' | 'orgAccess'>
    & { accessLinks?: Maybe<Array<(
      { __typename?: 'AccessLink' }
      & Pick<AccessLink, 'id' | 'docId' | 'type' | 'permission' | 'token' | 'url'>
    )>>, channels?: Maybe<Array<(
      { __typename?: 'DocChannel' }
      & DocChannelFieldsFragment
    )>>, collaborators?: Maybe<Array<(
      { __typename?: 'DocCollaborator' }
      & DocCollaboratorFieldsFragment
    )>>, editors?: Maybe<Array<(
      { __typename?: 'DocEditor' }
      & DocEditorsFieldsFragment
    )>>, invitations?: Maybe<Array<(
      { __typename?: 'DocInvitation' }
      & DocInvitationsFieldsFragment
    )>>, theme?: Maybe<(
      { __typename?: 'Theme' }
      & DefaultThemeFieldsFragment
    )>, reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>> }
  ) | (
    { __typename?: 'DocCommentsSubscription' }
    & Pick<DocCommentsSubscription, 'id'>
    & { comments?: Maybe<Array<(
      { __typename?: 'Comment' }
      & CommentFieldsFragment
    )>> }
  ) | (
    { __typename?: 'DocLinksSubscription' }
    & Pick<DocLinksSubscription, 'id'>
    & { links?: Maybe<Array<(
      { __typename?: 'Link' }
      & Pick<Link, 'url' | 'status'>
    )>> }
  ) | (
    { __typename?: 'DocReactionsSubscription' }
    & Pick<DocReactionsSubscription, 'id'>
    & { reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>> }
  )> }
);

export type UpdateDocTitleMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  titleEdited?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateDocTitleMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title' | 'titleEdited'>
  ) }
);

export type UpdateDocOrgAccessMutationVariables = Exact<{
  id: Scalars['ID'];
  orgAccess?: Maybe<Permission>;
}>;


export type UpdateDocOrgAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'orgAccess'>
  ) }
);

export type UpdateDocAccessLinksMutationVariables = Exact<{
  id: Scalars['ID'];
  accessLinks?: Maybe<Array<AccessLinkInput> | AccessLinkInput>;
}>;


export type UpdateDocAccessLinksMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { accessLinks?: Maybe<Array<(
      { __typename?: 'AccessLink' }
      & Pick<AccessLink, 'permission' | 'id'>
    )>> }
  ) }
);

export type UpdateDocThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  themeId?: Maybe<Scalars['String']>;
}>;


export type UpdateDocThemeMutation = (
  { __typename?: 'Mutation' }
  & { updateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { theme?: Maybe<(
      { __typename?: 'Theme' }
      & DefaultThemeFieldsFragment
    )> }
  ) }
);

export type InviteMembersMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  invitees: Array<InvitationInput> | InvitationInput;
}>;


export type InviteMembersMutation = (
  { __typename?: 'Mutation' }
  & { inviteMembers: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { invitations?: Maybe<Array<(
      { __typename?: 'WorkspaceInvitation' }
      & Pick<WorkspaceInvitation, 'email'>
    )>> }
  ) }
);

export type CreateCommentMutationVariables = Exact<{
  input?: Maybe<CreateCommentInput>;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'docId' | 'cardId' | 'content' | 'targetText' | 'targetHtml' | 'createdTime' | 'updatedTime' | 'commentId' | 'archived' | 'targetId' | 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & CommentAuthorFieldsFragment
    )>, replies?: Maybe<Array<(
      { __typename?: 'Comment' }
      & ReplyFieldsFragment
    )>>, reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>> }
  ) }
);

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;


export type UpdateCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'docId' | 'content' | 'updatedTime'>
  ) }
);

export type UpdateCommentStatusMutationVariables = Exact<{
  input: UpdateCommentStatusInput;
}>;


export type UpdateCommentStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateCommentStatus: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'status' | 'updatedTime'>
  ) }
);

export type RemoveCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCommentMutation = (
  { __typename?: 'Mutation' }
  & { archiveComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'archived'>
  ) }
);

export type DocReactionsCreateFragment = (
  { __typename?: 'Doc' }
  & { reactions?: Maybe<Array<(
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  )>> }
);

export type DocCommentsCreateFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'docId' | 'cardId' | 'content' | 'targetText' | 'targetHtml' | 'createdTime' | 'updatedTime' | 'archived' | 'targetId' | 'status'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & CommentAuthorFieldsFragment
    )> }
  )>> }
);

export type DocRepliesCreateFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
    & { replies?: Maybe<Array<(
      { __typename?: 'Comment' }
      & ReplyFieldsFragment
    )>> }
  )>> }
);

export type DocCommentsUpdateFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'docId' | 'content' | 'updatedTime'>
    & { reactions?: Maybe<Array<(
      { __typename?: 'Reaction' }
      & ReactionFieldsFragment
    )>> }
  )>> }
);

export type DocRepliesUpdateFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
    & { replies?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'docId' | 'content' | 'updatedTime'>
      & { reactions?: Maybe<Array<(
        { __typename?: 'Reaction' }
        & ReactionFieldsFragment
      )>> }
    )>> }
  )>> }
);

export type DocCommentsRemoveFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'archived'>
  )>> }
);

export type DocRepliesRemoveFragment = (
  { __typename?: 'Doc' }
  & { comments?: Maybe<Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id'>
    & { replies?: Maybe<Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'archived'>
    )>> }
  )>> }
);

export type AddReactionMutationVariables = Exact<{
  reactionInput: AddReactionInput;
}>;


export type AddReactionMutation = (
  { __typename?: 'Mutation' }
  & { addReaction: (
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  ) }
);

export type RemoveReactionMutationVariables = Exact<{
  reactionInput: RemoveReactionInput;
}>;


export type RemoveReactionMutation = (
  { __typename?: 'Mutation' }
  & { removeReaction: (
    { __typename?: 'Reaction' }
    & ReactionFieldsFragment
  ) }
);

export type GetMemoAnalyticsQueryVariables = Exact<{
  memoId: Scalars['ID'];
}>;


export type GetMemoAnalyticsQuery = (
  { __typename?: 'Query' }
  & { memoStats: (
    { __typename?: 'MemoStats' }
    & Pick<MemoStats, 'memoId' | 'uniqueViewers' | 'uniqueEditors' | 'cardCount' | 'lastOpened'>
    & { viewers?: Maybe<Array<(
      { __typename?: 'MemoViewerStats' }
      & Pick<MemoViewerStats, 'visitorId' | 'lastOpened' | 'cardsViewed'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
      )> }
    )>>, dailyViews?: Maybe<(
      { __typename?: 'DocDailyViews' }
      & Pick<DocDailyViews, 'dayCount' | 'timezone'>
      & { everyone?: Maybe<Array<(
        { __typename?: 'DocDayStats' }
        & Pick<DocDayStats, 'date' | 'uniqueVisitors'>
      )>>, viewers?: Maybe<Array<(
        { __typename?: 'DocDayStats' }
        & Pick<DocDayStats, 'date' | 'uniqueVisitors'>
      )>> }
    )> }
  ) }
);

export type GetDocCardsAnalyticsQueryVariables = Exact<{
  docId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
}>;


export type GetDocCardsAnalyticsQuery = (
  { __typename?: 'Query' }
  & { docCardsStats: (
    { __typename?: 'DocCardsStats' }
    & Pick<DocCardsStats, 'docId' | 'uniqueVisitors' | 'uniqueEditors' | 'cardCount'>
    & { everyone?: Maybe<Array<(
      { __typename?: 'CardStats' }
      & Pick<CardStats, 'cardId' | 'viewTimePercent' | 'viewersPercent'>
    )>>, viewers?: Maybe<Array<(
      { __typename?: 'CardStats' }
      & Pick<CardStats, 'cardId' | 'viewTimePercent' | 'viewersPercent'>
    )>> }
  ) }
);

export type SetDocPasswordMutationVariables = Exact<{
  docId: Scalars['ID'];
  password: Scalars['String'];
}>;


export type SetDocPasswordMutation = (
  { __typename?: 'Mutation' }
  & { setDocPassword: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { accessLinks?: Maybe<Array<(
      { __typename?: 'AccessLink' }
      & Pick<AccessLink, 'id' | 'permission' | 'type'>
    )>> }
  ) }
);

export type RemoveDocPasswordMutationVariables = Exact<{
  docId: Scalars['ID'];
}>;


export type RemoveDocPasswordMutation = (
  { __typename?: 'Mutation' }
  & { removeDocPassword: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
    & { accessLinks?: Maybe<Array<(
      { __typename?: 'AccessLink' }
      & Pick<AccessLink, 'id' | 'permission' | 'type'>
    )>> }
  ) }
);

export type GetDocPasswordQueryVariables = Exact<{
  docId: Scalars['ID'];
}>;


export type GetDocPasswordQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDocPassword'>
);

export type GetPublicDocQueryVariables = Exact<{
  docId: Scalars['ID'];
}>;


export type GetPublicDocQuery = (
  { __typename?: 'Query' }
  & { publicDoc?: Maybe<(
    { __typename?: 'PublicDoc' }
    & Pick<PublicDoc, 'id' | 'logoUrl'>
  )> }
);

export type GetDocCurrentSnapshotIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocCurrentSnapshotIdQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'currentSnapshotId'>
  )> }
);

export type CreateDocMutationVariables = Exact<{
  workspaceId: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  themeId?: Maybe<Scalars['String']>;
  source?: Maybe<DocSource>;
  initialContent?: Maybe<BaseSnapshotInput>;
  channels?: Maybe<Array<DocChannelInput> | DocChannelInput>;
  orgAccess?: Maybe<Permission>;
  mainPage?: Maybe<Scalars['Boolean']>;
}>;


export type CreateDocMutation = (
  { __typename?: 'Mutation' }
  & { createDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
  ) }
);

export type ArchiveDocMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ArchiveDocMutation = (
  { __typename?: 'Mutation' }
  & { archiveDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'archived'>
  ) }
);

export type DeleteDocMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDocMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDoc'>
);

export type UnArchiveDocMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnArchiveDocMutation = (
  { __typename?: 'Mutation' }
  & { unarchiveDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'archived'>
  ) }
);

export type FavoriteDocMutationVariables = Exact<{
  input: DocUserInput;
}>;


export type FavoriteDocMutation = (
  { __typename?: 'Mutation' }
  & { updateDocUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
  )> }
);

export type DocUserUpdateFragment = (
  { __typename?: 'Doc' }
  & { docUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
  )> }
);

export type HomeDocFieldsFragment = (
  { __typename?: 'Doc' }
  & Pick<Doc, 'id' | 'title' | 'archived' | 'createdTime' | 'updatedTime' | 'editedTime' | 'currentSnapshotId' | 'orgAccess'>
  & { titleCard?: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'previewUrl' | 'createdTime' | 'updatedTime'>
  )>, createdBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'profileImageUrl' | 'displayName' | 'id' | 'email'>
  )>, collaborators?: Maybe<Array<(
    { __typename?: 'DocCollaborator' }
    & Pick<DocCollaborator, 'docId' | 'permission'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )>>, docUser?: Maybe<(
    { __typename?: 'DocUser' }
    & Pick<DocUser, 'id' | 'docId' | 'lastViewed' | 'lastEdited' | 'favorited'>
  )>, channels?: Maybe<Array<(
    { __typename?: 'DocChannel' }
    & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
  )>>, organization?: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
  )>, editors?: Maybe<Array<(
    { __typename?: 'DocEditor' }
    & Pick<DocEditor, 'docId' | 'lastEdited'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
    ) }
  )>>, accessLinks?: Maybe<Array<(
    { __typename?: 'AccessLink' }
    & Pick<AccessLink, 'id' | 'docId' | 'permission' | 'token' | 'url'>
  )>>, site?: Maybe<(
    { __typename?: 'Site' }
    & Pick<Site, 'id' | 'name' | 'archived' | 'enabled' | 'faviconUrl' | 'previewUrl' | 'createdTime' | 'updatedTime' | 'pageCount'>
    & { domains: Array<(
      { __typename?: 'Domain' }
      & DefaultDomainsFieldsFragment
    )>, currentDeployment?: Maybe<(
      { __typename?: 'Deployment' }
      & Pick<Deployment, 'id' | 'buildHost' | 'deploymentTime'>
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'profileImageUrl' | 'displayName' | 'id' | 'email'>
    )> }
  )> }
);

export type ChannelDocActivityFieldsFragment = (
  { __typename?: 'DocActivity' }
  & { editors?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
  )>>, presenters?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
  )>>, doc: (
    { __typename?: 'Doc' }
    & HomeDocFieldsFragment
  ) }
);

export type GetPreviewChannelDocsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  channelId: Scalars['ID'];
}>;


export type GetPreviewChannelDocsQuery = (
  { __typename?: 'Query' }
  & { previewChannelDocs: (
    { __typename?: 'DocConnection' }
    & { edges: Array<(
      { __typename?: 'DocEdge' }
      & { node: (
        { __typename?: 'Doc' }
        & HomeDocFieldsFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetChannelDocActivityQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  channelId: Scalars['ID'];
}>;


export type GetChannelDocActivityQuery = (
  { __typename?: 'Query' }
  & { channelDocActivity: (
    { __typename?: 'DocActivityConnection' }
    & { edges: Array<(
      { __typename?: 'DocActivityEdge' }
      & { node: (
        { __typename?: 'DocActivity' }
        & ChannelDocActivityFieldsFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetDocsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
  createdBy?: Maybe<Scalars['ID']>;
  archived?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<DocSortOrder>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  channelId?: Maybe<Scalars['ID']>;
}>;


export type GetDocsQuery = (
  { __typename?: 'Query' }
  & { docs: (
    { __typename?: 'DocConnection' }
    & { edges: Array<(
      { __typename?: 'DocEdge' }
      & { node: (
        { __typename?: 'Doc' }
        & HomeDocFieldsFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetSharedDocsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  sortBy?: Maybe<DocSortOrder>;
}>;


export type GetSharedDocsQuery = (
  { __typename?: 'Query' }
  & { sharedDocs: (
    { __typename?: 'DocConnection' }
    & { edges: Array<(
      { __typename?: 'DocEdge' }
      & { node: (
        { __typename?: 'Doc' }
        & HomeDocFieldsFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type GetSnapshotsQueryVariables = Exact<{
  docId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetSnapshotsQuery = (
  { __typename?: 'Query' }
  & { snapshots: (
    { __typename?: 'SnapshotConnection' }
    & { edges: Array<(
      { __typename?: 'SnapshotEdge' }
      & { node: (
        { __typename?: 'Snapshot' }
        & Pick<Snapshot, 'id' | 'docId' | 'createdTime' | 'schemaVersion'>
        & { editors: Array<(
          { __typename?: 'User' }
          & Pick<User, 'displayName' | 'id'>
        )> }
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type DuplicateDocMutationVariables = Exact<{
  duplicateDocInput: DuplicateDocInput;
}>;


export type DuplicateDocMutation = (
  { __typename?: 'Mutation' }
  & { duplicateDoc: (
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'title'>
  ) }
);

export type GetSnapshotQueryVariables = Exact<{
  snapshotId: Scalars['ID'];
  docId: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
}>;


export type GetSnapshotQuery = (
  { __typename?: 'Query' }
  & { snapshot?: Maybe<(
    { __typename?: 'Snapshot' }
    & Pick<Snapshot, 'id' | 'content' | 'schemaVersion'>
  )> }
);

export type GetDocForSnapshotViewerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetDocForSnapshotViewerQuery = (
  { __typename?: 'Query' }
  & { doc?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id' | 'currentSnapshotId' | 'createdTime' | 'title'>
    & { createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'profileImageUrl' | 'displayName' | 'id'>
    )> }
  )> }
);

export type ChannelActivitySubscriptionVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type ChannelActivitySubscription = (
  { __typename?: 'Subscription' }
  & { channelDocActivity?: Maybe<Array<(
    { __typename?: 'DocActivity' }
    & { editors?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
    )>>, presenters?: Maybe<Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'displayName' | 'profileImageUrl'>
    )>>, doc: (
      { __typename?: 'Doc' }
      & HomeDocFieldsFragment
    ) }
  )>> }
);

export type ChannelFieldsFragmentFragment = (
  { __typename?: 'Channel' }
  & Pick<Channel, 'id' | 'name' | 'slug' | 'memberCount' | 'isMember' | 'workspaceId' | 'createdTime' | 'updatedTime' | 'archived'>
  & { members?: Maybe<Array<(
    { __typename?: 'ChannelMember' }
    & Pick<ChannelMember, 'profileImageUrl' | 'displayName' | 'id'>
  )>>, activity?: Maybe<(
    { __typename?: 'ChannelActivity' }
    & { activeUsers?: Maybe<Array<(
      { __typename?: 'ChannelMember' }
      & Pick<ChannelMember, 'id' | 'displayName' | 'profileImageUrl' | 'email'>
    )>> }
  )> }
);

export type DocChannelUpdateFragment = (
  { __typename?: 'Doc' }
  & Pick<Doc, 'id'>
  & { channels?: Maybe<Array<(
    { __typename?: 'DocChannel' }
    & Pick<DocChannel, 'id' | 'name' | 'slug' | 'permission'>
  )>> }
);

export type GetChannelsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type GetChannelsQuery = (
  { __typename?: 'Query' }
  & { channels?: Maybe<Array<(
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  )>> }
);

export type GetChannelQueryVariables = Exact<{
  id: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type GetChannelQuery = (
  { __typename?: 'Query' }
  & { channel?: Maybe<(
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  )> }
);

export type GetWorkspaceChannelsQueryVariables = Exact<{
  workspaceId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  sortBy?: Maybe<ChannelSortOrder>;
}>;


export type GetWorkspaceChannelsQuery = (
  { __typename?: 'Query' }
  & { workspaceChannels: (
    { __typename?: 'ChannelConnection' }
    & { edges: Array<(
      { __typename?: 'ChannelEdge' }
      & { node: (
        { __typename?: 'Channel' }
        & ChannelFieldsFragmentFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  ) }
);

export type CreateChannelMutationVariables = Exact<{
  input: CreateChannelInput;
}>;


export type CreateChannelMutation = (
  { __typename?: 'Mutation' }
  & { createChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type JoinChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type JoinChannelMutation = (
  { __typename?: 'Mutation' }
  & { joinChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type LeaveChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type LeaveChannelMutation = (
  { __typename?: 'Mutation' }
  & { leaveChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type ArchiveChannelMutationVariables = Exact<{
  channelId: Scalars['ID'];
  workspaceId: Scalars['ID'];
}>;


export type ArchiveChannelMutation = (
  { __typename?: 'Mutation' }
  & { archiveChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type UpdateChannelMutationVariables = Exact<{
  input?: Maybe<UpdateChannelInput>;
}>;


export type UpdateChannelMutation = (
  { __typename?: 'Mutation' }
  & { updateChannel: (
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) }
);

export type SearchChannelsQueryVariables = Exact<{
  workspaceId?: Maybe<Scalars['ID']>;
  query: Scalars['String'];
}>;


export type SearchChannelsQuery = (
  { __typename?: 'Query' }
  & { search: Array<(
    { __typename: 'Channel' }
    & ChannelFieldsFragmentFragment
  ) | { __typename: 'DocResult' } | { __typename: 'User' }> }
);

export type ChannelsSubscriptionVariables = Exact<{
  workspaceId: Scalars['ID'];
}>;


export type ChannelsSubscription = (
  { __typename?: 'Subscription' }
  & { channels?: Maybe<Array<(
    { __typename?: 'Channel' }
    & ChannelFieldsFragmentFragment
  )>> }
);

export type GetCardPreviewUrlsForDocQueryVariables = Exact<{
  docId: Scalars['ID'];
  maxDepth?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
}>;


export type GetCardPreviewUrlsForDocQuery = (
  { __typename?: 'Query' }
  & { docCards: Array<(
    { __typename?: 'Card' }
    & Pick<Card, 'docId' | 'previewUrl' | 'position' | 'depth' | 'id' | 'archived'>
  )> }
);

export type SendCodeMutationVariables = Exact<{
  input: SendCodeInput;
}>;


export type SendCodeMutation = (
  { __typename?: 'Mutation' }
  & { sendCode: (
    { __typename?: 'SendCodeResponse' }
    & Pick<SendCodeResponse, 'emailSent' | 'provider' | 'link'>
  ) }
);

export type VerifyCodeQueryVariables = Exact<{
  input: VerifyCodeInput;
}>;


export type VerifyCodeQuery = (
  { __typename?: 'Query' }
  & { verifyCode: (
    { __typename?: 'VerifyCodeResponse' }
    & Pick<VerifyCodeResponse, 'verified' | 'isNew'>
  ) }
);

export type SigninMutationVariables = Exact<{
  input: SigninInput;
}>;


export type SigninMutation = (
  { __typename?: 'Mutation' }
  & { signin: (
    { __typename?: 'SigninResponse' }
    & Pick<SigninResponse, 'success'>
  ) }
);

export type SignupMutationVariables = Exact<{
  input: SignupInput;
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { organizations?: Maybe<Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id'>
    )>>, settings?: Maybe<(
      { __typename?: 'UserSettings' }
      & Pick<UserSettings, 'showWorkspaceSetup' | 'showGetStarted' | 'welcomeDocId'>
    )> }
  ) }
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword: (
    { __typename?: 'SigninResponse' }
    & Pick<SigninResponse, 'success'>
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'SigninResponse' }
    & Pick<SigninResponse, 'success'>
  ) }
);

export type GetDocInvitationQueryVariables = Exact<{
  docId: Scalars['ID'];
  email: Scalars['String'];
  inviteCode: Scalars['String'];
}>;


export type GetDocInvitationQuery = (
  { __typename?: 'Query' }
  & { docInvitation?: Maybe<(
    { __typename?: 'DocInvitation' }
    & Pick<DocInvitation, 'docId' | 'docTitle' | 'email' | 'permission'>
    & { invitedBy: (
      { __typename?: 'User' }
      & Pick<User, 'displayName'>
    ) }
  )> }
);

export type AcceptDocInvitationMutationVariables = Exact<{
  docId: Scalars['ID'];
  email: Scalars['String'];
  inviteCode: Scalars['String'];
}>;


export type AcceptDocInvitationMutation = (
  { __typename?: 'Mutation' }
  & { acceptDocInvitation?: Maybe<(
    { __typename?: 'Doc' }
    & Pick<Doc, 'id'>
  )> }
);

export type GetWorkspaceInvitationQueryVariables = Exact<{
  email: Scalars['String'];
  inviteCode: Scalars['String'];
  workspaceId?: Maybe<Scalars['String']>;
}>;


export type GetWorkspaceInvitationQuery = (
  { __typename?: 'Query' }
  & { workspaceInvitation?: Maybe<(
    { __typename?: 'WorkspaceInvitation' }
    & Pick<WorkspaceInvitation, 'email' | 'workspaceId' | 'workspaceName'>
    & { invitedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'displayName'>
    )> }
  )> }
);

export type JoinWorkspaceMutationVariables = Exact<{
  input: Invite;
}>;


export type JoinWorkspaceMutation = (
  { __typename?: 'Mutation' }
  & { joinWorkspace: (
    { __typename?: 'SigninResponse' }
    & Pick<SigninResponse, 'success'>
  ) }
);

export const DocGenerateInputFragmentFragmentDoc = gql`
    fragment DocGenerateInputFragment on DocGenerateInput {
  id
  docGenerationId
  interactionId
  status
  prompt
  content
  format
  settings
  docId
  docGenerateOutputId
  createdTime
  updatedTime
}
    `;
export const DocGenerationFragmentFragmentDoc = gql`
    fragment DocGenerationFragment on DocGeneration {
  id
  generationType
  userId
  workspaceId
  draftInput {
    ...DocGenerateInputFragment
  }
  docGenerateInputs {
    ...DocGenerateInputFragment
  }
  createdTime
  updatedTime
}
    ${DocGenerateInputFragmentFragmentDoc}`;
export const FontFragmentDoc = gql`
    fragment Font on Font {
  id
  name
  cssUrl
  fontFiles {
    id
    name
    weight
    isItalic
    fileType
    sourceUrl
  }
  workspaceId
  archived
  fontFileCount
}
    `;
export const SavedMediaFragmentFragmentDoc = gql`
    fragment SavedMediaFragment on SavedMedia {
  id
  userId
  workspaceId
  interactionId
  docId
  themeId
  context
  type
  source
  attrs
  createdTime
  updatedTime
}
    `;
export const DocFieldsFragmentFragmentDoc = gql`
    fragment DocFieldsFragment on Doc {
  id
  title
  archived
  createdTime
  updatedTime
  editedTime
  currentSnapshotId
  titleCard {
    id
    previewUrl
    createdTime
    updatedTime
  }
  createdBy {
    profileImageUrl
    displayName
    id
  }
  collaborators {
    docId
    user {
      id
    }
    permission
  }
  docUser {
    id
    docId
    lastViewed
    lastEdited
    favorited
  }
  channels {
    id
    name
    slug
    permission
  }
  organization {
    id
    name
  }
  orgAccess
  editors {
    docId
    user {
      id
      displayName
      email
      profileImageUrl
    }
    lastEdited
  }
}
    `;
export const SearchChannelResultFragmentDoc = gql`
    fragment SearchChannelResult on Channel {
  id
  name
  slug
  members {
    profileImageUrl
    displayName
    id
  }
  memberCount
  isMember
  workspaceId
  createdTime
  updatedTime
}
    `;
export const SearchDocResultFragmentDoc = gql`
    fragment SearchDocResult on DocResult {
  id
  title
  text
  archived
  previewUrl
  createdBy {
    id
    displayName
    profileImageUrl
  }
  organization {
    id
  }
  editors {
    user {
      id
      displayName
      profileImageUrl
    }
    lastViewed
    lastEdited
  }
  docUser {
    lastViewed
    lastEdited
    favorited
  }
  orgAccess
  updatedTime
  createdTime
  editedTime
}
    `;
export const DocCollaboratorsUpdateFragmentDoc = gql`
    fragment DocCollaboratorsUpdate on Doc {
  collaborators {
    docId
    user {
      id
      displayName
      email
      profileImageUrl
    }
    permission
    guest
  }
}
    `;
export const ShareSearchUserFragmentDoc = gql`
    fragment ShareSearchUser on User {
  id
  displayName
  email
  profileImageUrl
}
    `;
export const DocChannelsUpdateFragmentDoc = gql`
    fragment DocChannelsUpdate on Doc {
  channels {
    id
    name
    slug
  }
}
    `;
export const ShareSearchChannelFragmentDoc = gql`
    fragment ShareSearchChannel on Channel {
  id
  name
  slug
  isMember
  memberCount
  members {
    id
    displayName
    email
    profileImageUrl
  }
}
    `;
export const DefaultDomainsFieldsFragmentDoc = gql`
    fragment DefaultDomainsFields on Domain {
  id
  name
  status
  builtin
  createdTime
  siteId
}
    `;
export const DefaultThemeFontFieldsFragmentDoc = gql`
    fragment DefaultThemeFontFields on ThemeFont {
  id
  name
  url
}
    `;
export const DefaultThemeFieldsFragmentDoc = gql`
    fragment DefaultThemeFields on Theme {
  id
  workspaceId
  name
  headingFont
  headingFontWeight
  bodyFont
  bodyFontWeight
  accentColor
  logoUrl
  config
  priority
  previewUrl
  archived
  createdTime
  updatedTime
  fonts {
    ... on ThemeFont {
      ...DefaultThemeFontFields
    }
  }
}
    ${DefaultThemeFontFieldsFragmentDoc}`;
export const SiteFragmentFragmentDoc = gql`
    fragment SiteFragment on Site {
  id
  workspaceId
  name
  domains {
    ...DefaultDomainsFields
  }
  hasCustomDomain
  routes {
    id
    siteId
    docId
    path
    offline
    doc {
      id
      title
      orgAccess
      publishedTime
      publishedSnapshotId
      currentSnapshotId
      editedTime
      archived
      createdTime
    }
  }
  currentDeployment {
    id
    buildHost
    deploymentTime
    siteSettings
  }
  createdTime
  updatedTime
  previewUrl
  pageCount
  archived
  enabled
  faviconUrl
  themeId
  createdBy {
    email
    profileImageUrl
    displayName
    id
  }
  theme {
    ...DefaultThemeFields
  }
  navEnabled
  navContent
  gtmContainerId
}
    ${DefaultDomainsFieldsFragmentDoc}
${DefaultThemeFieldsFragmentDoc}`;
export const DefaultCardFieldsFragmentDoc = gql`
    fragment DefaultCardFields on Card {
  id
  archived
  docId
  title
  text
  previewUrl
  createdTime
  updatedTime
}
    `;
export const CommentAuthorFieldsFragmentDoc = gql`
    fragment CommentAuthorFields on User {
  id
  displayName
  profileImageUrl
  email
}
    `;
export const ReactionFieldsFragmentDoc = gql`
    fragment ReactionFields on Reaction {
  emoji
  count
  targetId
  targetType
  docId
  users {
    id
    displayName
    profileImageUrl
  }
}
    `;
export const ReplyFieldsFragmentDoc = gql`
    fragment ReplyFields on Comment {
  id
  commentId
  content
  createdTime
  updatedTime
  archived
  user {
    ...CommentAuthorFields
  }
  reactions {
    ...ReactionFields
  }
}
    ${CommentAuthorFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}`;
export const CommentFieldsFragmentDoc = gql`
    fragment CommentFields on Comment {
  id
  docId
  cardId
  content
  targetText
  targetHtml
  createdTime
  updatedTime
  archived
  targetId
  status
  user {
    ...CommentAuthorFields
  }
  replies {
    ...ReplyFields
  }
  reactions {
    ...ReactionFields
  }
}
    ${CommentAuthorFieldsFragmentDoc}
${ReplyFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}`;
export const DocCollaboratorFieldsFragmentDoc = gql`
    fragment DocCollaboratorFields on DocCollaborator {
  docId
  user {
    id
    displayName
    email
    profileImageUrl
  }
  permission
  guest
  accessLinkId
}
    `;
export const DocInvitationsFieldsFragmentDoc = gql`
    fragment DocInvitationsFields on DocInvitation {
  id
  docId
  email
  permission
}
    `;
export const DocChannelFieldsFragmentDoc = gql`
    fragment DocChannelFields on DocChannel {
  id
  name
  slug
  permission
}
    `;
export const DocEditorsFieldsFragmentDoc = gql`
    fragment DocEditorsFields on DocEditor {
  user {
    id
    displayName
    email
    profileImageUrl
  }
  lastEdited
}
    `;
export const DocSiteFieldsFragmentDoc = gql`
    fragment DocSiteFields on Site {
  id
  archived
  workspaceId
  name
  domains {
    id
    siteId
    name
    status
    builtin
  }
  hasCustomDomain
  routes {
    id
    siteId
    docId
    path
    offline
    doc {
      id
      title
    }
  }
  currentDeployment {
    id
    siteId
    buildHost
    deploymentTime
  }
  createdTime
  updatedTime
  previewUrl
  pageCount
  archived
  enabled
  faviconUrl
  themeId
  navEnabled
  navContent
  gtmContainerId
}
    `;
export const DocReactionsCreateFragmentDoc = gql`
    fragment DocReactionsCreate on Doc {
  reactions {
    ...ReactionFields
  }
}
    ${ReactionFieldsFragmentDoc}`;
export const DocCommentsCreateFragmentDoc = gql`
    fragment DocCommentsCreate on Doc {
  comments {
    id
    docId
    cardId
    content
    targetText
    targetHtml
    createdTime
    updatedTime
    archived
    targetId
    status
    user {
      ...CommentAuthorFields
    }
  }
}
    ${CommentAuthorFieldsFragmentDoc}`;
export const DocRepliesCreateFragmentDoc = gql`
    fragment DocRepliesCreate on Doc {
  comments {
    id
    replies {
      ...ReplyFields
    }
  }
}
    ${ReplyFieldsFragmentDoc}`;
export const DocCommentsUpdateFragmentDoc = gql`
    fragment DocCommentsUpdate on Doc {
  comments {
    id
    docId
    content
    updatedTime
    reactions {
      ...ReactionFields
    }
  }
}
    ${ReactionFieldsFragmentDoc}`;
export const DocRepliesUpdateFragmentDoc = gql`
    fragment DocRepliesUpdate on Doc {
  comments {
    id
    replies {
      id
      docId
      content
      updatedTime
      reactions {
        ...ReactionFields
      }
    }
  }
}
    ${ReactionFieldsFragmentDoc}`;
export const DocCommentsRemoveFragmentDoc = gql`
    fragment DocCommentsRemove on Doc {
  comments {
    id
    archived
  }
}
    `;
export const DocRepliesRemoveFragmentDoc = gql`
    fragment DocRepliesRemove on Doc {
  comments {
    id
    replies {
      id
      archived
    }
  }
}
    `;
export const DocUserUpdateFragmentDoc = gql`
    fragment DocUserUpdate on Doc {
  docUser {
    id
    docId
    lastViewed
    lastEdited
    favorited
  }
}
    `;
export const HomeDocFieldsFragmentDoc = gql`
    fragment HomeDocFields on Doc {
  id
  title
  archived
  createdTime
  updatedTime
  editedTime
  currentSnapshotId
  titleCard {
    id
    previewUrl
    createdTime
    updatedTime
  }
  createdBy {
    profileImageUrl
    displayName
    id
    email
  }
  collaborators {
    docId
    user {
      id
    }
    permission
  }
  docUser {
    id
    docId
    lastViewed
    lastEdited
    favorited
  }
  channels {
    id
    name
    slug
    permission
  }
  organization {
    id
    name
  }
  orgAccess
  editors {
    docId
    user {
      id
      displayName
      email
      profileImageUrl
    }
    lastEdited
  }
  accessLinks {
    id
    docId
    permission
    token
    url
  }
  site {
    id
    name
    archived
    enabled
    faviconUrl
    previewUrl
    domains {
      ...DefaultDomainsFields
    }
    currentDeployment {
      id
      buildHost
      deploymentTime
    }
    createdTime
    updatedTime
    previewUrl
    pageCount
    createdBy {
      profileImageUrl
      displayName
      id
      email
    }
  }
}
    ${DefaultDomainsFieldsFragmentDoc}`;
export const ChannelDocActivityFieldsFragmentDoc = gql`
    fragment ChannelDocActivityFields on DocActivity {
  editors {
    id
    displayName
    profileImageUrl
  }
  presenters {
    id
    displayName
    profileImageUrl
  }
  doc {
    ...HomeDocFields
  }
}
    ${HomeDocFieldsFragmentDoc}`;
export const ChannelFieldsFragmentFragmentDoc = gql`
    fragment ChannelFieldsFragment on Channel {
  id
  name
  slug
  members {
    profileImageUrl
    displayName
    id
  }
  memberCount
  isMember
  workspaceId
  createdTime
  updatedTime
  archived
  activity {
    activeUsers {
      id
      displayName
      profileImageUrl
      email
    }
  }
}
    `;
export const DocChannelUpdateFragmentDoc = gql`
    fragment DocChannelUpdate on Doc {
  id
  channels {
    id
    name
    slug
    permission
  }
}
    `;
export const ReportAbuseDocument = gql`
    mutation ReportAbuse($input: AbuseReportInput!) {
  reportAbuse(input: $input)
}
    `;
export type ReportAbuseMutationFn = Apollo.MutationFunction<ReportAbuseMutation, ReportAbuseMutationVariables>;

/**
 * __useReportAbuseMutation__
 *
 * To run a mutation, you first call `useReportAbuseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportAbuseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportAbuseMutation, { data, loading, error }] = useReportAbuseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportAbuseMutation(baseOptions?: Apollo.MutationHookOptions<ReportAbuseMutation, ReportAbuseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportAbuseMutation, ReportAbuseMutationVariables>(ReportAbuseDocument, options);
      }
export type ReportAbuseMutationHookResult = ReturnType<typeof useReportAbuseMutation>;
export type ReportAbuseMutationResult = Apollo.MutationResult<ReportAbuseMutation>;
export type ReportAbuseMutationOptions = Apollo.BaseMutationOptions<ReportAbuseMutation, ReportAbuseMutationVariables>;
export const AssessRiskDocument = gql`
    query AssessRisk($token: String!, $action: RecaptchaAction!) {
  assessRisk(token: $token, action: $action) {
    risk
  }
}
    `;

/**
 * __useAssessRiskQuery__
 *
 * To run a query within a React component, call `useAssessRiskQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessRiskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessRiskQuery({
 *   variables: {
 *      token: // value for 'token'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useAssessRiskQuery(baseOptions: Apollo.QueryHookOptions<AssessRiskQuery, AssessRiskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssessRiskQuery, AssessRiskQueryVariables>(AssessRiskDocument, options);
      }
export function useAssessRiskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssessRiskQuery, AssessRiskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssessRiskQuery, AssessRiskQueryVariables>(AssessRiskDocument, options);
        }
export type AssessRiskQueryHookResult = ReturnType<typeof useAssessRiskQuery>;
export type AssessRiskLazyQueryHookResult = ReturnType<typeof useAssessRiskLazyQuery>;
export type AssessRiskQueryResult = Apollo.QueryResult<AssessRiskQuery, AssessRiskQueryVariables>;
export const ChatCompletionDocument = gql`
    mutation ChatCompletion($input: ChatCompletionInput!) {
  chatCompletion(input: $input) {
    role
    content
  }
}
    `;
export type ChatCompletionMutationFn = Apollo.MutationFunction<ChatCompletionMutation, ChatCompletionMutationVariables>;

/**
 * __useChatCompletionMutation__
 *
 * To run a mutation, you first call `useChatCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChatCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chatCompletionMutation, { data, loading, error }] = useChatCompletionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChatCompletionMutation(baseOptions?: Apollo.MutationHookOptions<ChatCompletionMutation, ChatCompletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChatCompletionMutation, ChatCompletionMutationVariables>(ChatCompletionDocument, options);
      }
export type ChatCompletionMutationHookResult = ReturnType<typeof useChatCompletionMutation>;
export type ChatCompletionMutationResult = Apollo.MutationResult<ChatCompletionMutation>;
export type ChatCompletionMutationOptions = Apollo.BaseMutationOptions<ChatCompletionMutation, ChatCompletionMutationVariables>;
export const CanRunChatCompletionDocument = gql`
    query CanRunChatCompletion($input: CanRunChatCompletionRequest!) {
  canRunChatCompletion(input: $input) {
    model
    canRun
  }
}
    `;

/**
 * __useCanRunChatCompletionQuery__
 *
 * To run a query within a React component, call `useCanRunChatCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanRunChatCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanRunChatCompletionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCanRunChatCompletionQuery(baseOptions: Apollo.QueryHookOptions<CanRunChatCompletionQuery, CanRunChatCompletionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CanRunChatCompletionQuery, CanRunChatCompletionQueryVariables>(CanRunChatCompletionDocument, options);
      }
export function useCanRunChatCompletionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CanRunChatCompletionQuery, CanRunChatCompletionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CanRunChatCompletionQuery, CanRunChatCompletionQueryVariables>(CanRunChatCompletionDocument, options);
        }
export type CanRunChatCompletionQueryHookResult = ReturnType<typeof useCanRunChatCompletionQuery>;
export type CanRunChatCompletionLazyQueryHookResult = ReturnType<typeof useCanRunChatCompletionLazyQuery>;
export type CanRunChatCompletionQueryResult = Apollo.QueryResult<CanRunChatCompletionQuery, CanRunChatCompletionQueryVariables>;
export const CreateDocGenerationDocument = gql`
    mutation CreateDocGeneration($input: CreateDocGenerationInput!) {
  createDocGeneration(input: $input) {
    ...DocGenerationFragment
  }
}
    ${DocGenerationFragmentFragmentDoc}`;
export type CreateDocGenerationMutationFn = Apollo.MutationFunction<CreateDocGenerationMutation, CreateDocGenerationMutationVariables>;

/**
 * __useCreateDocGenerationMutation__
 *
 * To run a mutation, you first call `useCreateDocGenerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocGenerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocGenerationMutation, { data, loading, error }] = useCreateDocGenerationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocGenerationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocGenerationMutation, CreateDocGenerationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocGenerationMutation, CreateDocGenerationMutationVariables>(CreateDocGenerationDocument, options);
      }
export type CreateDocGenerationMutationHookResult = ReturnType<typeof useCreateDocGenerationMutation>;
export type CreateDocGenerationMutationResult = Apollo.MutationResult<CreateDocGenerationMutation>;
export type CreateDocGenerationMutationOptions = Apollo.BaseMutationOptions<CreateDocGenerationMutation, CreateDocGenerationMutationVariables>;
export const UpdateDocGenerationDraftInputDocument = gql`
    mutation UpdateDocGenerationDraftInput($docGenerateInputId: ID!, $input: UpdateDocGenerateInput!) {
  updateDocGenerationDraftInput(
    docGenerateInputId: $docGenerateInputId
    input: $input
  ) {
    ...DocGenerationFragment
  }
}
    ${DocGenerationFragmentFragmentDoc}`;
export type UpdateDocGenerationDraftInputMutationFn = Apollo.MutationFunction<UpdateDocGenerationDraftInputMutation, UpdateDocGenerationDraftInputMutationVariables>;

/**
 * __useUpdateDocGenerationDraftInputMutation__
 *
 * To run a mutation, you first call `useUpdateDocGenerationDraftInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocGenerationDraftInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocGenerationDraftInputMutation, { data, loading, error }] = useUpdateDocGenerationDraftInputMutation({
 *   variables: {
 *      docGenerateInputId: // value for 'docGenerateInputId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocGenerationDraftInputMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocGenerationDraftInputMutation, UpdateDocGenerationDraftInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocGenerationDraftInputMutation, UpdateDocGenerationDraftInputMutationVariables>(UpdateDocGenerationDraftInputDocument, options);
      }
export type UpdateDocGenerationDraftInputMutationHookResult = ReturnType<typeof useUpdateDocGenerationDraftInputMutation>;
export type UpdateDocGenerationDraftInputMutationResult = Apollo.MutationResult<UpdateDocGenerationDraftInputMutation>;
export type UpdateDocGenerationDraftInputMutationOptions = Apollo.BaseMutationOptions<UpdateDocGenerationDraftInputMutation, UpdateDocGenerationDraftInputMutationVariables>;
export const GenerateDocDocument = gql`
    mutation GenerateDoc($docGenerateInputId: ID!, $options: GenerateDocOptions!) {
  generateDoc(docGenerateInputId: $docGenerateInputId, options: $options) {
    id
    docGenerateInput {
      ...DocGenerateInputFragment
    }
  }
}
    ${DocGenerateInputFragmentFragmentDoc}`;
export type GenerateDocMutationFn = Apollo.MutationFunction<GenerateDocMutation, GenerateDocMutationVariables>;

/**
 * __useGenerateDocMutation__
 *
 * To run a mutation, you first call `useGenerateDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDocMutation, { data, loading, error }] = useGenerateDocMutation({
 *   variables: {
 *      docGenerateInputId: // value for 'docGenerateInputId'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGenerateDocMutation(baseOptions?: Apollo.MutationHookOptions<GenerateDocMutation, GenerateDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateDocMutation, GenerateDocMutationVariables>(GenerateDocDocument, options);
      }
export type GenerateDocMutationHookResult = ReturnType<typeof useGenerateDocMutation>;
export type GenerateDocMutationResult = Apollo.MutationResult<GenerateDocMutation>;
export type GenerateDocMutationOptions = Apollo.BaseMutationOptions<GenerateDocMutation, GenerateDocMutationVariables>;
export const GenerateDocFromImportDocument = gql`
    mutation GenerateDocFromImport($docGenerateInputId: ID!, $options: GenerateDocFromImportOptions!) {
  generateDocFromImport(
    docGenerateInputId: $docGenerateInputId
    options: $options
  ) {
    id
    docGenerateInput {
      ...DocGenerateInputFragment
    }
  }
}
    ${DocGenerateInputFragmentFragmentDoc}`;
export type GenerateDocFromImportMutationFn = Apollo.MutationFunction<GenerateDocFromImportMutation, GenerateDocFromImportMutationVariables>;

/**
 * __useGenerateDocFromImportMutation__
 *
 * To run a mutation, you first call `useGenerateDocFromImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateDocFromImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateDocFromImportMutation, { data, loading, error }] = useGenerateDocFromImportMutation({
 *   variables: {
 *      docGenerateInputId: // value for 'docGenerateInputId'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGenerateDocFromImportMutation(baseOptions?: Apollo.MutationHookOptions<GenerateDocFromImportMutation, GenerateDocFromImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateDocFromImportMutation, GenerateDocFromImportMutationVariables>(GenerateDocFromImportDocument, options);
      }
export type GenerateDocFromImportMutationHookResult = ReturnType<typeof useGenerateDocFromImportMutation>;
export type GenerateDocFromImportMutationResult = Apollo.MutationResult<GenerateDocFromImportMutation>;
export type GenerateDocFromImportMutationOptions = Apollo.BaseMutationOptions<GenerateDocFromImportMutation, GenerateDocFromImportMutationVariables>;
export const GetDocGenerationDocument = gql`
    query GetDocGeneration($id: ID!) {
  docGeneration(id: $id) {
    ...DocGenerationFragment
  }
}
    ${DocGenerationFragmentFragmentDoc}`;

/**
 * __useGetDocGenerationQuery__
 *
 * To run a query within a React component, call `useGetDocGenerationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocGenerationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocGenerationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocGenerationQuery(baseOptions: Apollo.QueryHookOptions<GetDocGenerationQuery, GetDocGenerationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocGenerationQuery, GetDocGenerationQueryVariables>(GetDocGenerationDocument, options);
      }
export function useGetDocGenerationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocGenerationQuery, GetDocGenerationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocGenerationQuery, GetDocGenerationQueryVariables>(GetDocGenerationDocument, options);
        }
export type GetDocGenerationQueryHookResult = ReturnType<typeof useGetDocGenerationQuery>;
export type GetDocGenerationLazyQueryHookResult = ReturnType<typeof useGetDocGenerationLazyQuery>;
export type GetDocGenerationQueryResult = Apollo.QueryResult<GetDocGenerationQuery, GetDocGenerationQueryVariables>;
export const GetDocGenerationsDocument = gql`
    query GetDocGenerations($workspaceId: ID!, $first: Int, $after: String, $last: Int, $before: String, $generationTypes: [GenerationType!]) {
  docGenerations(
    workspaceId: $workspaceId
    first: $first
    after: $after
    last: $last
    before: $before
    generationTypes: $generationTypes
  ) {
    edges {
      cursor
      node {
        ...DocGenerationFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${DocGenerationFragmentFragmentDoc}`;

/**
 * __useGetDocGenerationsQuery__
 *
 * To run a query within a React component, call `useGetDocGenerationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocGenerationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocGenerationsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      generationTypes: // value for 'generationTypes'
 *   },
 * });
 */
export function useGetDocGenerationsQuery(baseOptions: Apollo.QueryHookOptions<GetDocGenerationsQuery, GetDocGenerationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocGenerationsQuery, GetDocGenerationsQueryVariables>(GetDocGenerationsDocument, options);
      }
export function useGetDocGenerationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocGenerationsQuery, GetDocGenerationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocGenerationsQuery, GetDocGenerationsQueryVariables>(GetDocGenerationsDocument, options);
        }
export type GetDocGenerationsQueryHookResult = ReturnType<typeof useGetDocGenerationsQuery>;
export type GetDocGenerationsLazyQueryHookResult = ReturnType<typeof useGetDocGenerationsLazyQuery>;
export type GetDocGenerationsQueryResult = Apollo.QueryResult<GetDocGenerationsQuery, GetDocGenerationsQueryVariables>;
export const GenerateImportPptThemeDocument = gql`
    mutation GenerateImportPptTheme($input: GeneratePptThemeInput!) {
  generateImportPptTheme(input: $input) {
    thumbnails {
      url
      width
      height
      index
    }
    theme {
      headingFont {
        suggested
        original
      }
      headingFontWeight
      bodyFont {
        suggested
        original
      }
      bodyFontWeight
      headingColors
      bodyColors
      accentColors
      cardColors
      backgroundColors
      logos {
        url
        width
        height
      }
    }
  }
}
    `;
export type GenerateImportPptThemeMutationFn = Apollo.MutationFunction<GenerateImportPptThemeMutation, GenerateImportPptThemeMutationVariables>;

/**
 * __useGenerateImportPptThemeMutation__
 *
 * To run a mutation, you first call `useGenerateImportPptThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateImportPptThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateImportPptThemeMutation, { data, loading, error }] = useGenerateImportPptThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateImportPptThemeMutation(baseOptions?: Apollo.MutationHookOptions<GenerateImportPptThemeMutation, GenerateImportPptThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateImportPptThemeMutation, GenerateImportPptThemeMutationVariables>(GenerateImportPptThemeDocument, options);
      }
export type GenerateImportPptThemeMutationHookResult = ReturnType<typeof useGenerateImportPptThemeMutation>;
export type GenerateImportPptThemeMutationResult = Apollo.MutationResult<GenerateImportPptThemeMutation>;
export type GenerateImportPptThemeMutationOptions = Apollo.BaseMutationOptions<GenerateImportPptThemeMutation, GenerateImportPptThemeMutationVariables>;
export const GetPptHtmlDocument = gql`
    mutation GetPptHtml($input: GetPptHtmlInput!) {
  getPptHtml(input: $input) {
    slideHtml
    htmlUrl
  }
}
    `;
export type GetPptHtmlMutationFn = Apollo.MutationFunction<GetPptHtmlMutation, GetPptHtmlMutationVariables>;

/**
 * __useGetPptHtmlMutation__
 *
 * To run a mutation, you first call `useGetPptHtmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPptHtmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPptHtmlMutation, { data, loading, error }] = useGetPptHtmlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPptHtmlMutation(baseOptions?: Apollo.MutationHookOptions<GetPptHtmlMutation, GetPptHtmlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetPptHtmlMutation, GetPptHtmlMutationVariables>(GetPptHtmlDocument, options);
      }
export type GetPptHtmlMutationHookResult = ReturnType<typeof useGetPptHtmlMutation>;
export type GetPptHtmlMutationResult = Apollo.MutationResult<GetPptHtmlMutation>;
export type GetPptHtmlMutationOptions = Apollo.BaseMutationOptions<GetPptHtmlMutation, GetPptHtmlMutationVariables>;
export const GetCreditsDocument = gql`
    query getCredits($workspaceId: ID!) {
  credits(workspaceId: $workspaceId) {
    userId
    credits
  }
}
    `;

/**
 * __useGetCreditsQuery__
 *
 * To run a query within a React component, call `useGetCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetCreditsQuery(baseOptions: Apollo.QueryHookOptions<GetCreditsQuery, GetCreditsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreditsQuery, GetCreditsQueryVariables>(GetCreditsDocument, options);
      }
export function useGetCreditsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreditsQuery, GetCreditsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreditsQuery, GetCreditsQueryVariables>(GetCreditsDocument, options);
        }
export type GetCreditsQueryHookResult = ReturnType<typeof useGetCreditsQuery>;
export type GetCreditsLazyQueryHookResult = ReturnType<typeof useGetCreditsLazyQuery>;
export type GetCreditsQueryResult = Apollo.QueryResult<GetCreditsQuery, GetCreditsQueryVariables>;
export const DeductCreditsDocument = gql`
    mutation deductCredits($value: Int!, $workspaceId: ID!) {
  deductCredits(value: $value, workspaceId: $workspaceId) {
    userId
    credits
  }
}
    `;
export type DeductCreditsMutationFn = Apollo.MutationFunction<DeductCreditsMutation, DeductCreditsMutationVariables>;

/**
 * __useDeductCreditsMutation__
 *
 * To run a mutation, you first call `useDeductCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeductCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deductCreditsMutation, { data, loading, error }] = useDeductCreditsMutation({
 *   variables: {
 *      value: // value for 'value'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDeductCreditsMutation(baseOptions?: Apollo.MutationHookOptions<DeductCreditsMutation, DeductCreditsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeductCreditsMutation, DeductCreditsMutationVariables>(DeductCreditsDocument, options);
      }
export type DeductCreditsMutationHookResult = ReturnType<typeof useDeductCreditsMutation>;
export type DeductCreditsMutationResult = Apollo.MutationResult<DeductCreditsMutation>;
export type DeductCreditsMutationOptions = Apollo.BaseMutationOptions<DeductCreditsMutation, DeductCreditsMutationVariables>;
export const SubscribeToCreditsDocument = gql`
    subscription SubscribeToCredits($workspaceId: ID!) {
  credits(workspaceId: $workspaceId) {
    userId
    credits
  }
}
    `;

/**
 * __useSubscribeToCreditsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCreditsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCreditsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCreditsSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useSubscribeToCreditsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToCreditsSubscription, SubscribeToCreditsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToCreditsSubscription, SubscribeToCreditsSubscriptionVariables>(SubscribeToCreditsDocument, options);
      }
export type SubscribeToCreditsSubscriptionHookResult = ReturnType<typeof useSubscribeToCreditsSubscription>;
export type SubscribeToCreditsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCreditsSubscription>;
export const GetDocWithSnapshotDocument = gql`
    query GetDocWithSnapshot($id: ID!) {
  doc(id: $id) {
    id
    title
    theme {
      ...DefaultThemeFields
    }
    organization {
      id
      name
      products
      __typename
    }
    currentSnapshot {
      id
      docId
      content
    }
    titleCard {
      previewUrl
      text
    }
    cards {
      ...DefaultCardFields
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}
${DefaultCardFieldsFragmentDoc}`;

/**
 * __useGetDocWithSnapshotQuery__
 *
 * To run a query within a React component, call `useGetDocWithSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocWithSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocWithSnapshotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocWithSnapshotQuery(baseOptions: Apollo.QueryHookOptions<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>(GetDocWithSnapshotDocument, options);
      }
export function useGetDocWithSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>(GetDocWithSnapshotDocument, options);
        }
export type GetDocWithSnapshotQueryHookResult = ReturnType<typeof useGetDocWithSnapshotQuery>;
export type GetDocWithSnapshotLazyQueryHookResult = ReturnType<typeof useGetDocWithSnapshotLazyQuery>;
export type GetDocWithSnapshotQueryResult = Apollo.QueryResult<GetDocWithSnapshotQuery, GetDocWithSnapshotQueryVariables>;
export const GetDocPreviewUrlDocument = gql`
    query GetDocPreviewUrl($id: ID!) {
  doc(id: $id) {
    id
    titleCard {
      previewUrl
    }
  }
}
    `;

/**
 * __useGetDocPreviewUrlQuery__
 *
 * To run a query within a React component, call `useGetDocPreviewUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocPreviewUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocPreviewUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocPreviewUrlQuery(baseOptions: Apollo.QueryHookOptions<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>(GetDocPreviewUrlDocument, options);
      }
export function useGetDocPreviewUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>(GetDocPreviewUrlDocument, options);
        }
export type GetDocPreviewUrlQueryHookResult = ReturnType<typeof useGetDocPreviewUrlQuery>;
export type GetDocPreviewUrlLazyQueryHookResult = ReturnType<typeof useGetDocPreviewUrlLazyQuery>;
export type GetDocPreviewUrlQueryResult = Apollo.QueryResult<GetDocPreviewUrlQuery, GetDocPreviewUrlQueryVariables>;
export const GetWorkspaceFontsDocument = gql`
    query GetWorkspaceFonts($workspaceId: ID, $archived: Boolean, $sortBy: FontSortOrder) {
  fonts(workspaceId: $workspaceId, archived: $archived, sortBy: $sortBy) {
    ...Font
  }
}
    ${FontFragmentDoc}`;

/**
 * __useGetWorkspaceFontsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceFontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceFontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceFontsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      archived: // value for 'archived'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetWorkspaceFontsQuery(baseOptions?: Apollo.QueryHookOptions<GetWorkspaceFontsQuery, GetWorkspaceFontsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceFontsQuery, GetWorkspaceFontsQueryVariables>(GetWorkspaceFontsDocument, options);
      }
export function useGetWorkspaceFontsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceFontsQuery, GetWorkspaceFontsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceFontsQuery, GetWorkspaceFontsQueryVariables>(GetWorkspaceFontsDocument, options);
        }
export type GetWorkspaceFontsQueryHookResult = ReturnType<typeof useGetWorkspaceFontsQuery>;
export type GetWorkspaceFontsLazyQueryHookResult = ReturnType<typeof useGetWorkspaceFontsLazyQuery>;
export type GetWorkspaceFontsQueryResult = Apollo.QueryResult<GetWorkspaceFontsQuery, GetWorkspaceFontsQueryVariables>;
export const GetGlobalFontsDocument = gql`
    query GetGlobalFonts {
  fonts(workspaceId: null, archived: false, sortBy: {field: name, direction: asc}) {
    ...Font
  }
}
    ${FontFragmentDoc}`;

/**
 * __useGetGlobalFontsQuery__
 *
 * To run a query within a React component, call `useGetGlobalFontsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGlobalFontsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGlobalFontsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGlobalFontsQuery(baseOptions?: Apollo.QueryHookOptions<GetGlobalFontsQuery, GetGlobalFontsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalFontsQuery, GetGlobalFontsQueryVariables>(GetGlobalFontsDocument, options);
      }
export function useGetGlobalFontsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalFontsQuery, GetGlobalFontsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalFontsQuery, GetGlobalFontsQueryVariables>(GetGlobalFontsDocument, options);
        }
export type GetGlobalFontsQueryHookResult = ReturnType<typeof useGetGlobalFontsQuery>;
export type GetGlobalFontsLazyQueryHookResult = ReturnType<typeof useGetGlobalFontsLazyQuery>;
export type GetGlobalFontsQueryResult = Apollo.QueryResult<GetGlobalFontsQuery, GetGlobalFontsQueryVariables>;
export const GetFontDocument = gql`
    query GetFont($id: ID!) {
  font(id: $id) {
    ...Font
  }
}
    ${FontFragmentDoc}`;

/**
 * __useGetFontQuery__
 *
 * To run a query within a React component, call `useGetFontQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFontQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFontQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFontQuery(baseOptions: Apollo.QueryHookOptions<GetFontQuery, GetFontQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFontQuery, GetFontQueryVariables>(GetFontDocument, options);
      }
export function useGetFontLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFontQuery, GetFontQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFontQuery, GetFontQueryVariables>(GetFontDocument, options);
        }
export type GetFontQueryHookResult = ReturnType<typeof useGetFontQuery>;
export type GetFontLazyQueryHookResult = ReturnType<typeof useGetFontLazyQuery>;
export type GetFontQueryResult = Apollo.QueryResult<GetFontQuery, GetFontQueryVariables>;
export const CreateFontDocument = gql`
    mutation CreateFont($input: CreateFontInput) {
  createFont(input: $input) {
    ...Font
  }
}
    ${FontFragmentDoc}`;
export type CreateFontMutationFn = Apollo.MutationFunction<CreateFontMutation, CreateFontMutationVariables>;

/**
 * __useCreateFontMutation__
 *
 * To run a mutation, you first call `useCreateFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFontMutation, { data, loading, error }] = useCreateFontMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFontMutation(baseOptions?: Apollo.MutationHookOptions<CreateFontMutation, CreateFontMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFontMutation, CreateFontMutationVariables>(CreateFontDocument, options);
      }
export type CreateFontMutationHookResult = ReturnType<typeof useCreateFontMutation>;
export type CreateFontMutationResult = Apollo.MutationResult<CreateFontMutation>;
export type CreateFontMutationOptions = Apollo.BaseMutationOptions<CreateFontMutation, CreateFontMutationVariables>;
export const UpdateFontDocument = gql`
    mutation UpdateFont($input: UpdateFontInput) {
  updateFont(input: $input) {
    ...Font
  }
}
    ${FontFragmentDoc}`;
export type UpdateFontMutationFn = Apollo.MutationFunction<UpdateFontMutation, UpdateFontMutationVariables>;

/**
 * __useUpdateFontMutation__
 *
 * To run a mutation, you first call `useUpdateFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFontMutation, { data, loading, error }] = useUpdateFontMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFontMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFontMutation, UpdateFontMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFontMutation, UpdateFontMutationVariables>(UpdateFontDocument, options);
      }
export type UpdateFontMutationHookResult = ReturnType<typeof useUpdateFontMutation>;
export type UpdateFontMutationResult = Apollo.MutationResult<UpdateFontMutation>;
export type UpdateFontMutationOptions = Apollo.BaseMutationOptions<UpdateFontMutation, UpdateFontMutationVariables>;
export const ArchiveFontDocument = gql`
    mutation ArchiveFont($id: ID!, $workspaceId: ID!) {
  archiveFont(id: $id, workspaceId: $workspaceId) {
    ...Font
  }
}
    ${FontFragmentDoc}`;
export type ArchiveFontMutationFn = Apollo.MutationFunction<ArchiveFontMutation, ArchiveFontMutationVariables>;

/**
 * __useArchiveFontMutation__
 *
 * To run a mutation, you first call `useArchiveFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveFontMutation, { data, loading, error }] = useArchiveFontMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useArchiveFontMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveFontMutation, ArchiveFontMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveFontMutation, ArchiveFontMutationVariables>(ArchiveFontDocument, options);
      }
export type ArchiveFontMutationHookResult = ReturnType<typeof useArchiveFontMutation>;
export type ArchiveFontMutationResult = Apollo.MutationResult<ArchiveFontMutation>;
export type ArchiveFontMutationOptions = Apollo.BaseMutationOptions<ArchiveFontMutation, ArchiveFontMutationVariables>;
export const CreateSavedMediaDocument = gql`
    mutation CreateSavedMedia($input: CreateSavedMediaInput!) {
  createSavedMedia(input: $input) {
    ...SavedMediaFragment
  }
}
    ${SavedMediaFragmentFragmentDoc}`;
export type CreateSavedMediaMutationFn = Apollo.MutationFunction<CreateSavedMediaMutation, CreateSavedMediaMutationVariables>;

/**
 * __useCreateSavedMediaMutation__
 *
 * To run a mutation, you first call `useCreateSavedMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedMediaMutation, { data, loading, error }] = useCreateSavedMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSavedMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateSavedMediaMutation, CreateSavedMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSavedMediaMutation, CreateSavedMediaMutationVariables>(CreateSavedMediaDocument, options);
      }
export type CreateSavedMediaMutationHookResult = ReturnType<typeof useCreateSavedMediaMutation>;
export type CreateSavedMediaMutationResult = Apollo.MutationResult<CreateSavedMediaMutation>;
export type CreateSavedMediaMutationOptions = Apollo.BaseMutationOptions<CreateSavedMediaMutation, CreateSavedMediaMutationVariables>;
export const ArchiveSavedMediaDocument = gql`
    mutation ArchiveSavedMedia($id: ID!) {
  archiveSavedMedia(id: $id) {
    id
  }
}
    `;
export type ArchiveSavedMediaMutationFn = Apollo.MutationFunction<ArchiveSavedMediaMutation, ArchiveSavedMediaMutationVariables>;

/**
 * __useArchiveSavedMediaMutation__
 *
 * To run a mutation, you first call `useArchiveSavedMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSavedMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSavedMediaMutation, { data, loading, error }] = useArchiveSavedMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveSavedMediaMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveSavedMediaMutation, ArchiveSavedMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveSavedMediaMutation, ArchiveSavedMediaMutationVariables>(ArchiveSavedMediaDocument, options);
      }
export type ArchiveSavedMediaMutationHookResult = ReturnType<typeof useArchiveSavedMediaMutation>;
export type ArchiveSavedMediaMutationResult = Apollo.MutationResult<ArchiveSavedMediaMutation>;
export type ArchiveSavedMediaMutationOptions = Apollo.BaseMutationOptions<ArchiveSavedMediaMutation, ArchiveSavedMediaMutationVariables>;
export const DeleteSavedMediaDocument = gql`
    mutation DeleteSavedMedia($id: ID!) {
  deleteSavedMedia(id: $id)
}
    `;
export type DeleteSavedMediaMutationFn = Apollo.MutationFunction<DeleteSavedMediaMutation, DeleteSavedMediaMutationVariables>;

/**
 * __useDeleteSavedMediaMutation__
 *
 * To run a mutation, you first call `useDeleteSavedMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSavedMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSavedMediaMutation, { data, loading, error }] = useDeleteSavedMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSavedMediaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSavedMediaMutation, DeleteSavedMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSavedMediaMutation, DeleteSavedMediaMutationVariables>(DeleteSavedMediaDocument, options);
      }
export type DeleteSavedMediaMutationHookResult = ReturnType<typeof useDeleteSavedMediaMutation>;
export type DeleteSavedMediaMutationResult = Apollo.MutationResult<DeleteSavedMediaMutation>;
export type DeleteSavedMediaMutationOptions = Apollo.BaseMutationOptions<DeleteSavedMediaMutation, DeleteSavedMediaMutationVariables>;
export const GetSavedMediaDocument = gql`
    query GetSavedMedia($docId: ID, $themeId: ID, $interactionId: ID, $context: SavedMediaContext, $type: MediaType!, $source: String!, $sortBy: SavedMediaSortOrder, $first: Int, $after: String, $last: Int, $before: String) {
  savedMedia(
    docId: $docId
    themeId: $themeId
    interactionId: $interactionId
    context: $context
    type: $type
    source: $source
    sortBy: $sortBy
    first: $first
    after: $after
    last: $last
    before: $before
  ) {
    edges {
      node {
        ...SavedMediaFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${SavedMediaFragmentFragmentDoc}`;

/**
 * __useGetSavedMediaQuery__
 *
 * To run a query within a React component, call `useGetSavedMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedMediaQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      themeId: // value for 'themeId'
 *      interactionId: // value for 'interactionId'
 *      context: // value for 'context'
 *      type: // value for 'type'
 *      source: // value for 'source'
 *      sortBy: // value for 'sortBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetSavedMediaQuery(baseOptions: Apollo.QueryHookOptions<GetSavedMediaQuery, GetSavedMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSavedMediaQuery, GetSavedMediaQueryVariables>(GetSavedMediaDocument, options);
      }
export function useGetSavedMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSavedMediaQuery, GetSavedMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSavedMediaQuery, GetSavedMediaQueryVariables>(GetSavedMediaDocument, options);
        }
export type GetSavedMediaQueryHookResult = ReturnType<typeof useGetSavedMediaQuery>;
export type GetSavedMediaLazyQueryHookResult = ReturnType<typeof useGetSavedMediaLazyQuery>;
export type GetSavedMediaQueryResult = Apollo.QueryResult<GetSavedMediaQuery, GetSavedMediaQueryVariables>;
export const GetUserSavedMediaDocument = gql`
    query GetUserSavedMedia($workspaceId: ID!, $types: [MediaType!]!, $source: String, $docId: ID, $themeId: ID, $interactionId: ID, $sortBy: SavedMediaSortOrder, $first: Int, $after: String, $last: Int, $before: String) {
  userSavedMedia(
    workspaceId: $workspaceId
    types: $types
    source: $source
    docId: $docId
    themeId: $themeId
    interactionId: $interactionId
    sortBy: $sortBy
    first: $first
    after: $after
    last: $last
    before: $before
  ) {
    edges {
      node {
        ...SavedMediaFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${SavedMediaFragmentFragmentDoc}`;

/**
 * __useGetUserSavedMediaQuery__
 *
 * To run a query within a React component, call `useGetUserSavedMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSavedMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSavedMediaQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      types: // value for 'types'
 *      source: // value for 'source'
 *      docId: // value for 'docId'
 *      themeId: // value for 'themeId'
 *      interactionId: // value for 'interactionId'
 *      sortBy: // value for 'sortBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetUserSavedMediaQuery(baseOptions: Apollo.QueryHookOptions<GetUserSavedMediaQuery, GetUserSavedMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSavedMediaQuery, GetUserSavedMediaQueryVariables>(GetUserSavedMediaDocument, options);
      }
export function useGetUserSavedMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSavedMediaQuery, GetUserSavedMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSavedMediaQuery, GetUserSavedMediaQueryVariables>(GetUserSavedMediaDocument, options);
        }
export type GetUserSavedMediaQueryHookResult = ReturnType<typeof useGetUserSavedMediaQuery>;
export type GetUserSavedMediaLazyQueryHookResult = ReturnType<typeof useGetUserSavedMediaLazyQuery>;
export type GetUserSavedMediaQueryResult = Apollo.QueryResult<GetUserSavedMediaQuery, GetUserSavedMediaQueryVariables>;
export const GetWorkspaceSavedMediaDocument = gql`
    query GetWorkspaceSavedMedia($workspaceId: ID!, $types: [MediaType!]!, $source: String, $docId: ID, $themeId: ID, $interactionId: ID, $sortBy: SavedMediaSortOrder, $first: Int, $after: String, $last: Int, $before: String) {
  workspaceSavedMedia(
    workspaceId: $workspaceId
    types: $types
    source: $source
    docId: $docId
    themeId: $themeId
    interactionId: $interactionId
    sortBy: $sortBy
    first: $first
    after: $after
    last: $last
    before: $before
  ) {
    edges {
      node {
        ...SavedMediaFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${SavedMediaFragmentFragmentDoc}`;

/**
 * __useGetWorkspaceSavedMediaQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceSavedMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceSavedMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceSavedMediaQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      types: // value for 'types'
 *      source: // value for 'source'
 *      docId: // value for 'docId'
 *      themeId: // value for 'themeId'
 *      interactionId: // value for 'interactionId'
 *      sortBy: // value for 'sortBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetWorkspaceSavedMediaQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceSavedMediaQuery, GetWorkspaceSavedMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceSavedMediaQuery, GetWorkspaceSavedMediaQueryVariables>(GetWorkspaceSavedMediaDocument, options);
      }
export function useGetWorkspaceSavedMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceSavedMediaQuery, GetWorkspaceSavedMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceSavedMediaQuery, GetWorkspaceSavedMediaQueryVariables>(GetWorkspaceSavedMediaDocument, options);
        }
export type GetWorkspaceSavedMediaQueryHookResult = ReturnType<typeof useGetWorkspaceSavedMediaQuery>;
export type GetWorkspaceSavedMediaLazyQueryHookResult = ReturnType<typeof useGetWorkspaceSavedMediaLazyQuery>;
export type GetWorkspaceSavedMediaQueryResult = Apollo.QueryResult<GetWorkspaceSavedMediaQuery, GetWorkspaceSavedMediaQueryVariables>;
export const ProductsDocument = gql`
    query Products($workspaceId: ID, $productPriceFilter: ProductPriceInput) {
  products(workspaceId: $workspaceId, productPriceFilter: $productPriceFilter) {
    id
    key
    prices {
      id
      price
      currency
      discount {
        price
        discountPercent
        discountAmount
      }
      frequencyUnit
      frequencyInterval
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      productPriceFilter: // value for 'productPriceFilter'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const CreateCheckoutSessionDocument = gql`
    mutation CreateCheckoutSession($workspaceId: ID!, $items: [CheckoutItemInput!]!) {
  createCheckoutSession(workspaceId: $workspaceId, items: $items) {
    url
  }
}
    `;
export type CreateCheckoutSessionMutationFn = Apollo.MutationFunction<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(CreateCheckoutSessionDocument, options);
      }
export type CreateCheckoutSessionMutationHookResult = ReturnType<typeof useCreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationResult = Apollo.MutationResult<CreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;
export const UpdateSubscriptionProductDocument = gql`
    mutation UpdateSubscriptionProduct($id: ID!, $productPriceId: String!) {
  updateSubscriptionProduct(id: $id, productPriceId: $productPriceId) {
    id
  }
}
    `;
export type UpdateSubscriptionProductMutationFn = Apollo.MutationFunction<UpdateSubscriptionProductMutation, UpdateSubscriptionProductMutationVariables>;

/**
 * __useUpdateSubscriptionProductMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionProductMutation, { data, loading, error }] = useUpdateSubscriptionProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productPriceId: // value for 'productPriceId'
 *   },
 * });
 */
export function useUpdateSubscriptionProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionProductMutation, UpdateSubscriptionProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionProductMutation, UpdateSubscriptionProductMutationVariables>(UpdateSubscriptionProductDocument, options);
      }
export type UpdateSubscriptionProductMutationHookResult = ReturnType<typeof useUpdateSubscriptionProductMutation>;
export type UpdateSubscriptionProductMutationResult = Apollo.MutationResult<UpdateSubscriptionProductMutation>;
export type UpdateSubscriptionProductMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionProductMutation, UpdateSubscriptionProductMutationVariables>;
export const SearchDocsAndChannelsDocument = gql`
    query SearchDocsAndChannels($workspaceId: ID, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [doc, channel]) {
    __typename
    ... on DocResult {
      ...SearchDocResult
    }
    ... on Channel {
      ...SearchChannelResult
    }
  }
}
    ${SearchDocResultFragmentDoc}
${SearchChannelResultFragmentDoc}`;

/**
 * __useSearchDocsAndChannelsQuery__
 *
 * To run a query within a React component, call `useSearchDocsAndChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDocsAndChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDocsAndChannelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchDocsAndChannelsQuery(baseOptions: Apollo.QueryHookOptions<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>(SearchDocsAndChannelsDocument, options);
      }
export function useSearchDocsAndChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>(SearchDocsAndChannelsDocument, options);
        }
export type SearchDocsAndChannelsQueryHookResult = ReturnType<typeof useSearchDocsAndChannelsQuery>;
export type SearchDocsAndChannelsLazyQueryHookResult = ReturnType<typeof useSearchDocsAndChannelsLazyQuery>;
export type SearchDocsAndChannelsQueryResult = Apollo.QueryResult<SearchDocsAndChannelsQuery, SearchDocsAndChannelsQueryVariables>;
export const GetDocsForGlobalSearchDocument = gql`
    query GetDocsForGlobalSearch($first: Int, $after: String, $workspaceId: ID, $createdBy: ID, $archived: Boolean, $sortBy: DocSortOrder) {
  docs(
    first: $first
    after: $after
    workspaceId: $workspaceId
    createdBy: $createdBy
    archived: $archived
    sortBy: $sortBy
  ) {
    edges {
      node {
        ...DocFieldsFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${DocFieldsFragmentFragmentDoc}`;

/**
 * __useGetDocsForGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGetDocsForGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocsForGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocsForGlobalSearchQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      workspaceId: // value for 'workspaceId'
 *      createdBy: // value for 'createdBy'
 *      archived: // value for 'archived'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetDocsForGlobalSearchQuery(baseOptions?: Apollo.QueryHookOptions<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>(GetDocsForGlobalSearchDocument, options);
      }
export function useGetDocsForGlobalSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>(GetDocsForGlobalSearchDocument, options);
        }
export type GetDocsForGlobalSearchQueryHookResult = ReturnType<typeof useGetDocsForGlobalSearchQuery>;
export type GetDocsForGlobalSearchLazyQueryHookResult = ReturnType<typeof useGetDocsForGlobalSearchLazyQuery>;
export type GetDocsForGlobalSearchQueryResult = Apollo.QueryResult<GetDocsForGlobalSearchQuery, GetDocsForGlobalSearchQueryVariables>;
export const SharingSearchUserDocument = gql`
    query SharingSearchUser($workspaceId: ID!, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [user]) {
    __typename
    ... on User {
      ...ShareSearchUser
    }
  }
}
    ${ShareSearchUserFragmentDoc}`;

/**
 * __useSharingSearchUserQuery__
 *
 * To run a query within a React component, call `useSharingSearchUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharingSearchUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharingSearchUserQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSharingSearchUserQuery(baseOptions: Apollo.QueryHookOptions<SharingSearchUserQuery, SharingSearchUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharingSearchUserQuery, SharingSearchUserQueryVariables>(SharingSearchUserDocument, options);
      }
export function useSharingSearchUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharingSearchUserQuery, SharingSearchUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharingSearchUserQuery, SharingSearchUserQueryVariables>(SharingSearchUserDocument, options);
        }
export type SharingSearchUserQueryHookResult = ReturnType<typeof useSharingSearchUserQuery>;
export type SharingSearchUserLazyQueryHookResult = ReturnType<typeof useSharingSearchUserLazyQuery>;
export type SharingSearchUserQueryResult = Apollo.QueryResult<SharingSearchUserQuery, SharingSearchUserQueryVariables>;
export const SharingSearchChannelDocument = gql`
    query SharingSearchChannel($workspaceId: ID!, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [channel]) {
    __typename
    ... on Channel {
      ...ShareSearchChannel
    }
  }
}
    ${ShareSearchChannelFragmentDoc}`;

/**
 * __useSharingSearchChannelQuery__
 *
 * To run a query within a React component, call `useSharingSearchChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharingSearchChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharingSearchChannelQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSharingSearchChannelQuery(baseOptions: Apollo.QueryHookOptions<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>(SharingSearchChannelDocument, options);
      }
export function useSharingSearchChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>(SharingSearchChannelDocument, options);
        }
export type SharingSearchChannelQueryHookResult = ReturnType<typeof useSharingSearchChannelQuery>;
export type SharingSearchChannelLazyQueryHookResult = ReturnType<typeof useSharingSearchChannelLazyQuery>;
export type SharingSearchChannelQueryResult = Apollo.QueryResult<SharingSearchChannelQuery, SharingSearchChannelQueryVariables>;
export const UpdatePublicChannelsDocument = gql`
    mutation UpdatePublicChannels($input: DocSharingInput!) {
  updatePublicChannels(input: $input) {
    id
    channels {
      id
      name
      permission
    }
  }
}
    `;
export type UpdatePublicChannelsMutationFn = Apollo.MutationFunction<UpdatePublicChannelsMutation, UpdatePublicChannelsMutationVariables>;

/**
 * __useUpdatePublicChannelsMutation__
 *
 * To run a mutation, you first call `useUpdatePublicChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicChannelsMutation, { data, loading, error }] = useUpdatePublicChannelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublicChannelsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicChannelsMutation, UpdatePublicChannelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublicChannelsMutation, UpdatePublicChannelsMutationVariables>(UpdatePublicChannelsDocument, options);
      }
export type UpdatePublicChannelsMutationHookResult = ReturnType<typeof useUpdatePublicChannelsMutation>;
export type UpdatePublicChannelsMutationResult = Apollo.MutationResult<UpdatePublicChannelsMutation>;
export type UpdatePublicChannelsMutationOptions = Apollo.BaseMutationOptions<UpdatePublicChannelsMutation, UpdatePublicChannelsMutationVariables>;
export const UpdateDocSharingDocument = gql`
    mutation UpdateDocSharing($id: ID!, $sharing: [DocJSONPatch!]) {
  updateDoc(input: {id: $id, sharing: $sharing}) {
    id
    channels {
      id
      name
      slug
      permission
    }
  }
}
    `;
export type UpdateDocSharingMutationFn = Apollo.MutationFunction<UpdateDocSharingMutation, UpdateDocSharingMutationVariables>;

/**
 * __useUpdateDocSharingMutation__
 *
 * To run a mutation, you first call `useUpdateDocSharingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocSharingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocSharingMutation, { data, loading, error }] = useUpdateDocSharingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sharing: // value for 'sharing'
 *   },
 * });
 */
export function useUpdateDocSharingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocSharingMutation, UpdateDocSharingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocSharingMutation, UpdateDocSharingMutationVariables>(UpdateDocSharingDocument, options);
      }
export type UpdateDocSharingMutationHookResult = ReturnType<typeof useUpdateDocSharingMutation>;
export type UpdateDocSharingMutationResult = Apollo.MutationResult<UpdateDocSharingMutation>;
export type UpdateDocSharingMutationOptions = Apollo.BaseMutationOptions<UpdateDocSharingMutation, UpdateDocSharingMutationVariables>;
export const CreateSiteDocument = gql`
    mutation CreateSite($input: CreateSiteInput!) {
  createSite(input: $input) {
    ...SiteFragment
  }
}
    ${SiteFragmentFragmentDoc}`;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
      }
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const UpdateSiteDocument = gql`
    mutation UpdateSite($input: UpdateSiteInput!) {
  updateSite(input: $input) {
    id
    name
    faviconUrl
    themeId
    navEnabled
    navContent
  }
}
    `;
export type UpdateSiteMutationFn = Apollo.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, options);
      }
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = Apollo.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = Apollo.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
export const ArchiveSiteDocument = gql`
    mutation ArchiveSite($id: ID!) {
  archiveSite(id: $id) {
    id
    archived
  }
}
    `;
export type ArchiveSiteMutationFn = Apollo.MutationFunction<ArchiveSiteMutation, ArchiveSiteMutationVariables>;

/**
 * __useArchiveSiteMutation__
 *
 * To run a mutation, you first call `useArchiveSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSiteMutation, { data, loading, error }] = useArchiveSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveSiteMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveSiteMutation, ArchiveSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveSiteMutation, ArchiveSiteMutationVariables>(ArchiveSiteDocument, options);
      }
export type ArchiveSiteMutationHookResult = ReturnType<typeof useArchiveSiteMutation>;
export type ArchiveSiteMutationResult = Apollo.MutationResult<ArchiveSiteMutation>;
export type ArchiveSiteMutationOptions = Apollo.BaseMutationOptions<ArchiveSiteMutation, ArchiveSiteMutationVariables>;
export const UnarchiveSiteDocument = gql`
    mutation UnarchiveSite($id: ID!) {
  unarchiveSite(id: $id) {
    id
    enabled
    archived
  }
}
    `;
export type UnarchiveSiteMutationFn = Apollo.MutationFunction<UnarchiveSiteMutation, UnarchiveSiteMutationVariables>;

/**
 * __useUnarchiveSiteMutation__
 *
 * To run a mutation, you first call `useUnarchiveSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveSiteMutation, { data, loading, error }] = useUnarchiveSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveSiteMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveSiteMutation, UnarchiveSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveSiteMutation, UnarchiveSiteMutationVariables>(UnarchiveSiteDocument, options);
      }
export type UnarchiveSiteMutationHookResult = ReturnType<typeof useUnarchiveSiteMutation>;
export type UnarchiveSiteMutationResult = Apollo.MutationResult<UnarchiveSiteMutation>;
export type UnarchiveSiteMutationOptions = Apollo.BaseMutationOptions<UnarchiveSiteMutation, UnarchiveSiteMutationVariables>;
export const DisableSiteDocument = gql`
    mutation DisableSite($id: ID!) {
  disableSite(id: $id) {
    id
    enabled
  }
}
    `;
export type DisableSiteMutationFn = Apollo.MutationFunction<DisableSiteMutation, DisableSiteMutationVariables>;

/**
 * __useDisableSiteMutation__
 *
 * To run a mutation, you first call `useDisableSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSiteMutation, { data, loading, error }] = useDisableSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableSiteMutation(baseOptions?: Apollo.MutationHookOptions<DisableSiteMutation, DisableSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableSiteMutation, DisableSiteMutationVariables>(DisableSiteDocument, options);
      }
export type DisableSiteMutationHookResult = ReturnType<typeof useDisableSiteMutation>;
export type DisableSiteMutationResult = Apollo.MutationResult<DisableSiteMutation>;
export type DisableSiteMutationOptions = Apollo.BaseMutationOptions<DisableSiteMutation, DisableSiteMutationVariables>;
export const EnableSiteDocument = gql`
    mutation EnableSite($id: ID!) {
  enableSite(id: $id) {
    id
    enabled
  }
}
    `;
export type EnableSiteMutationFn = Apollo.MutationFunction<EnableSiteMutation, EnableSiteMutationVariables>;

/**
 * __useEnableSiteMutation__
 *
 * To run a mutation, you first call `useEnableSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableSiteMutation, { data, loading, error }] = useEnableSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableSiteMutation(baseOptions?: Apollo.MutationHookOptions<EnableSiteMutation, EnableSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableSiteMutation, EnableSiteMutationVariables>(EnableSiteDocument, options);
      }
export type EnableSiteMutationHookResult = ReturnType<typeof useEnableSiteMutation>;
export type EnableSiteMutationResult = Apollo.MutationResult<EnableSiteMutation>;
export type EnableSiteMutationOptions = Apollo.BaseMutationOptions<EnableSiteMutation, EnableSiteMutationVariables>;
export const UpdateRoutesDocument = gql`
    mutation UpdateRoutes($input: UpdateSiteRoutesInput!) {
  updateRoutes(input: $input) {
    id
    routes {
      path
      docId
    }
  }
}
    `;
export type UpdateRoutesMutationFn = Apollo.MutationFunction<UpdateRoutesMutation, UpdateRoutesMutationVariables>;

/**
 * __useUpdateRoutesMutation__
 *
 * To run a mutation, you first call `useUpdateRoutesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoutesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoutesMutation, { data, loading, error }] = useUpdateRoutesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoutesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoutesMutation, UpdateRoutesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoutesMutation, UpdateRoutesMutationVariables>(UpdateRoutesDocument, options);
      }
export type UpdateRoutesMutationHookResult = ReturnType<typeof useUpdateRoutesMutation>;
export type UpdateRoutesMutationResult = Apollo.MutationResult<UpdateRoutesMutation>;
export type UpdateRoutesMutationOptions = Apollo.BaseMutationOptions<UpdateRoutesMutation, UpdateRoutesMutationVariables>;
export const AddDomainDocument = gql`
    mutation AddDomain($input: AddDomainInput!) {
  addDomain(input: $input) {
    id
    domains {
      name
      status
    }
    routes {
      path
      docId
    }
  }
}
    `;
export type AddDomainMutationFn = Apollo.MutationFunction<AddDomainMutation, AddDomainMutationVariables>;

/**
 * __useAddDomainMutation__
 *
 * To run a mutation, you first call `useAddDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDomainMutation, { data, loading, error }] = useAddDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDomainMutation(baseOptions?: Apollo.MutationHookOptions<AddDomainMutation, AddDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDomainMutation, AddDomainMutationVariables>(AddDomainDocument, options);
      }
export type AddDomainMutationHookResult = ReturnType<typeof useAddDomainMutation>;
export type AddDomainMutationResult = Apollo.MutationResult<AddDomainMutation>;
export type AddDomainMutationOptions = Apollo.BaseMutationOptions<AddDomainMutation, AddDomainMutationVariables>;
export const DeleteDomainDocument = gql`
    mutation DeleteDomain($id: ID!) {
  deleteDomain(id: $id) {
    id
  }
}
    `;
export type DeleteDomainMutationFn = Apollo.MutationFunction<DeleteDomainMutation, DeleteDomainMutationVariables>;

/**
 * __useDeleteDomainMutation__
 *
 * To run a mutation, you first call `useDeleteDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainMutation, { data, loading, error }] = useDeleteDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDomainMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDomainMutation, DeleteDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDomainMutation, DeleteDomainMutationVariables>(DeleteDomainDocument, options);
      }
export type DeleteDomainMutationHookResult = ReturnType<typeof useDeleteDomainMutation>;
export type DeleteDomainMutationResult = Apollo.MutationResult<DeleteDomainMutation>;
export type DeleteDomainMutationOptions = Apollo.BaseMutationOptions<DeleteDomainMutation, DeleteDomainMutationVariables>;
export const ChangeBuiltinDomainDocument = gql`
    mutation changeBuiltinDomain($input: ChangeBuiltinDomainInput!) {
  changeBuiltinDomain(input: $input) {
    id
    domains {
      name
      status
      builtin
    }
    routes {
      path
      docId
    }
  }
}
    `;
export type ChangeBuiltinDomainMutationFn = Apollo.MutationFunction<ChangeBuiltinDomainMutation, ChangeBuiltinDomainMutationVariables>;

/**
 * __useChangeBuiltinDomainMutation__
 *
 * To run a mutation, you first call `useChangeBuiltinDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeBuiltinDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeBuiltinDomainMutation, { data, loading, error }] = useChangeBuiltinDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeBuiltinDomainMutation(baseOptions?: Apollo.MutationHookOptions<ChangeBuiltinDomainMutation, ChangeBuiltinDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeBuiltinDomainMutation, ChangeBuiltinDomainMutationVariables>(ChangeBuiltinDomainDocument, options);
      }
export type ChangeBuiltinDomainMutationHookResult = ReturnType<typeof useChangeBuiltinDomainMutation>;
export type ChangeBuiltinDomainMutationResult = Apollo.MutationResult<ChangeBuiltinDomainMutation>;
export type ChangeBuiltinDomainMutationOptions = Apollo.BaseMutationOptions<ChangeBuiltinDomainMutation, ChangeBuiltinDomainMutationVariables>;
export const GetSitesDocument = gql`
    query GetSites($workspaceId: ID!) {
  sites(workspaceId: $workspaceId) {
    ...SiteFragment
  }
}
    ${SiteFragmentFragmentDoc}`;

/**
 * __useGetSitesQuery__
 *
 * To run a query within a React component, call `useGetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetSitesQuery(baseOptions: Apollo.QueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
      }
export function useGetSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
        }
export type GetSitesQueryHookResult = ReturnType<typeof useGetSitesQuery>;
export type GetSitesLazyQueryHookResult = ReturnType<typeof useGetSitesLazyQuery>;
export type GetSitesQueryResult = Apollo.QueryResult<GetSitesQuery, GetSitesQueryVariables>;
export const GetSiteDocument = gql`
    query GetSite($id: ID!) {
  site(id: $id) {
    ...SiteFragment
  }
}
    ${SiteFragmentFragmentDoc}`;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions: Apollo.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, options);
      }
export function useGetSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, options);
        }
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = Apollo.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const GetDomainsDocument = gql`
    query GetDomains($workspaceId: ID!, $name: String!) {
  domains(workspaceId: $workspaceId, name: $name) {
    ...DefaultDomainsFields
    siteId
    siteName
    siteArchived
  }
}
    ${DefaultDomainsFieldsFragmentDoc}`;

/**
 * __useGetDomainsQuery__
 *
 * To run a query within a React component, call `useGetDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetDomainsQuery(baseOptions: Apollo.QueryHookOptions<GetDomainsQuery, GetDomainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDomainsQuery, GetDomainsQueryVariables>(GetDomainsDocument, options);
      }
export function useGetDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDomainsQuery, GetDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDomainsQuery, GetDomainsQueryVariables>(GetDomainsDocument, options);
        }
export type GetDomainsQueryHookResult = ReturnType<typeof useGetDomainsQuery>;
export type GetDomainsLazyQueryHookResult = ReturnType<typeof useGetDomainsLazyQuery>;
export type GetDomainsQueryResult = Apollo.QueryResult<GetDomainsQuery, GetDomainsQueryVariables>;
export const GetDocWithPublishedSnapshotDocument = gql`
    query GetDocWithPublishedSnapshot($id: ID!) {
  publishedDoc(id: $id) {
    id
    title
    organization {
      products
      id
      __typename
    }
    editors {
      docId
      user {
        id
        displayName
        profileImageUrl
      }
      lastEdited
    }
    theme {
      ...DefaultThemeFields
    }
    publishedSnapshot {
      id
      docId
      content
    }
    titleCard {
      previewUrl
      text
      title
    }
    cards {
      ...DefaultCardFields
    }
    editedTime
    site {
      routes {
        path
        docId
      }
      hasCustomDomain
      faviconUrl
      navContent
      navEnabled
      gtmContainerId
    }
    links {
      url
      nodeType
      status
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}
${DefaultCardFieldsFragmentDoc}`;

/**
 * __useGetDocWithPublishedSnapshotQuery__
 *
 * To run a query within a React component, call `useGetDocWithPublishedSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocWithPublishedSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocWithPublishedSnapshotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocWithPublishedSnapshotQuery(baseOptions: Apollo.QueryHookOptions<GetDocWithPublishedSnapshotQuery, GetDocWithPublishedSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocWithPublishedSnapshotQuery, GetDocWithPublishedSnapshotQueryVariables>(GetDocWithPublishedSnapshotDocument, options);
      }
export function useGetDocWithPublishedSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocWithPublishedSnapshotQuery, GetDocWithPublishedSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocWithPublishedSnapshotQuery, GetDocWithPublishedSnapshotQueryVariables>(GetDocWithPublishedSnapshotDocument, options);
        }
export type GetDocWithPublishedSnapshotQueryHookResult = ReturnType<typeof useGetDocWithPublishedSnapshotQuery>;
export type GetDocWithPublishedSnapshotLazyQueryHookResult = ReturnType<typeof useGetDocWithPublishedSnapshotLazyQuery>;
export type GetDocWithPublishedSnapshotQueryResult = Apollo.QueryResult<GetDocWithPublishedSnapshotQuery, GetDocWithPublishedSnapshotQueryVariables>;
export const GetPublishedDocDocument = gql`
    query GetPublishedDoc($id: ID!) {
  publishedDoc(id: $id) {
    id
    publishedTime
    publishedSnapshot {
      id
      content
    }
  }
}
    `;

/**
 * __useGetPublishedDocQuery__
 *
 * To run a query within a React component, call `useGetPublishedDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedDocQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublishedDocQuery(baseOptions: Apollo.QueryHookOptions<GetPublishedDocQuery, GetPublishedDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishedDocQuery, GetPublishedDocQueryVariables>(GetPublishedDocDocument, options);
      }
export function useGetPublishedDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishedDocQuery, GetPublishedDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishedDocQuery, GetPublishedDocQueryVariables>(GetPublishedDocDocument, options);
        }
export type GetPublishedDocQueryHookResult = ReturnType<typeof useGetPublishedDocQuery>;
export type GetPublishedDocLazyQueryHookResult = ReturnType<typeof useGetPublishedDocLazyQuery>;
export type GetPublishedDocQueryResult = Apollo.QueryResult<GetPublishedDocQuery, GetPublishedDocQueryVariables>;
export const PublishSiteDocument = gql`
    mutation PublishSite($id: ID!) {
  publishSite(id: $id) {
    ...SiteFragment
  }
}
    ${SiteFragmentFragmentDoc}`;
export type PublishSiteMutationFn = Apollo.MutationFunction<PublishSiteMutation, PublishSiteMutationVariables>;

/**
 * __usePublishSiteMutation__
 *
 * To run a mutation, you first call `usePublishSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishSiteMutation, { data, loading, error }] = usePublishSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishSiteMutation(baseOptions?: Apollo.MutationHookOptions<PublishSiteMutation, PublishSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishSiteMutation, PublishSiteMutationVariables>(PublishSiteDocument, options);
      }
export type PublishSiteMutationHookResult = ReturnType<typeof usePublishSiteMutation>;
export type PublishSiteMutationResult = Apollo.MutationResult<PublishSiteMutation>;
export type PublishSiteMutationOptions = Apollo.BaseMutationOptions<PublishSiteMutation, PublishSiteMutationVariables>;
export const PublishDocDocument = gql`
    mutation PublishDoc($id: ID!) {
  publishDoc(id: $id) {
    id
    publishedTime
    publishedSnapshot {
      id
    }
  }
}
    `;
export type PublishDocMutationFn = Apollo.MutationFunction<PublishDocMutation, PublishDocMutationVariables>;

/**
 * __usePublishDocMutation__
 *
 * To run a mutation, you first call `usePublishDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDocMutation, { data, loading, error }] = usePublishDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishDocMutation(baseOptions?: Apollo.MutationHookOptions<PublishDocMutation, PublishDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishDocMutation, PublishDocMutationVariables>(PublishDocDocument, options);
      }
export type PublishDocMutationHookResult = ReturnType<typeof usePublishDocMutation>;
export type PublishDocMutationResult = Apollo.MutationResult<PublishDocMutation>;
export type PublishDocMutationOptions = Apollo.BaseMutationOptions<PublishDocMutation, PublishDocMutationVariables>;
export const ArchivePageDocument = gql`
    mutation archivePage($id: ID!) {
  archivePage(id: $id) {
    id
    publishedTime
    publishedSnapshot {
      id
    }
  }
}
    `;
export type ArchivePageMutationFn = Apollo.MutationFunction<ArchivePageMutation, ArchivePageMutationVariables>;

/**
 * __useArchivePageMutation__
 *
 * To run a mutation, you first call `useArchivePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePageMutation, { data, loading, error }] = useArchivePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchivePageMutation(baseOptions?: Apollo.MutationHookOptions<ArchivePageMutation, ArchivePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchivePageMutation, ArchivePageMutationVariables>(ArchivePageDocument, options);
      }
export type ArchivePageMutationHookResult = ReturnType<typeof useArchivePageMutation>;
export type ArchivePageMutationResult = Apollo.MutationResult<ArchivePageMutation>;
export type ArchivePageMutationOptions = Apollo.BaseMutationOptions<ArchivePageMutation, ArchivePageMutationVariables>;
export const VerifyDomainDocument = gql`
    mutation VerifyDomain($id: ID!) {
  verifyDomain(id: $id) {
    id
    domains {
      id
      name
      status
    }
  }
}
    `;
export type VerifyDomainMutationFn = Apollo.MutationFunction<VerifyDomainMutation, VerifyDomainMutationVariables>;

/**
 * __useVerifyDomainMutation__
 *
 * To run a mutation, you first call `useVerifyDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyDomainMutation, { data, loading, error }] = useVerifyDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyDomainMutation(baseOptions?: Apollo.MutationHookOptions<VerifyDomainMutation, VerifyDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyDomainMutation, VerifyDomainMutationVariables>(VerifyDomainDocument, options);
      }
export type VerifyDomainMutationHookResult = ReturnType<typeof useVerifyDomainMutation>;
export type VerifyDomainMutationResult = Apollo.MutationResult<VerifyDomainMutation>;
export type VerifyDomainMutationOptions = Apollo.BaseMutationOptions<VerifyDomainMutation, VerifyDomainMutationVariables>;
export const TransferDomainDocument = gql`
    mutation TransferDomain($id: ID!, $siteId: ID!) {
  transferDomain(id: $id, siteId: $siteId) {
    id
    siteId
    name
  }
}
    `;
export type TransferDomainMutationFn = Apollo.MutationFunction<TransferDomainMutation, TransferDomainMutationVariables>;

/**
 * __useTransferDomainMutation__
 *
 * To run a mutation, you first call `useTransferDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferDomainMutation, { data, loading, error }] = useTransferDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useTransferDomainMutation(baseOptions?: Apollo.MutationHookOptions<TransferDomainMutation, TransferDomainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferDomainMutation, TransferDomainMutationVariables>(TransferDomainDocument, options);
      }
export type TransferDomainMutationHookResult = ReturnType<typeof useTransferDomainMutation>;
export type TransferDomainMutationResult = Apollo.MutationResult<TransferDomainMutation>;
export type TransferDomainMutationOptions = Apollo.BaseMutationOptions<TransferDomainMutation, TransferDomainMutationVariables>;
export const SetRouteDocument = gql`
    mutation SetRoute($input: SetRouteInput!) {
  setRoute(input: $input) {
    id
    name
    routes {
      path
      docId
      doc {
        title
      }
      offline
    }
  }
}
    `;
export type SetRouteMutationFn = Apollo.MutationFunction<SetRouteMutation, SetRouteMutationVariables>;

/**
 * __useSetRouteMutation__
 *
 * To run a mutation, you first call `useSetRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRouteMutation, { data, loading, error }] = useSetRouteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetRouteMutation(baseOptions?: Apollo.MutationHookOptions<SetRouteMutation, SetRouteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRouteMutation, SetRouteMutationVariables>(SetRouteDocument, options);
      }
export type SetRouteMutationHookResult = ReturnType<typeof useSetRouteMutation>;
export type SetRouteMutationResult = Apollo.MutationResult<SetRouteMutation>;
export type SetRouteMutationOptions = Apollo.BaseMutationOptions<SetRouteMutation, SetRouteMutationVariables>;
export const CheckDomainDocument = gql`
    query CheckDomain($name: String!) {
  checkDomain(name: $name) {
    ok
    dns {
      status
      errors {
        code
        message
      }
    }
    ssl {
      ok
      errors {
        code
        message
      }
    }
    http {
      ok
      errors {
        code
        message
      }
    }
    actions {
      code
      data
    }
  }
}
    `;

/**
 * __useCheckDomainQuery__
 *
 * To run a query within a React component, call `useCheckDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckDomainQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCheckDomainQuery(baseOptions: Apollo.QueryHookOptions<CheckDomainQuery, CheckDomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckDomainQuery, CheckDomainQueryVariables>(CheckDomainDocument, options);
      }
export function useCheckDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckDomainQuery, CheckDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckDomainQuery, CheckDomainQueryVariables>(CheckDomainDocument, options);
        }
export type CheckDomainQueryHookResult = ReturnType<typeof useCheckDomainQuery>;
export type CheckDomainLazyQueryHookResult = ReturnType<typeof useCheckDomainLazyQuery>;
export type CheckDomainQueryResult = Apollo.QueryResult<CheckDomainQuery, CheckDomainQueryVariables>;
export const CreateThemeDocument = gql`
    mutation CreateTheme($input: CreateThemeInput!) {
  createTheme(input: $input) {
    ...DefaultThemeFields
    docCount
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type CreateThemeMutationFn = Apollo.MutationFunction<CreateThemeMutation, CreateThemeMutationVariables>;

/**
 * __useCreateThemeMutation__
 *
 * To run a mutation, you first call `useCreateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThemeMutation, { data, loading, error }] = useCreateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThemeMutation(baseOptions?: Apollo.MutationHookOptions<CreateThemeMutation, CreateThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateThemeMutation, CreateThemeMutationVariables>(CreateThemeDocument, options);
      }
export type CreateThemeMutationHookResult = ReturnType<typeof useCreateThemeMutation>;
export type CreateThemeMutationResult = Apollo.MutationResult<CreateThemeMutation>;
export type CreateThemeMutationOptions = Apollo.BaseMutationOptions<CreateThemeMutation, CreateThemeMutationVariables>;
export const UpdateThemeDocument = gql`
    mutation UpdateTheme($input: UpdateThemeInput!) {
  updateTheme(input: $input) {
    ...DefaultThemeFields
    docCount
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type UpdateThemeMutationFn = Apollo.MutationFunction<UpdateThemeMutation, UpdateThemeMutationVariables>;

/**
 * __useUpdateThemeMutation__
 *
 * To run a mutation, you first call `useUpdateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThemeMutation, { data, loading, error }] = useUpdateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateThemeMutation, UpdateThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateThemeMutation, UpdateThemeMutationVariables>(UpdateThemeDocument, options);
      }
export type UpdateThemeMutationHookResult = ReturnType<typeof useUpdateThemeMutation>;
export type UpdateThemeMutationResult = Apollo.MutationResult<UpdateThemeMutation>;
export type UpdateThemeMutationOptions = Apollo.BaseMutationOptions<UpdateThemeMutation, UpdateThemeMutationVariables>;
export const MakeThemeStandardDocument = gql`
    mutation MakeThemeStandard($id: ID!, $newId: ID) {
  makeThemeStandard(id: $id, newId: $newId) {
    ...DefaultThemeFields
    docCount
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type MakeThemeStandardMutationFn = Apollo.MutationFunction<MakeThemeStandardMutation, MakeThemeStandardMutationVariables>;

/**
 * __useMakeThemeStandardMutation__
 *
 * To run a mutation, you first call `useMakeThemeStandardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeThemeStandardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeThemeStandardMutation, { data, loading, error }] = useMakeThemeStandardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newId: // value for 'newId'
 *   },
 * });
 */
export function useMakeThemeStandardMutation(baseOptions?: Apollo.MutationHookOptions<MakeThemeStandardMutation, MakeThemeStandardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeThemeStandardMutation, MakeThemeStandardMutationVariables>(MakeThemeStandardDocument, options);
      }
export type MakeThemeStandardMutationHookResult = ReturnType<typeof useMakeThemeStandardMutation>;
export type MakeThemeStandardMutationResult = Apollo.MutationResult<MakeThemeStandardMutation>;
export type MakeThemeStandardMutationOptions = Apollo.BaseMutationOptions<MakeThemeStandardMutation, MakeThemeStandardMutationVariables>;
export const DuplicateThemeDocument = gql`
    mutation DuplicateTheme($id: ID!) {
  duplicateTheme(id: $id) {
    ...DefaultThemeFields
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type DuplicateThemeMutationFn = Apollo.MutationFunction<DuplicateThemeMutation, DuplicateThemeMutationVariables>;

/**
 * __useDuplicateThemeMutation__
 *
 * To run a mutation, you first call `useDuplicateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateThemeMutation, { data, loading, error }] = useDuplicateThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateThemeMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateThemeMutation, DuplicateThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateThemeMutation, DuplicateThemeMutationVariables>(DuplicateThemeDocument, options);
      }
export type DuplicateThemeMutationHookResult = ReturnType<typeof useDuplicateThemeMutation>;
export type DuplicateThemeMutationResult = Apollo.MutationResult<DuplicateThemeMutation>;
export type DuplicateThemeMutationOptions = Apollo.BaseMutationOptions<DuplicateThemeMutation, DuplicateThemeMutationVariables>;
export const GetThemeDocument = gql`
    query GetTheme($id: ID) {
  theme(id: $id) {
    ...DefaultThemeFields
    createdBy {
      id
      displayName
      email
      profileImageUrl
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;

/**
 * __useGetThemeQuery__
 *
 * To run a query within a React component, call `useGetThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThemeQuery(baseOptions?: Apollo.QueryHookOptions<GetThemeQuery, GetThemeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThemeQuery, GetThemeQueryVariables>(GetThemeDocument, options);
      }
export function useGetThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemeQuery, GetThemeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThemeQuery, GetThemeQueryVariables>(GetThemeDocument, options);
        }
export type GetThemeQueryHookResult = ReturnType<typeof useGetThemeQuery>;
export type GetThemeLazyQueryHookResult = ReturnType<typeof useGetThemeLazyQuery>;
export type GetThemeQueryResult = Apollo.QueryResult<GetThemeQuery, GetThemeQueryVariables>;
export const GetThemeWithCountDocument = gql`
    query GetThemeWithCount($id: ID) {
  theme(id: $id) {
    ...DefaultThemeFields
    docCount
    siteCount
    createdBy {
      id
      displayName
      email
      profileImageUrl
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;

/**
 * __useGetThemeWithCountQuery__
 *
 * To run a query within a React component, call `useGetThemeWithCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemeWithCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemeWithCountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThemeWithCountQuery(baseOptions?: Apollo.QueryHookOptions<GetThemeWithCountQuery, GetThemeWithCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThemeWithCountQuery, GetThemeWithCountQueryVariables>(GetThemeWithCountDocument, options);
      }
export function useGetThemeWithCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemeWithCountQuery, GetThemeWithCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThemeWithCountQuery, GetThemeWithCountQueryVariables>(GetThemeWithCountDocument, options);
        }
export type GetThemeWithCountQueryHookResult = ReturnType<typeof useGetThemeWithCountQuery>;
export type GetThemeWithCountLazyQueryHookResult = ReturnType<typeof useGetThemeWithCountLazyQuery>;
export type GetThemeWithCountQueryResult = Apollo.QueryResult<GetThemeWithCountQuery, GetThemeWithCountQueryVariables>;
export const GetThemesDocument = gql`
    query GetThemes($workspaceId: ID, $archived: Boolean) {
  themes(workspaceId: $workspaceId, archived: $archived) {
    ...DefaultThemeFields
    createdBy {
      id
      displayName
      email
      profileImageUrl
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;

/**
 * __useGetThemesQuery__
 *
 * To run a query within a React component, call `useGetThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetThemesQuery(baseOptions?: Apollo.QueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options);
      }
export function useGetThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options);
        }
export type GetThemesQueryHookResult = ReturnType<typeof useGetThemesQuery>;
export type GetThemesLazyQueryHookResult = ReturnType<typeof useGetThemesLazyQuery>;
export type GetThemesQueryResult = Apollo.QueryResult<GetThemesQuery, GetThemesQueryVariables>;
export const ArchiveThemeDocument = gql`
    mutation ArchiveTheme($id: ID!) {
  archiveTheme(id: $id) {
    ...DefaultThemeFields
    docCount
    siteCount
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type ArchiveThemeMutationFn = Apollo.MutationFunction<ArchiveThemeMutation, ArchiveThemeMutationVariables>;

/**
 * __useArchiveThemeMutation__
 *
 * To run a mutation, you first call `useArchiveThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveThemeMutation, { data, loading, error }] = useArchiveThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveThemeMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveThemeMutation, ArchiveThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveThemeMutation, ArchiveThemeMutationVariables>(ArchiveThemeDocument, options);
      }
export type ArchiveThemeMutationHookResult = ReturnType<typeof useArchiveThemeMutation>;
export type ArchiveThemeMutationResult = Apollo.MutationResult<ArchiveThemeMutation>;
export type ArchiveThemeMutationOptions = Apollo.BaseMutationOptions<ArchiveThemeMutation, ArchiveThemeMutationVariables>;
export const UnarchiveThemeDocument = gql`
    mutation UnarchiveTheme($id: ID!) {
  unarchiveTheme(id: $id) {
    ...DefaultThemeFields
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type UnarchiveThemeMutationFn = Apollo.MutationFunction<UnarchiveThemeMutation, UnarchiveThemeMutationVariables>;

/**
 * __useUnarchiveThemeMutation__
 *
 * To run a mutation, you first call `useUnarchiveThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveThemeMutation, { data, loading, error }] = useUnarchiveThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveThemeMutation(baseOptions?: Apollo.MutationHookOptions<UnarchiveThemeMutation, UnarchiveThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnarchiveThemeMutation, UnarchiveThemeMutationVariables>(UnarchiveThemeDocument, options);
      }
export type UnarchiveThemeMutationHookResult = ReturnType<typeof useUnarchiveThemeMutation>;
export type UnarchiveThemeMutationResult = Apollo.MutationResult<UnarchiveThemeMutation>;
export type UnarchiveThemeMutationOptions = Apollo.BaseMutationOptions<UnarchiveThemeMutation, UnarchiveThemeMutationVariables>;
export const CreateCardDocument = gql`
    mutation CreateCard($id: ID!, $docId: ID!) {
  createCard(id: $id, docId: $docId) {
    id
  }
}
    `;
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, options);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const GetCardDocument = gql`
    query GetCard($id: ID!, $docId: ID!) {
  card(id: $id, docId: $docId) {
    ...DefaultCardFields
  }
}
    ${DefaultCardFieldsFragmentDoc}`;

/**
 * __useGetCardQuery__
 *
 * To run a query within a React component, call `useGetCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useGetCardQuery(baseOptions: Apollo.QueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
      }
export function useGetCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export type GetCardQueryHookResult = ReturnType<typeof useGetCardQuery>;
export type GetCardLazyQueryHookResult = ReturnType<typeof useGetCardLazyQuery>;
export type GetCardQueryResult = Apollo.QueryResult<GetCardQuery, GetCardQueryVariables>;
export const GetCardsDocument = gql`
    query GetCards($docId: ID!, $password: String) {
  docCards(docId: $docId, password: $password, archived: false) {
    ...DefaultCardFields
  }
}
    ${DefaultCardFieldsFragmentDoc}`;

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetCardsQuery(baseOptions: Apollo.QueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
      }
export function useGetCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, options);
        }
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsQueryResult = Apollo.QueryResult<GetCardsQuery, GetCardsQueryVariables>;
export const SubscribeToCardsDocument = gql`
    subscription SubscribeToCards($docId: ID!) {
  docCards(docId: $docId) {
    ...DefaultCardFields
  }
}
    ${DefaultCardFieldsFragmentDoc}`;

/**
 * __useSubscribeToCardsSubscription__
 *
 * To run a query within a React component, call `useSubscribeToCardsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToCardsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToCardsSubscription({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useSubscribeToCardsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToCardsSubscription, SubscribeToCardsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToCardsSubscription, SubscribeToCardsSubscriptionVariables>(SubscribeToCardsDocument, options);
      }
export type SubscribeToCardsSubscriptionHookResult = ReturnType<typeof useSubscribeToCardsSubscription>;
export type SubscribeToCardsSubscriptionResult = Apollo.SubscriptionResult<SubscribeToCardsSubscription>;
export const GetDocMentionDocument = gql`
    query GetDocMention($id: ID!) {
  doc(id: $id) {
    id
    title
    updatedTime
    editedTime
    titleCard {
      previewUrl
    }
    createdTime
    createdBy {
      id
      displayName
      email
      profileImageUrl
    }
    docUser {
      id
      docId
      lastViewed
      lastEdited
      favorited
    }
    editors {
      user {
        id
        displayName
        profileImageUrl
      }
      lastViewed
      lastEdited
    }
  }
}
    `;

/**
 * __useGetDocMentionQuery__
 *
 * To run a query within a React component, call `useGetDocMentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocMentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocMentionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocMentionQuery(baseOptions: Apollo.QueryHookOptions<GetDocMentionQuery, GetDocMentionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocMentionQuery, GetDocMentionQueryVariables>(GetDocMentionDocument, options);
      }
export function useGetDocMentionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocMentionQuery, GetDocMentionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocMentionQuery, GetDocMentionQueryVariables>(GetDocMentionDocument, options);
        }
export type GetDocMentionQueryHookResult = ReturnType<typeof useGetDocMentionQuery>;
export type GetDocMentionLazyQueryHookResult = ReturnType<typeof useGetDocMentionLazyQuery>;
export type GetDocMentionQueryResult = Apollo.QueryResult<GetDocMentionQuery, GetDocMentionQueryVariables>;
export const SubscribeToDocMentionDocument = gql`
    subscription SubscribeToDocMention($id: ID!) {
  doc(id: $id) {
    ... on DocSubscription {
      id
      title
      updatedTime
      editedTime
      titleCard {
        previewUrl
      }
      editors {
        user {
          id
          displayName
          profileImageUrl
        }
        lastViewed
        lastEdited
      }
    }
  }
}
    `;

/**
 * __useSubscribeToDocMentionSubscription__
 *
 * To run a query within a React component, call `useSubscribeToDocMentionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToDocMentionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToDocMentionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeToDocMentionSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToDocMentionSubscription, SubscribeToDocMentionSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToDocMentionSubscription, SubscribeToDocMentionSubscriptionVariables>(SubscribeToDocMentionDocument, options);
      }
export type SubscribeToDocMentionSubscriptionHookResult = ReturnType<typeof useSubscribeToDocMentionSubscription>;
export type SubscribeToDocMentionSubscriptionResult = Apollo.SubscriptionResult<SubscribeToDocMentionSubscription>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    jobFunction
    referrer
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserSettingsDocument = gql`
    mutation UpdateUserSettings($patch: [JSONPatch!]) {
  updateUserSettings(patch: $patch) {
    showWorkspaceSetup
    showGetStarted
    welcomeDocId
    locale
    unsubscribeAnnouncementEmails
    unsubscribeOnboardingEmails
    goals
  }
}
    `;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsDocument, options);
      }
export type UpdateUserSettingsMutationHookResult = ReturnType<typeof useUpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationResult = Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>;
export const GetGraphqlUserDocument = gql`
    query GetGraphqlUser {
  user {
    id
    displayName
    profileImageUrl
    email
    workspaceMemberships {
      workspace {
        id
        name
        adminCount
        memberCount
      }
      role
      disabled
    }
  }
}
    `;

/**
 * __useGetGraphqlUserQuery__
 *
 * To run a query within a React component, call `useGetGraphqlUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphqlUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphqlUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGraphqlUserQuery(baseOptions?: Apollo.QueryHookOptions<GetGraphqlUserQuery, GetGraphqlUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphqlUserQuery, GetGraphqlUserQueryVariables>(GetGraphqlUserDocument, options);
      }
export function useGetGraphqlUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphqlUserQuery, GetGraphqlUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphqlUserQuery, GetGraphqlUserQueryVariables>(GetGraphqlUserDocument, options);
        }
export type GetGraphqlUserQueryHookResult = ReturnType<typeof useGetGraphqlUserQuery>;
export type GetGraphqlUserLazyQueryHookResult = ReturnType<typeof useGetGraphqlUserLazyQuery>;
export type GetGraphqlUserQueryResult = Apollo.QueryResult<GetGraphqlUserQuery, GetGraphqlUserQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser {
  deleteUser {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetWorkspaceSubscriptionDocument = gql`
    query GetWorkspaceSubscription($workspaceId: ID!, $includeExpired: Boolean) {
  subscription(workspaceId: $workspaceId, includeExpired: $includeExpired) {
    id
    status
    billingSubscriptionId
    discountPercent
    createdByUser {
      id
      email
      displayName
      billingCustomerId
    }
    products {
      id
      name
      key
      productPrice {
        id
        price
        currency
        frequencyUnit
        frequencyInterval
      }
      units
      createdTime
    }
    createdTime
    nextBillingTime
    expirationTime
    cancellationRequestedTime
  }
}
    `;

/**
 * __useGetWorkspaceSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceSubscriptionQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      includeExpired: // value for 'includeExpired'
 *   },
 * });
 */
export function useGetWorkspaceSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>(GetWorkspaceSubscriptionDocument, options);
      }
export function useGetWorkspaceSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>(GetWorkspaceSubscriptionDocument, options);
        }
export type GetWorkspaceSubscriptionQueryHookResult = ReturnType<typeof useGetWorkspaceSubscriptionQuery>;
export type GetWorkspaceSubscriptionLazyQueryHookResult = ReturnType<typeof useGetWorkspaceSubscriptionLazyQuery>;
export type GetWorkspaceSubscriptionQueryResult = Apollo.QueryResult<GetWorkspaceSubscriptionQuery, GetWorkspaceSubscriptionQueryVariables>;
export const UpdateWorkspaceDocument = gql`
    mutation UpdateWorkspace($input: UpdateWorkspaceInput!) {
  updateWorkspace(input: $input) {
    id
    name
    defaultThemeId
    logoUrl
    workspaceMemberPermission
    accessLinkPermission
  }
}
    `;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>(UpdateWorkspaceDocument, options);
      }
export type UpdateWorkspaceMutationHookResult = ReturnType<typeof useUpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationResult = Apollo.MutationResult<UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<UpdateWorkspaceMutation, UpdateWorkspaceMutationVariables>;
export const GetWorkspaceMembersDocument = gql`
    query GetWorkspaceMembers($first: Int, $after: String, $sortBy: WorkspaceMembersSortOrder, $id: ID!) {
  workspaceMembers(id: $id, first: $first, after: $after, sortBy: $sortBy) {
    edges {
      node {
        displayName
        id
        createdTime
        profileImageUrl
        email
        workspaceMemberships {
          workspace {
            id
          }
          role
          createdTime
          disabled
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetWorkspaceMembersQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceMembersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      sortBy: // value for 'sortBy'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceMembersQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>(GetWorkspaceMembersDocument, options);
      }
export function useGetWorkspaceMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>(GetWorkspaceMembersDocument, options);
        }
export type GetWorkspaceMembersQueryHookResult = ReturnType<typeof useGetWorkspaceMembersQuery>;
export type GetWorkspaceMembersLazyQueryHookResult = ReturnType<typeof useGetWorkspaceMembersLazyQuery>;
export type GetWorkspaceMembersQueryResult = Apollo.QueryResult<GetWorkspaceMembersQuery, GetWorkspaceMembersQueryVariables>;
export const GetWorkspaceDocument = gql`
    query GetWorkspace($id: String!) {
  workspace(id: $id) {
    id
    name
    adminCount
    memberCount
    invitations {
      id
      email
      createdTime
    }
    defaultThemeId
    logoUrl
    workspaceMemberPermission
    accessLinkPermission
  }
}
    `;

/**
 * __useGetWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWorkspaceQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
      }
export function useGetWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceQuery, GetWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceQuery, GetWorkspaceQueryVariables>(GetWorkspaceDocument, options);
        }
export type GetWorkspaceQueryHookResult = ReturnType<typeof useGetWorkspaceQuery>;
export type GetWorkspaceLazyQueryHookResult = ReturnType<typeof useGetWorkspaceLazyQuery>;
export type GetWorkspaceQueryResult = Apollo.QueryResult<GetWorkspaceQuery, GetWorkspaceQueryVariables>;
export const UpdateMemberRoleDocument = gql`
    mutation UpdateMemberRole($workspaceId: ID!, $userId: ID!, $role: WorkspaceRole) {
  updateMemberRole(workspaceId: $workspaceId, userId: $userId, role: $role) {
    id
    workspaceMemberships {
      workspace {
        id
        name
      }
      role
      disabled
      createdTime
    }
  }
}
    `;
export type UpdateMemberRoleMutationFn = Apollo.MutationFunction<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>;

/**
 * __useUpdateMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberRoleMutation, { data, loading, error }] = useUpdateMemberRoleMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>(UpdateMemberRoleDocument, options);
      }
export type UpdateMemberRoleMutationHookResult = ReturnType<typeof useUpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationResult = Apollo.MutationResult<UpdateMemberRoleMutation>;
export type UpdateMemberRoleMutationOptions = Apollo.BaseMutationOptions<UpdateMemberRoleMutation, UpdateMemberRoleMutationVariables>;
export const RemoveMemberDocument = gql`
    mutation RemoveMember($workspaceId: ID!, $userId: ID!) {
  removeMember(workspaceId: $workspaceId, userId: $userId) {
    id
    name
    adminCount
  }
}
    `;
export type RemoveMemberMutationFn = Apollo.MutationFunction<RemoveMemberMutation, RemoveMemberMutationVariables>;

/**
 * __useRemoveMemberMutation__
 *
 * To run a mutation, you first call `useRemoveMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMemberMutation, { data, loading, error }] = useRemoveMemberMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveMemberMutation, RemoveMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveMemberMutation, RemoveMemberMutationVariables>(RemoveMemberDocument, options);
      }
export type RemoveMemberMutationHookResult = ReturnType<typeof useRemoveMemberMutation>;
export type RemoveMemberMutationResult = Apollo.MutationResult<RemoveMemberMutation>;
export type RemoveMemberMutationOptions = Apollo.BaseMutationOptions<RemoveMemberMutation, RemoveMemberMutationVariables>;
export const RevokeWorkspaceInvitationDocument = gql`
    mutation RevokeWorkspaceInvitation($id: ID!) {
  revokeWorkspaceInvitation(id: $id) {
    id
    invitations {
      id
      email
      createdTime
    }
  }
}
    `;
export type RevokeWorkspaceInvitationMutationFn = Apollo.MutationFunction<RevokeWorkspaceInvitationMutation, RevokeWorkspaceInvitationMutationVariables>;

/**
 * __useRevokeWorkspaceInvitationMutation__
 *
 * To run a mutation, you first call `useRevokeWorkspaceInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeWorkspaceInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeWorkspaceInvitationMutation, { data, loading, error }] = useRevokeWorkspaceInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevokeWorkspaceInvitationMutation(baseOptions?: Apollo.MutationHookOptions<RevokeWorkspaceInvitationMutation, RevokeWorkspaceInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeWorkspaceInvitationMutation, RevokeWorkspaceInvitationMutationVariables>(RevokeWorkspaceInvitationDocument, options);
      }
export type RevokeWorkspaceInvitationMutationHookResult = ReturnType<typeof useRevokeWorkspaceInvitationMutation>;
export type RevokeWorkspaceInvitationMutationResult = Apollo.MutationResult<RevokeWorkspaceInvitationMutation>;
export type RevokeWorkspaceInvitationMutationOptions = Apollo.BaseMutationOptions<RevokeWorkspaceInvitationMutation, RevokeWorkspaceInvitationMutationVariables>;
export const SearchMentionDocument = gql`
    query SearchMention($workspaceId: ID!, $query: String!, $types: [SearchType!] = [user, doc]) {
  search(orgId: $workspaceId, query: $query, types: $types) {
    __typename
    ... on DocResult {
      id
      title
      text
      archived
      previewUrl
      createdBy {
        id
        displayName
        profileImageUrl
      }
      organization {
        id
      }
      editors {
        user {
          id
          displayName
          profileImageUrl
        }
        lastViewed
        lastEdited
      }
      docUser {
        lastViewed
        lastEdited
        favorited
      }
      orgAccess
      updatedTime
      createdTime
      editedTime
    }
    ... on User {
      id
      displayName
      email
      profileImageUrl
    }
  }
}
    `;

/**
 * __useSearchMentionQuery__
 *
 * To run a query within a React component, call `useSearchMentionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMentionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMentionQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *      types: // value for 'types'
 *   },
 * });
 */
export function useSearchMentionQuery(baseOptions: Apollo.QueryHookOptions<SearchMentionQuery, SearchMentionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMentionQuery, SearchMentionQueryVariables>(SearchMentionDocument, options);
      }
export function useSearchMentionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMentionQuery, SearchMentionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMentionQuery, SearchMentionQueryVariables>(SearchMentionDocument, options);
        }
export type SearchMentionQueryHookResult = ReturnType<typeof useSearchMentionQuery>;
export type SearchMentionLazyQueryHookResult = ReturnType<typeof useSearchMentionLazyQuery>;
export type SearchMentionQueryResult = Apollo.QueryResult<SearchMentionQuery, SearchMentionQueryVariables>;
export const AddCollaboratorsDocument = gql`
    mutation AddCollaborators($docId: ID!, $collaborators: [DocCollaboratorInput!]!) {
  addCollaborators(docId: $docId, collaborators: $collaborators) {
    id
    collaborators {
      ...DocCollaboratorFields
    }
    invitations {
      ...DocInvitationsFields
    }
  }
}
    ${DocCollaboratorFieldsFragmentDoc}
${DocInvitationsFieldsFragmentDoc}`;
export type AddCollaboratorsMutationFn = Apollo.MutationFunction<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>;

/**
 * __useAddCollaboratorsMutation__
 *
 * To run a mutation, you first call `useAddCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaboratorsMutation, { data, loading, error }] = useAddCollaboratorsMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *      collaborators: // value for 'collaborators'
 *   },
 * });
 */
export function useAddCollaboratorsMutation(baseOptions?: Apollo.MutationHookOptions<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>(AddCollaboratorsDocument, options);
      }
export type AddCollaboratorsMutationHookResult = ReturnType<typeof useAddCollaboratorsMutation>;
export type AddCollaboratorsMutationResult = Apollo.MutationResult<AddCollaboratorsMutation>;
export type AddCollaboratorsMutationOptions = Apollo.BaseMutationOptions<AddCollaboratorsMutation, AddCollaboratorsMutationVariables>;
export const RemoveCollaboratorsDocument = gql`
    mutation RemoveCollaborators($docId: ID!, $collaborators: [DocCollaboratorRemovalInput!]!) {
  removeCollaborators(docId: $docId, collaborators: $collaborators) {
    id
    collaborators {
      ...DocCollaboratorFields
    }
    invitations {
      ...DocInvitationsFields
    }
  }
}
    ${DocCollaboratorFieldsFragmentDoc}
${DocInvitationsFieldsFragmentDoc}`;
export type RemoveCollaboratorsMutationFn = Apollo.MutationFunction<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>;

/**
 * __useRemoveCollaboratorsMutation__
 *
 * To run a mutation, you first call `useRemoveCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCollaboratorsMutation, { data, loading, error }] = useRemoveCollaboratorsMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *      collaborators: // value for 'collaborators'
 *   },
 * });
 */
export function useRemoveCollaboratorsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>(RemoveCollaboratorsDocument, options);
      }
export type RemoveCollaboratorsMutationHookResult = ReturnType<typeof useRemoveCollaboratorsMutation>;
export type RemoveCollaboratorsMutationResult = Apollo.MutationResult<RemoveCollaboratorsMutation>;
export type RemoveCollaboratorsMutationOptions = Apollo.BaseMutationOptions<RemoveCollaboratorsMutation, RemoveCollaboratorsMutationVariables>;
export const AddCollaboratorByAccessLinkDocument = gql`
    mutation AddCollaboratorByAccessLink($docId: ID!) {
  addCollaboratorByAccessLink(docId: $docId) {
    id
    collaborators {
      ...DocCollaboratorFields
    }
  }
}
    ${DocCollaboratorFieldsFragmentDoc}`;
export type AddCollaboratorByAccessLinkMutationFn = Apollo.MutationFunction<AddCollaboratorByAccessLinkMutation, AddCollaboratorByAccessLinkMutationVariables>;

/**
 * __useAddCollaboratorByAccessLinkMutation__
 *
 * To run a mutation, you first call `useAddCollaboratorByAccessLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaboratorByAccessLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaboratorByAccessLinkMutation, { data, loading, error }] = useAddCollaboratorByAccessLinkMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useAddCollaboratorByAccessLinkMutation(baseOptions?: Apollo.MutationHookOptions<AddCollaboratorByAccessLinkMutation, AddCollaboratorByAccessLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCollaboratorByAccessLinkMutation, AddCollaboratorByAccessLinkMutationVariables>(AddCollaboratorByAccessLinkDocument, options);
      }
export type AddCollaboratorByAccessLinkMutationHookResult = ReturnType<typeof useAddCollaboratorByAccessLinkMutation>;
export type AddCollaboratorByAccessLinkMutationResult = Apollo.MutationResult<AddCollaboratorByAccessLinkMutation>;
export type AddCollaboratorByAccessLinkMutationOptions = Apollo.BaseMutationOptions<AddCollaboratorByAccessLinkMutation, AddCollaboratorByAccessLinkMutationVariables>;
export const GetDocDocument = gql`
    query GetDoc($id: ID!, $password: String) {
  doc(id: $id, password: $password) {
    id
    title
    titleEdited
    archived
    disabled
    createdTime
    updatedTime
    editedTime
    savedTime
    currentSnapshotId
    publishedSnapshotId
    publishedSnapshot {
      content
    }
    publishedTime
    comments {
      ...CommentFields
    }
    reactions {
      ...ReactionFields
    }
    accessLinks {
      id
      docId
      type
      permission
      token
      url
    }
    links {
      url
      status
    }
    orgAccess
    organization {
      id
      name
      products
    }
    theme {
      ...DefaultThemeFields
      fonts {
        id
        name
        url
      }
    }
    collaborators {
      ...DocCollaboratorFields
    }
    invitations {
      ...DocInvitationsFields
    }
    editors {
      ...DocEditorsFields
    }
    createdBy {
      displayName
      id
      email
    }
    channels {
      ...DocChannelFields
    }
    site {
      ...DocSiteFields
    }
    docGenerateInput {
      id
      settings
      docGeneration {
        id
        generationType
      }
    }
  }
}
    ${CommentFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}
${DefaultThemeFieldsFragmentDoc}
${DocCollaboratorFieldsFragmentDoc}
${DocInvitationsFieldsFragmentDoc}
${DocEditorsFieldsFragmentDoc}
${DocChannelFieldsFragmentDoc}
${DocSiteFieldsFragmentDoc}`;

/**
 * __useGetDocQuery__
 *
 * To run a query within a React component, call `useGetDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocQuery({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetDocQuery(baseOptions: Apollo.QueryHookOptions<GetDocQuery, GetDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocQuery, GetDocQueryVariables>(GetDocDocument, options);
      }
export function useGetDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocQuery, GetDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocQuery, GetDocQueryVariables>(GetDocDocument, options);
        }
export type GetDocQueryHookResult = ReturnType<typeof useGetDocQuery>;
export type GetDocLazyQueryHookResult = ReturnType<typeof useGetDocLazyQuery>;
export type GetDocQueryResult = Apollo.QueryResult<GetDocQuery, GetDocQueryVariables>;
export const GetDocRobotsDocument = gql`
    query GetDocRobots($id: ID!) {
  doc(id: $id) {
    id
    title
    createdTime
    updatedTime
    currentSnapshot {
      id
      docId
      content
    }
    titleCard {
      previewUrl
      text
    }
    organization {
      id
      name
      products
    }
    createdBy {
      displayName
      id
      email
    }
  }
}
    `;

/**
 * __useGetDocRobotsQuery__
 *
 * To run a query within a React component, call `useGetDocRobotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocRobotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocRobotsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocRobotsQuery(baseOptions: Apollo.QueryHookOptions<GetDocRobotsQuery, GetDocRobotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocRobotsQuery, GetDocRobotsQueryVariables>(GetDocRobotsDocument, options);
      }
export function useGetDocRobotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocRobotsQuery, GetDocRobotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocRobotsQuery, GetDocRobotsQueryVariables>(GetDocRobotsDocument, options);
        }
export type GetDocRobotsQueryHookResult = ReturnType<typeof useGetDocRobotsQuery>;
export type GetDocRobotsLazyQueryHookResult = ReturnType<typeof useGetDocRobotsLazyQuery>;
export type GetDocRobotsQueryResult = Apollo.QueryResult<GetDocRobotsQuery, GetDocRobotsQueryVariables>;
export const SubscribeToDocDocument = gql`
    subscription SubscribeToDoc($id: ID!) {
  doc(id: $id) {
    ... on DocSubscription {
      id
      title
      titleEdited
      archived
      updatedTime
      editedTime
      savedTime
      publishedTime
      accessLinks {
        id
        docId
        type
        permission
        token
        url
      }
      channels {
        ...DocChannelFields
      }
      collaborators {
        ...DocCollaboratorFields
      }
      editors {
        ...DocEditorsFields
      }
      invitations {
        ...DocInvitationsFields
      }
      theme {
        ...DefaultThemeFields
      }
      reactions {
        ...ReactionFields
      }
      orgAccess
    }
    ... on DocCommentsSubscription {
      id
      comments {
        ...CommentFields
      }
    }
    ... on DocReactionsSubscription {
      id
      reactions {
        ...ReactionFields
      }
    }
    ... on DocLinksSubscription {
      id
      links {
        url
        status
      }
    }
  }
}
    ${DocChannelFieldsFragmentDoc}
${DocCollaboratorFieldsFragmentDoc}
${DocEditorsFieldsFragmentDoc}
${DocInvitationsFieldsFragmentDoc}
${DefaultThemeFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}
${CommentFieldsFragmentDoc}`;

/**
 * __useSubscribeToDocSubscription__
 *
 * To run a query within a React component, call `useSubscribeToDocSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToDocSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeToDocSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSubscribeToDocSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeToDocSubscription, SubscribeToDocSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeToDocSubscription, SubscribeToDocSubscriptionVariables>(SubscribeToDocDocument, options);
      }
export type SubscribeToDocSubscriptionHookResult = ReturnType<typeof useSubscribeToDocSubscription>;
export type SubscribeToDocSubscriptionResult = Apollo.SubscriptionResult<SubscribeToDocSubscription>;
export const UpdateDocTitleDocument = gql`
    mutation UpdateDocTitle($id: ID!, $title: String, $titleEdited: Boolean) {
  updateDoc(input: {id: $id, title: $title, titleEdited: $titleEdited}) {
    id
    title
    titleEdited
  }
}
    `;
export type UpdateDocTitleMutationFn = Apollo.MutationFunction<UpdateDocTitleMutation, UpdateDocTitleMutationVariables>;

/**
 * __useUpdateDocTitleMutation__
 *
 * To run a mutation, you first call `useUpdateDocTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocTitleMutation, { data, loading, error }] = useUpdateDocTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      titleEdited: // value for 'titleEdited'
 *   },
 * });
 */
export function useUpdateDocTitleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocTitleMutation, UpdateDocTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocTitleMutation, UpdateDocTitleMutationVariables>(UpdateDocTitleDocument, options);
      }
export type UpdateDocTitleMutationHookResult = ReturnType<typeof useUpdateDocTitleMutation>;
export type UpdateDocTitleMutationResult = Apollo.MutationResult<UpdateDocTitleMutation>;
export type UpdateDocTitleMutationOptions = Apollo.BaseMutationOptions<UpdateDocTitleMutation, UpdateDocTitleMutationVariables>;
export const UpdateDocOrgAccessDocument = gql`
    mutation UpdateDocOrgAccess($id: ID!, $orgAccess: Permission) {
  updateDoc(input: {id: $id, orgAccess: $orgAccess}) {
    id
    orgAccess
  }
}
    `;
export type UpdateDocOrgAccessMutationFn = Apollo.MutationFunction<UpdateDocOrgAccessMutation, UpdateDocOrgAccessMutationVariables>;

/**
 * __useUpdateDocOrgAccessMutation__
 *
 * To run a mutation, you first call `useUpdateDocOrgAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocOrgAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocOrgAccessMutation, { data, loading, error }] = useUpdateDocOrgAccessMutation({
 *   variables: {
 *      id: // value for 'id'
 *      orgAccess: // value for 'orgAccess'
 *   },
 * });
 */
export function useUpdateDocOrgAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocOrgAccessMutation, UpdateDocOrgAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocOrgAccessMutation, UpdateDocOrgAccessMutationVariables>(UpdateDocOrgAccessDocument, options);
      }
export type UpdateDocOrgAccessMutationHookResult = ReturnType<typeof useUpdateDocOrgAccessMutation>;
export type UpdateDocOrgAccessMutationResult = Apollo.MutationResult<UpdateDocOrgAccessMutation>;
export type UpdateDocOrgAccessMutationOptions = Apollo.BaseMutationOptions<UpdateDocOrgAccessMutation, UpdateDocOrgAccessMutationVariables>;
export const UpdateDocAccessLinksDocument = gql`
    mutation UpdateDocAccessLinks($id: ID!, $accessLinks: [AccessLinkInput!]) {
  updateDoc(input: {id: $id, accessLinks: $accessLinks}) {
    id
    accessLinks {
      permission
      id
    }
  }
}
    `;
export type UpdateDocAccessLinksMutationFn = Apollo.MutationFunction<UpdateDocAccessLinksMutation, UpdateDocAccessLinksMutationVariables>;

/**
 * __useUpdateDocAccessLinksMutation__
 *
 * To run a mutation, you first call `useUpdateDocAccessLinksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocAccessLinksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocAccessLinksMutation, { data, loading, error }] = useUpdateDocAccessLinksMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accessLinks: // value for 'accessLinks'
 *   },
 * });
 */
export function useUpdateDocAccessLinksMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocAccessLinksMutation, UpdateDocAccessLinksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocAccessLinksMutation, UpdateDocAccessLinksMutationVariables>(UpdateDocAccessLinksDocument, options);
      }
export type UpdateDocAccessLinksMutationHookResult = ReturnType<typeof useUpdateDocAccessLinksMutation>;
export type UpdateDocAccessLinksMutationResult = Apollo.MutationResult<UpdateDocAccessLinksMutation>;
export type UpdateDocAccessLinksMutationOptions = Apollo.BaseMutationOptions<UpdateDocAccessLinksMutation, UpdateDocAccessLinksMutationVariables>;
export const UpdateDocThemeDocument = gql`
    mutation UpdateDocTheme($id: ID!, $themeId: String) {
  updateDoc(input: {id: $id, themeId: $themeId}) {
    id
    theme {
      ...DefaultThemeFields
    }
  }
}
    ${DefaultThemeFieldsFragmentDoc}`;
export type UpdateDocThemeMutationFn = Apollo.MutationFunction<UpdateDocThemeMutation, UpdateDocThemeMutationVariables>;

/**
 * __useUpdateDocThemeMutation__
 *
 * To run a mutation, you first call `useUpdateDocThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocThemeMutation, { data, loading, error }] = useUpdateDocThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useUpdateDocThemeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocThemeMutation, UpdateDocThemeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocThemeMutation, UpdateDocThemeMutationVariables>(UpdateDocThemeDocument, options);
      }
export type UpdateDocThemeMutationHookResult = ReturnType<typeof useUpdateDocThemeMutation>;
export type UpdateDocThemeMutationResult = Apollo.MutationResult<UpdateDocThemeMutation>;
export type UpdateDocThemeMutationOptions = Apollo.BaseMutationOptions<UpdateDocThemeMutation, UpdateDocThemeMutationVariables>;
export const InviteMembersDocument = gql`
    mutation InviteMembers($workspaceId: ID!, $invitees: [InvitationInput!]!) {
  inviteMembers(orgId: $workspaceId, invitees: $invitees) {
    id
    name
    invitations {
      email
    }
  }
}
    `;
export type InviteMembersMutationFn = Apollo.MutationFunction<InviteMembersMutation, InviteMembersMutationVariables>;

/**
 * __useInviteMembersMutation__
 *
 * To run a mutation, you first call `useInviteMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMembersMutation, { data, loading, error }] = useInviteMembersMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      invitees: // value for 'invitees'
 *   },
 * });
 */
export function useInviteMembersMutation(baseOptions?: Apollo.MutationHookOptions<InviteMembersMutation, InviteMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteMembersMutation, InviteMembersMutationVariables>(InviteMembersDocument, options);
      }
export type InviteMembersMutationHookResult = ReturnType<typeof useInviteMembersMutation>;
export type InviteMembersMutationResult = Apollo.MutationResult<InviteMembersMutation>;
export type InviteMembersMutationOptions = Apollo.BaseMutationOptions<InviteMembersMutation, InviteMembersMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($input: CreateCommentInput) {
  createComment(input: $input) {
    id
    docId
    cardId
    content
    targetText
    targetHtml
    createdTime
    updatedTime
    commentId
    archived
    targetId
    status
    user {
      ...CommentAuthorFields
    }
    replies {
      ...ReplyFields
    }
    reactions {
      ...ReactionFields
    }
  }
}
    ${CommentAuthorFieldsFragmentDoc}
${ReplyFieldsFragmentDoc}
${ReactionFieldsFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    id
    docId
    content
    updatedTime
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const UpdateCommentStatusDocument = gql`
    mutation UpdateCommentStatus($input: UpdateCommentStatusInput!) {
  updateCommentStatus(input: $input) {
    id
    status
    updatedTime
  }
}
    `;
export type UpdateCommentStatusMutationFn = Apollo.MutationFunction<UpdateCommentStatusMutation, UpdateCommentStatusMutationVariables>;

/**
 * __useUpdateCommentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCommentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentStatusMutation, { data, loading, error }] = useUpdateCommentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentStatusMutation, UpdateCommentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentStatusMutation, UpdateCommentStatusMutationVariables>(UpdateCommentStatusDocument, options);
      }
export type UpdateCommentStatusMutationHookResult = ReturnType<typeof useUpdateCommentStatusMutation>;
export type UpdateCommentStatusMutationResult = Apollo.MutationResult<UpdateCommentStatusMutation>;
export type UpdateCommentStatusMutationOptions = Apollo.BaseMutationOptions<UpdateCommentStatusMutation, UpdateCommentStatusMutationVariables>;
export const RemoveCommentDocument = gql`
    mutation RemoveComment($id: ID!) {
  archiveComment(id: $id) {
    id
    archived
  }
}
    `;
export type RemoveCommentMutationFn = Apollo.MutationFunction<RemoveCommentMutation, RemoveCommentMutationVariables>;

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCommentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCommentMutation, RemoveCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCommentMutation, RemoveCommentMutationVariables>(RemoveCommentDocument, options);
      }
export type RemoveCommentMutationHookResult = ReturnType<typeof useRemoveCommentMutation>;
export type RemoveCommentMutationResult = Apollo.MutationResult<RemoveCommentMutation>;
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<RemoveCommentMutation, RemoveCommentMutationVariables>;
export const AddReactionDocument = gql`
    mutation AddReaction($reactionInput: AddReactionInput!) {
  addReaction(input: $reactionInput) {
    ...ReactionFields
  }
}
    ${ReactionFieldsFragmentDoc}`;
export type AddReactionMutationFn = Apollo.MutationFunction<AddReactionMutation, AddReactionMutationVariables>;

/**
 * __useAddReactionMutation__
 *
 * To run a mutation, you first call `useAddReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReactionMutation, { data, loading, error }] = useAddReactionMutation({
 *   variables: {
 *      reactionInput: // value for 'reactionInput'
 *   },
 * });
 */
export function useAddReactionMutation(baseOptions?: Apollo.MutationHookOptions<AddReactionMutation, AddReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReactionMutation, AddReactionMutationVariables>(AddReactionDocument, options);
      }
export type AddReactionMutationHookResult = ReturnType<typeof useAddReactionMutation>;
export type AddReactionMutationResult = Apollo.MutationResult<AddReactionMutation>;
export type AddReactionMutationOptions = Apollo.BaseMutationOptions<AddReactionMutation, AddReactionMutationVariables>;
export const RemoveReactionDocument = gql`
    mutation RemoveReaction($reactionInput: RemoveReactionInput!) {
  removeReaction(input: $reactionInput) {
    ...ReactionFields
  }
}
    ${ReactionFieldsFragmentDoc}`;
export type RemoveReactionMutationFn = Apollo.MutationFunction<RemoveReactionMutation, RemoveReactionMutationVariables>;

/**
 * __useRemoveReactionMutation__
 *
 * To run a mutation, you first call `useRemoveReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReactionMutation, { data, loading, error }] = useRemoveReactionMutation({
 *   variables: {
 *      reactionInput: // value for 'reactionInput'
 *   },
 * });
 */
export function useRemoveReactionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReactionMutation, RemoveReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveReactionMutation, RemoveReactionMutationVariables>(RemoveReactionDocument, options);
      }
export type RemoveReactionMutationHookResult = ReturnType<typeof useRemoveReactionMutation>;
export type RemoveReactionMutationResult = Apollo.MutationResult<RemoveReactionMutation>;
export type RemoveReactionMutationOptions = Apollo.BaseMutationOptions<RemoveReactionMutation, RemoveReactionMutationVariables>;
export const GetMemoAnalyticsDocument = gql`
    query GetMemoAnalytics($memoId: ID!) {
  memoStats(id: $memoId) {
    memoId
    uniqueViewers
    uniqueEditors
    cardCount
    lastOpened
    viewers {
      user {
        id
        displayName
        email
        profileImageUrl
      }
      visitorId
      lastOpened
      cardsViewed
    }
    dailyViews {
      dayCount
      timezone
      everyone {
        date
        uniqueVisitors
      }
      viewers {
        date
        uniqueVisitors
      }
    }
  }
}
    `;

/**
 * __useGetMemoAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetMemoAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemoAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemoAnalyticsQuery({
 *   variables: {
 *      memoId: // value for 'memoId'
 *   },
 * });
 */
export function useGetMemoAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>(GetMemoAnalyticsDocument, options);
      }
export function useGetMemoAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>(GetMemoAnalyticsDocument, options);
        }
export type GetMemoAnalyticsQueryHookResult = ReturnType<typeof useGetMemoAnalyticsQuery>;
export type GetMemoAnalyticsLazyQueryHookResult = ReturnType<typeof useGetMemoAnalyticsLazyQuery>;
export type GetMemoAnalyticsQueryResult = Apollo.QueryResult<GetMemoAnalyticsQuery, GetMemoAnalyticsQueryVariables>;
export const GetDocCardsAnalyticsDocument = gql`
    query GetDocCardsAnalytics($docId: ID!, $userId: ID) {
  docCardsStats(docId: $docId, userId: $userId) {
    docId
    uniqueVisitors
    uniqueEditors
    cardCount
    everyone {
      cardId
      viewTimePercent
      viewersPercent
    }
    viewers {
      cardId
      viewTimePercent
      viewersPercent
    }
  }
}
    `;

/**
 * __useGetDocCardsAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetDocCardsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocCardsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocCardsAnalyticsQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDocCardsAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>(GetDocCardsAnalyticsDocument, options);
      }
export function useGetDocCardsAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>(GetDocCardsAnalyticsDocument, options);
        }
export type GetDocCardsAnalyticsQueryHookResult = ReturnType<typeof useGetDocCardsAnalyticsQuery>;
export type GetDocCardsAnalyticsLazyQueryHookResult = ReturnType<typeof useGetDocCardsAnalyticsLazyQuery>;
export type GetDocCardsAnalyticsQueryResult = Apollo.QueryResult<GetDocCardsAnalyticsQuery, GetDocCardsAnalyticsQueryVariables>;
export const SetDocPasswordDocument = gql`
    mutation SetDocPassword($docId: ID!, $password: String!) {
  setDocPassword(id: $docId, password: $password) {
    id
    accessLinks {
      id
      permission
      type
    }
  }
}
    `;
export type SetDocPasswordMutationFn = Apollo.MutationFunction<SetDocPasswordMutation, SetDocPasswordMutationVariables>;

/**
 * __useSetDocPasswordMutation__
 *
 * To run a mutation, you first call `useSetDocPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocPasswordMutation, { data, loading, error }] = useSetDocPasswordMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetDocPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetDocPasswordMutation, SetDocPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDocPasswordMutation, SetDocPasswordMutationVariables>(SetDocPasswordDocument, options);
      }
export type SetDocPasswordMutationHookResult = ReturnType<typeof useSetDocPasswordMutation>;
export type SetDocPasswordMutationResult = Apollo.MutationResult<SetDocPasswordMutation>;
export type SetDocPasswordMutationOptions = Apollo.BaseMutationOptions<SetDocPasswordMutation, SetDocPasswordMutationVariables>;
export const RemoveDocPasswordDocument = gql`
    mutation RemoveDocPassword($docId: ID!) {
  removeDocPassword(id: $docId) {
    id
    accessLinks {
      id
      permission
      type
    }
  }
}
    `;
export type RemoveDocPasswordMutationFn = Apollo.MutationFunction<RemoveDocPasswordMutation, RemoveDocPasswordMutationVariables>;

/**
 * __useRemoveDocPasswordMutation__
 *
 * To run a mutation, you first call `useRemoveDocPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDocPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDocPasswordMutation, { data, loading, error }] = useRemoveDocPasswordMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useRemoveDocPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDocPasswordMutation, RemoveDocPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDocPasswordMutation, RemoveDocPasswordMutationVariables>(RemoveDocPasswordDocument, options);
      }
export type RemoveDocPasswordMutationHookResult = ReturnType<typeof useRemoveDocPasswordMutation>;
export type RemoveDocPasswordMutationResult = Apollo.MutationResult<RemoveDocPasswordMutation>;
export type RemoveDocPasswordMutationOptions = Apollo.BaseMutationOptions<RemoveDocPasswordMutation, RemoveDocPasswordMutationVariables>;
export const GetDocPasswordDocument = gql`
    query GetDocPassword($docId: ID!) {
  getDocPassword(id: $docId)
}
    `;

/**
 * __useGetDocPasswordQuery__
 *
 * To run a query within a React component, call `useGetDocPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocPasswordQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useGetDocPasswordQuery(baseOptions: Apollo.QueryHookOptions<GetDocPasswordQuery, GetDocPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocPasswordQuery, GetDocPasswordQueryVariables>(GetDocPasswordDocument, options);
      }
export function useGetDocPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocPasswordQuery, GetDocPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocPasswordQuery, GetDocPasswordQueryVariables>(GetDocPasswordDocument, options);
        }
export type GetDocPasswordQueryHookResult = ReturnType<typeof useGetDocPasswordQuery>;
export type GetDocPasswordLazyQueryHookResult = ReturnType<typeof useGetDocPasswordLazyQuery>;
export type GetDocPasswordQueryResult = Apollo.QueryResult<GetDocPasswordQuery, GetDocPasswordQueryVariables>;
export const GetPublicDocDocument = gql`
    query GetPublicDoc($docId: ID!) {
  publicDoc(id: $docId) {
    id
    logoUrl
  }
}
    `;

/**
 * __useGetPublicDocQuery__
 *
 * To run a query within a React component, call `useGetPublicDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicDocQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *   },
 * });
 */
export function useGetPublicDocQuery(baseOptions: Apollo.QueryHookOptions<GetPublicDocQuery, GetPublicDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicDocQuery, GetPublicDocQueryVariables>(GetPublicDocDocument, options);
      }
export function useGetPublicDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicDocQuery, GetPublicDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicDocQuery, GetPublicDocQueryVariables>(GetPublicDocDocument, options);
        }
export type GetPublicDocQueryHookResult = ReturnType<typeof useGetPublicDocQuery>;
export type GetPublicDocLazyQueryHookResult = ReturnType<typeof useGetPublicDocLazyQuery>;
export type GetPublicDocQueryResult = Apollo.QueryResult<GetPublicDocQuery, GetPublicDocQueryVariables>;
export const GetDocCurrentSnapshotIdDocument = gql`
    query GetDocCurrentSnapshotId($id: ID!) {
  doc(id: $id) {
    id
    currentSnapshotId
  }
}
    `;

/**
 * __useGetDocCurrentSnapshotIdQuery__
 *
 * To run a query within a React component, call `useGetDocCurrentSnapshotIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocCurrentSnapshotIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocCurrentSnapshotIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocCurrentSnapshotIdQuery(baseOptions: Apollo.QueryHookOptions<GetDocCurrentSnapshotIdQuery, GetDocCurrentSnapshotIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocCurrentSnapshotIdQuery, GetDocCurrentSnapshotIdQueryVariables>(GetDocCurrentSnapshotIdDocument, options);
      }
export function useGetDocCurrentSnapshotIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocCurrentSnapshotIdQuery, GetDocCurrentSnapshotIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocCurrentSnapshotIdQuery, GetDocCurrentSnapshotIdQueryVariables>(GetDocCurrentSnapshotIdDocument, options);
        }
export type GetDocCurrentSnapshotIdQueryHookResult = ReturnType<typeof useGetDocCurrentSnapshotIdQuery>;
export type GetDocCurrentSnapshotIdLazyQueryHookResult = ReturnType<typeof useGetDocCurrentSnapshotIdLazyQuery>;
export type GetDocCurrentSnapshotIdQueryResult = Apollo.QueryResult<GetDocCurrentSnapshotIdQuery, GetDocCurrentSnapshotIdQueryVariables>;
export const CreateDocDocument = gql`
    mutation CreateDoc($workspaceId: ID!, $title: String, $themeId: String, $source: DocSource, $initialContent: BaseSnapshotInput, $channels: [DocChannelInput!], $orgAccess: Permission, $mainPage: Boolean) {
  createDoc(
    input: {orgId: $workspaceId, title: $title, source: $source, initialContent: $initialContent, channels: $channels, themeId: $themeId, orgAccess: $orgAccess, mainPage: $mainPage}
  ) {
    id
  }
}
    `;
export type CreateDocMutationFn = Apollo.MutationFunction<CreateDocMutation, CreateDocMutationVariables>;

/**
 * __useCreateDocMutation__
 *
 * To run a mutation, you first call `useCreateDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocMutation, { data, loading, error }] = useCreateDocMutation({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      title: // value for 'title'
 *      themeId: // value for 'themeId'
 *      source: // value for 'source'
 *      initialContent: // value for 'initialContent'
 *      channels: // value for 'channels'
 *      orgAccess: // value for 'orgAccess'
 *      mainPage: // value for 'mainPage'
 *   },
 * });
 */
export function useCreateDocMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocMutation, CreateDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocMutation, CreateDocMutationVariables>(CreateDocDocument, options);
      }
export type CreateDocMutationHookResult = ReturnType<typeof useCreateDocMutation>;
export type CreateDocMutationResult = Apollo.MutationResult<CreateDocMutation>;
export type CreateDocMutationOptions = Apollo.BaseMutationOptions<CreateDocMutation, CreateDocMutationVariables>;
export const ArchiveDocDocument = gql`
    mutation ArchiveDoc($id: ID!) {
  archiveDoc(id: $id) {
    id
    archived
  }
}
    `;
export type ArchiveDocMutationFn = Apollo.MutationFunction<ArchiveDocMutation, ArchiveDocMutationVariables>;

/**
 * __useArchiveDocMutation__
 *
 * To run a mutation, you first call `useArchiveDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveDocMutation, { data, loading, error }] = useArchiveDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveDocMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveDocMutation, ArchiveDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveDocMutation, ArchiveDocMutationVariables>(ArchiveDocDocument, options);
      }
export type ArchiveDocMutationHookResult = ReturnType<typeof useArchiveDocMutation>;
export type ArchiveDocMutationResult = Apollo.MutationResult<ArchiveDocMutation>;
export type ArchiveDocMutationOptions = Apollo.BaseMutationOptions<ArchiveDocMutation, ArchiveDocMutationVariables>;
export const DeleteDocDocument = gql`
    mutation DeleteDoc($id: ID!) {
  deleteDoc(id: $id)
}
    `;
export type DeleteDocMutationFn = Apollo.MutationFunction<DeleteDocMutation, DeleteDocMutationVariables>;

/**
 * __useDeleteDocMutation__
 *
 * To run a mutation, you first call `useDeleteDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocMutation, { data, loading, error }] = useDeleteDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocMutation, DeleteDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocMutation, DeleteDocMutationVariables>(DeleteDocDocument, options);
      }
export type DeleteDocMutationHookResult = ReturnType<typeof useDeleteDocMutation>;
export type DeleteDocMutationResult = Apollo.MutationResult<DeleteDocMutation>;
export type DeleteDocMutationOptions = Apollo.BaseMutationOptions<DeleteDocMutation, DeleteDocMutationVariables>;
export const UnArchiveDocDocument = gql`
    mutation UnArchiveDoc($id: ID!) {
  unarchiveDoc(id: $id) {
    id
    archived
  }
}
    `;
export type UnArchiveDocMutationFn = Apollo.MutationFunction<UnArchiveDocMutation, UnArchiveDocMutationVariables>;

/**
 * __useUnArchiveDocMutation__
 *
 * To run a mutation, you first call `useUnArchiveDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnArchiveDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unArchiveDocMutation, { data, loading, error }] = useUnArchiveDocMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnArchiveDocMutation(baseOptions?: Apollo.MutationHookOptions<UnArchiveDocMutation, UnArchiveDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnArchiveDocMutation, UnArchiveDocMutationVariables>(UnArchiveDocDocument, options);
      }
export type UnArchiveDocMutationHookResult = ReturnType<typeof useUnArchiveDocMutation>;
export type UnArchiveDocMutationResult = Apollo.MutationResult<UnArchiveDocMutation>;
export type UnArchiveDocMutationOptions = Apollo.BaseMutationOptions<UnArchiveDocMutation, UnArchiveDocMutationVariables>;
export const FavoriteDocDocument = gql`
    mutation FavoriteDoc($input: DocUserInput!) {
  updateDocUser(input: $input) {
    id
    docId
    lastViewed
    lastEdited
    favorited
  }
}
    `;
export type FavoriteDocMutationFn = Apollo.MutationFunction<FavoriteDocMutation, FavoriteDocMutationVariables>;

/**
 * __useFavoriteDocMutation__
 *
 * To run a mutation, you first call `useFavoriteDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteDocMutation, { data, loading, error }] = useFavoriteDocMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFavoriteDocMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteDocMutation, FavoriteDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FavoriteDocMutation, FavoriteDocMutationVariables>(FavoriteDocDocument, options);
      }
export type FavoriteDocMutationHookResult = ReturnType<typeof useFavoriteDocMutation>;
export type FavoriteDocMutationResult = Apollo.MutationResult<FavoriteDocMutation>;
export type FavoriteDocMutationOptions = Apollo.BaseMutationOptions<FavoriteDocMutation, FavoriteDocMutationVariables>;
export const GetPreviewChannelDocsDocument = gql`
    query GetPreviewChannelDocs($first: Int, $after: String, $channelId: ID!) {
  previewChannelDocs(first: $first, after: $after, channelId: $channelId) {
    edges {
      node {
        ...HomeDocFields
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${HomeDocFieldsFragmentDoc}`;

/**
 * __useGetPreviewChannelDocsQuery__
 *
 * To run a query within a React component, call `useGetPreviewChannelDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewChannelDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewChannelDocsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetPreviewChannelDocsQuery(baseOptions: Apollo.QueryHookOptions<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>(GetPreviewChannelDocsDocument, options);
      }
export function useGetPreviewChannelDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>(GetPreviewChannelDocsDocument, options);
        }
export type GetPreviewChannelDocsQueryHookResult = ReturnType<typeof useGetPreviewChannelDocsQuery>;
export type GetPreviewChannelDocsLazyQueryHookResult = ReturnType<typeof useGetPreviewChannelDocsLazyQuery>;
export type GetPreviewChannelDocsQueryResult = Apollo.QueryResult<GetPreviewChannelDocsQuery, GetPreviewChannelDocsQueryVariables>;
export const GetChannelDocActivityDocument = gql`
    query GetChannelDocActivity($first: Int, $channelId: ID!) {
  channelDocActivity(first: $first, channelId: $channelId) {
    edges {
      node {
        ...ChannelDocActivityFields
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${ChannelDocActivityFieldsFragmentDoc}`;

/**
 * __useGetChannelDocActivityQuery__
 *
 * To run a query within a React component, call `useGetChannelDocActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelDocActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelDocActivityQuery({
 *   variables: {
 *      first: // value for 'first'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetChannelDocActivityQuery(baseOptions: Apollo.QueryHookOptions<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>(GetChannelDocActivityDocument, options);
      }
export function useGetChannelDocActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>(GetChannelDocActivityDocument, options);
        }
export type GetChannelDocActivityQueryHookResult = ReturnType<typeof useGetChannelDocActivityQuery>;
export type GetChannelDocActivityLazyQueryHookResult = ReturnType<typeof useGetChannelDocActivityLazyQuery>;
export type GetChannelDocActivityQueryResult = Apollo.QueryResult<GetChannelDocActivityQuery, GetChannelDocActivityQueryVariables>;
export const GetDocsDocument = gql`
    query GetDocs($first: Int, $after: String, $workspaceId: ID, $createdBy: ID, $archived: Boolean, $sortBy: DocSortOrder, $isPrivate: Boolean, $channelId: ID) {
  docs(
    first: $first
    after: $after
    workspaceId: $workspaceId
    createdBy: $createdBy
    archived: $archived
    sortBy: $sortBy
    isPrivate: $isPrivate
    channelId: $channelId
  ) {
    edges {
      node {
        ...HomeDocFields
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${HomeDocFieldsFragmentDoc}`;

/**
 * __useGetDocsQuery__
 *
 * To run a query within a React component, call `useGetDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      workspaceId: // value for 'workspaceId'
 *      createdBy: // value for 'createdBy'
 *      archived: // value for 'archived'
 *      sortBy: // value for 'sortBy'
 *      isPrivate: // value for 'isPrivate'
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useGetDocsQuery(baseOptions?: Apollo.QueryHookOptions<GetDocsQuery, GetDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocsQuery, GetDocsQueryVariables>(GetDocsDocument, options);
      }
export function useGetDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocsQuery, GetDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocsQuery, GetDocsQueryVariables>(GetDocsDocument, options);
        }
export type GetDocsQueryHookResult = ReturnType<typeof useGetDocsQuery>;
export type GetDocsLazyQueryHookResult = ReturnType<typeof useGetDocsLazyQuery>;
export type GetDocsQueryResult = Apollo.QueryResult<GetDocsQuery, GetDocsQueryVariables>;
export const GetSharedDocsDocument = gql`
    query GetSharedDocs($first: Int, $after: String, $archived: Boolean, $sortBy: DocSortOrder) {
  sharedDocs(first: $first, after: $after, archived: $archived, sortBy: $sortBy) {
    edges {
      node {
        ...HomeDocFields
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${HomeDocFieldsFragmentDoc}`;

/**
 * __useGetSharedDocsQuery__
 *
 * To run a query within a React component, call `useGetSharedDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedDocsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      archived: // value for 'archived'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetSharedDocsQuery(baseOptions?: Apollo.QueryHookOptions<GetSharedDocsQuery, GetSharedDocsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSharedDocsQuery, GetSharedDocsQueryVariables>(GetSharedDocsDocument, options);
      }
export function useGetSharedDocsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSharedDocsQuery, GetSharedDocsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSharedDocsQuery, GetSharedDocsQueryVariables>(GetSharedDocsDocument, options);
        }
export type GetSharedDocsQueryHookResult = ReturnType<typeof useGetSharedDocsQuery>;
export type GetSharedDocsLazyQueryHookResult = ReturnType<typeof useGetSharedDocsLazyQuery>;
export type GetSharedDocsQueryResult = Apollo.QueryResult<GetSharedDocsQuery, GetSharedDocsQueryVariables>;
export const GetSnapshotsDocument = gql`
    query GetSnapshots($docId: ID!, $first: Int, $after: String) {
  snapshots(docId: $docId, first: $first, after: $after) {
    edges {
      node {
        id
        docId
        createdTime
        editors {
          displayName
          id
        }
        schemaVersion
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useGetSnapshotsQuery__
 *
 * To run a query within a React component, call `useGetSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSnapshotsQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetSnapshotsQuery(baseOptions: Apollo.QueryHookOptions<GetSnapshotsQuery, GetSnapshotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSnapshotsQuery, GetSnapshotsQueryVariables>(GetSnapshotsDocument, options);
      }
export function useGetSnapshotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSnapshotsQuery, GetSnapshotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSnapshotsQuery, GetSnapshotsQueryVariables>(GetSnapshotsDocument, options);
        }
export type GetSnapshotsQueryHookResult = ReturnType<typeof useGetSnapshotsQuery>;
export type GetSnapshotsLazyQueryHookResult = ReturnType<typeof useGetSnapshotsLazyQuery>;
export type GetSnapshotsQueryResult = Apollo.QueryResult<GetSnapshotsQuery, GetSnapshotsQueryVariables>;
export const DuplicateDocDocument = gql`
    mutation DuplicateDoc($duplicateDocInput: DuplicateDocInput!) {
  duplicateDoc(input: $duplicateDocInput) {
    id
    title
  }
}
    `;
export type DuplicateDocMutationFn = Apollo.MutationFunction<DuplicateDocMutation, DuplicateDocMutationVariables>;

/**
 * __useDuplicateDocMutation__
 *
 * To run a mutation, you first call `useDuplicateDocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateDocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateDocMutation, { data, loading, error }] = useDuplicateDocMutation({
 *   variables: {
 *      duplicateDocInput: // value for 'duplicateDocInput'
 *   },
 * });
 */
export function useDuplicateDocMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateDocMutation, DuplicateDocMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateDocMutation, DuplicateDocMutationVariables>(DuplicateDocDocument, options);
      }
export type DuplicateDocMutationHookResult = ReturnType<typeof useDuplicateDocMutation>;
export type DuplicateDocMutationResult = Apollo.MutationResult<DuplicateDocMutation>;
export type DuplicateDocMutationOptions = Apollo.BaseMutationOptions<DuplicateDocMutation, DuplicateDocMutationVariables>;
export const GetSnapshotDocument = gql`
    query GetSnapshot($snapshotId: ID!, $docId: ID!, $password: String) {
  snapshot(id: $snapshotId, docId: $docId, password: $password) {
    id
    content
    schemaVersion
  }
}
    `;

/**
 * __useGetSnapshotQuery__
 *
 * To run a query within a React component, call `useGetSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSnapshotQuery({
 *   variables: {
 *      snapshotId: // value for 'snapshotId'
 *      docId: // value for 'docId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetSnapshotQuery(baseOptions: Apollo.QueryHookOptions<GetSnapshotQuery, GetSnapshotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSnapshotQuery, GetSnapshotQueryVariables>(GetSnapshotDocument, options);
      }
export function useGetSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSnapshotQuery, GetSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSnapshotQuery, GetSnapshotQueryVariables>(GetSnapshotDocument, options);
        }
export type GetSnapshotQueryHookResult = ReturnType<typeof useGetSnapshotQuery>;
export type GetSnapshotLazyQueryHookResult = ReturnType<typeof useGetSnapshotLazyQuery>;
export type GetSnapshotQueryResult = Apollo.QueryResult<GetSnapshotQuery, GetSnapshotQueryVariables>;
export const GetDocForSnapshotViewerDocument = gql`
    query GetDocForSnapshotViewer($id: ID!) {
  doc(id: $id) {
    id
    currentSnapshotId
    createdTime
    title
    createdBy {
      profileImageUrl
      displayName
      id
    }
  }
}
    `;

/**
 * __useGetDocForSnapshotViewerQuery__
 *
 * To run a query within a React component, call `useGetDocForSnapshotViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocForSnapshotViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocForSnapshotViewerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDocForSnapshotViewerQuery(baseOptions: Apollo.QueryHookOptions<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>(GetDocForSnapshotViewerDocument, options);
      }
export function useGetDocForSnapshotViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>(GetDocForSnapshotViewerDocument, options);
        }
export type GetDocForSnapshotViewerQueryHookResult = ReturnType<typeof useGetDocForSnapshotViewerQuery>;
export type GetDocForSnapshotViewerLazyQueryHookResult = ReturnType<typeof useGetDocForSnapshotViewerLazyQuery>;
export type GetDocForSnapshotViewerQueryResult = Apollo.QueryResult<GetDocForSnapshotViewerQuery, GetDocForSnapshotViewerQueryVariables>;
export const ChannelActivityDocument = gql`
    subscription ChannelActivity($channelId: ID!) {
  channelDocActivity(channelId: $channelId) {
    editors {
      id
      displayName
      profileImageUrl
    }
    presenters {
      id
      displayName
      profileImageUrl
    }
    doc {
      ...HomeDocFields
    }
  }
}
    ${HomeDocFieldsFragmentDoc}`;

/**
 * __useChannelActivitySubscription__
 *
 * To run a query within a React component, call `useChannelActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useChannelActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelActivitySubscription({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useChannelActivitySubscription(baseOptions: Apollo.SubscriptionHookOptions<ChannelActivitySubscription, ChannelActivitySubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChannelActivitySubscription, ChannelActivitySubscriptionVariables>(ChannelActivityDocument, options);
      }
export type ChannelActivitySubscriptionHookResult = ReturnType<typeof useChannelActivitySubscription>;
export type ChannelActivitySubscriptionResult = Apollo.SubscriptionResult<ChannelActivitySubscription>;
export const GetChannelsDocument = gql`
    query GetChannels($workspaceId: ID!) {
  channels(workspaceId: $workspaceId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useGetChannelsQuery__
 *
 * To run a query within a React component, call `useGetChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetChannelsQuery(baseOptions: Apollo.QueryHookOptions<GetChannelsQuery, GetChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, options);
      }
export function useGetChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelsQuery, GetChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelsQuery, GetChannelsQueryVariables>(GetChannelsDocument, options);
        }
export type GetChannelsQueryHookResult = ReturnType<typeof useGetChannelsQuery>;
export type GetChannelsLazyQueryHookResult = ReturnType<typeof useGetChannelsLazyQuery>;
export type GetChannelsQueryResult = Apollo.QueryResult<GetChannelsQuery, GetChannelsQueryVariables>;
export const GetChannelDocument = gql`
    query GetChannel($id: ID!, $workspaceId: ID!) {
  channel(id: $id, workspaceId: $workspaceId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useGetChannelQuery__
 *
 * To run a query within a React component, call `useGetChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetChannelQuery(baseOptions: Apollo.QueryHookOptions<GetChannelQuery, GetChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelQuery, GetChannelQueryVariables>(GetChannelDocument, options);
      }
export function useGetChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelQuery, GetChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelQuery, GetChannelQueryVariables>(GetChannelDocument, options);
        }
export type GetChannelQueryHookResult = ReturnType<typeof useGetChannelQuery>;
export type GetChannelLazyQueryHookResult = ReturnType<typeof useGetChannelLazyQuery>;
export type GetChannelQueryResult = Apollo.QueryResult<GetChannelQuery, GetChannelQueryVariables>;
export const GetWorkspaceChannelsDocument = gql`
    query GetWorkspaceChannels($workspaceId: ID!, $first: Int, $after: String, $query: String, $sortBy: ChannelSortOrder) {
  workspaceChannels(
    workspaceId: $workspaceId
    first: $first
    after: $after
    query: $query
    sortBy: $sortBy
  ) {
    edges {
      node {
        ...ChannelFieldsFragment
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useGetWorkspaceChannelsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceChannelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGetWorkspaceChannelsQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>(GetWorkspaceChannelsDocument, options);
      }
export function useGetWorkspaceChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>(GetWorkspaceChannelsDocument, options);
        }
export type GetWorkspaceChannelsQueryHookResult = ReturnType<typeof useGetWorkspaceChannelsQuery>;
export type GetWorkspaceChannelsLazyQueryHookResult = ReturnType<typeof useGetWorkspaceChannelsLazyQuery>;
export type GetWorkspaceChannelsQueryResult = Apollo.QueryResult<GetWorkspaceChannelsQuery, GetWorkspaceChannelsQueryVariables>;
export const CreateChannelDocument = gql`
    mutation CreateChannel($input: CreateChannelInput!) {
  createChannel(input: $input) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type CreateChannelMutationFn = Apollo.MutationFunction<CreateChannelMutation, CreateChannelMutationVariables>;

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMutation, CreateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, options);
      }
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<CreateChannelMutation, CreateChannelMutationVariables>;
export const JoinChannelDocument = gql`
    mutation JoinChannel($channelId: ID!) {
  joinChannel(channelId: $channelId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type JoinChannelMutationFn = Apollo.MutationFunction<JoinChannelMutation, JoinChannelMutationVariables>;

/**
 * __useJoinChannelMutation__
 *
 * To run a mutation, you first call `useJoinChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinChannelMutation, { data, loading, error }] = useJoinChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useJoinChannelMutation(baseOptions?: Apollo.MutationHookOptions<JoinChannelMutation, JoinChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinChannelMutation, JoinChannelMutationVariables>(JoinChannelDocument, options);
      }
export type JoinChannelMutationHookResult = ReturnType<typeof useJoinChannelMutation>;
export type JoinChannelMutationResult = Apollo.MutationResult<JoinChannelMutation>;
export type JoinChannelMutationOptions = Apollo.BaseMutationOptions<JoinChannelMutation, JoinChannelMutationVariables>;
export const LeaveChannelDocument = gql`
    mutation LeaveChannel($channelId: ID!) {
  leaveChannel(channelId: $channelId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type LeaveChannelMutationFn = Apollo.MutationFunction<LeaveChannelMutation, LeaveChannelMutationVariables>;

/**
 * __useLeaveChannelMutation__
 *
 * To run a mutation, you first call `useLeaveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveChannelMutation, { data, loading, error }] = useLeaveChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useLeaveChannelMutation(baseOptions?: Apollo.MutationHookOptions<LeaveChannelMutation, LeaveChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveChannelMutation, LeaveChannelMutationVariables>(LeaveChannelDocument, options);
      }
export type LeaveChannelMutationHookResult = ReturnType<typeof useLeaveChannelMutation>;
export type LeaveChannelMutationResult = Apollo.MutationResult<LeaveChannelMutation>;
export type LeaveChannelMutationOptions = Apollo.BaseMutationOptions<LeaveChannelMutation, LeaveChannelMutationVariables>;
export const ArchiveChannelDocument = gql`
    mutation ArchiveChannel($channelId: ID!, $workspaceId: ID!) {
  archiveChannel(id: $channelId, workspaceId: $workspaceId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type ArchiveChannelMutationFn = Apollo.MutationFunction<ArchiveChannelMutation, ArchiveChannelMutationVariables>;

/**
 * __useArchiveChannelMutation__
 *
 * To run a mutation, you first call `useArchiveChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveChannelMutation, { data, loading, error }] = useArchiveChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useArchiveChannelMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveChannelMutation, ArchiveChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveChannelMutation, ArchiveChannelMutationVariables>(ArchiveChannelDocument, options);
      }
export type ArchiveChannelMutationHookResult = ReturnType<typeof useArchiveChannelMutation>;
export type ArchiveChannelMutationResult = Apollo.MutationResult<ArchiveChannelMutation>;
export type ArchiveChannelMutationOptions = Apollo.BaseMutationOptions<ArchiveChannelMutation, ArchiveChannelMutationVariables>;
export const UpdateChannelDocument = gql`
    mutation UpdateChannel($input: UpdateChannelInput) {
  updateChannel(input: $input) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;
export type UpdateChannelMutationFn = Apollo.MutationFunction<UpdateChannelMutation, UpdateChannelMutationVariables>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMutation, UpdateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(UpdateChannelDocument, options);
      }
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMutation, UpdateChannelMutationVariables>;
export const SearchChannelsDocument = gql`
    query SearchChannels($workspaceId: ID, $query: String!) {
  search(orgId: $workspaceId, query: $query, types: [doc, channel]) {
    __typename
    ... on Channel {
      ...ChannelFieldsFragment
    }
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useSearchChannelsQuery__
 *
 * To run a query within a React component, call `useSearchChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchChannelsQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchChannelsQuery(baseOptions: Apollo.QueryHookOptions<SearchChannelsQuery, SearchChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchChannelsQuery, SearchChannelsQueryVariables>(SearchChannelsDocument, options);
      }
export function useSearchChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchChannelsQuery, SearchChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchChannelsQuery, SearchChannelsQueryVariables>(SearchChannelsDocument, options);
        }
export type SearchChannelsQueryHookResult = ReturnType<typeof useSearchChannelsQuery>;
export type SearchChannelsLazyQueryHookResult = ReturnType<typeof useSearchChannelsLazyQuery>;
export type SearchChannelsQueryResult = Apollo.QueryResult<SearchChannelsQuery, SearchChannelsQueryVariables>;
export const ChannelsDocument = gql`
    subscription Channels($workspaceId: ID!) {
  channels(workspaceId: $workspaceId) {
    ...ChannelFieldsFragment
  }
}
    ${ChannelFieldsFragmentFragmentDoc}`;

/**
 * __useChannelsSubscription__
 *
 * To run a query within a React component, call `useChannelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChannelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsSubscription({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useChannelsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChannelsSubscription, ChannelsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChannelsSubscription, ChannelsSubscriptionVariables>(ChannelsDocument, options);
      }
export type ChannelsSubscriptionHookResult = ReturnType<typeof useChannelsSubscription>;
export type ChannelsSubscriptionResult = Apollo.SubscriptionResult<ChannelsSubscription>;
export const GetCardPreviewUrlsForDocDocument = gql`
    query GetCardPreviewUrlsForDoc($docId: ID!, $maxDepth: Int, $archived: Boolean) {
  docCards(docId: $docId, maxDepth: $maxDepth, archived: $archived) {
    docId
    previewUrl
    position
    depth
    id
    archived
  }
}
    `;

/**
 * __useGetCardPreviewUrlsForDocQuery__
 *
 * To run a query within a React component, call `useGetCardPreviewUrlsForDocQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardPreviewUrlsForDocQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardPreviewUrlsForDocQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      maxDepth: // value for 'maxDepth'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useGetCardPreviewUrlsForDocQuery(baseOptions: Apollo.QueryHookOptions<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>(GetCardPreviewUrlsForDocDocument, options);
      }
export function useGetCardPreviewUrlsForDocLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>(GetCardPreviewUrlsForDocDocument, options);
        }
export type GetCardPreviewUrlsForDocQueryHookResult = ReturnType<typeof useGetCardPreviewUrlsForDocQuery>;
export type GetCardPreviewUrlsForDocLazyQueryHookResult = ReturnType<typeof useGetCardPreviewUrlsForDocLazyQuery>;
export type GetCardPreviewUrlsForDocQueryResult = Apollo.QueryResult<GetCardPreviewUrlsForDocQuery, GetCardPreviewUrlsForDocQueryVariables>;
export const SendCodeDocument = gql`
    mutation SendCode($input: SendCodeInput!) {
  sendCode(input: $input) {
    emailSent
    provider
    link
  }
}
    `;
export type SendCodeMutationFn = Apollo.MutationFunction<SendCodeMutation, SendCodeMutationVariables>;

/**
 * __useSendCodeMutation__
 *
 * To run a mutation, you first call `useSendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCodeMutation, { data, loading, error }] = useSendCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendCodeMutation, SendCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCodeMutation, SendCodeMutationVariables>(SendCodeDocument, options);
      }
export type SendCodeMutationHookResult = ReturnType<typeof useSendCodeMutation>;
export type SendCodeMutationResult = Apollo.MutationResult<SendCodeMutation>;
export type SendCodeMutationOptions = Apollo.BaseMutationOptions<SendCodeMutation, SendCodeMutationVariables>;
export const VerifyCodeDocument = gql`
    query VerifyCode($input: VerifyCodeInput!) {
  verifyCode(input: $input) {
    verified
    isNew
  }
}
    `;

/**
 * __useVerifyCodeQuery__
 *
 * To run a query within a React component, call `useVerifyCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyCodeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyCodeQuery(baseOptions: Apollo.QueryHookOptions<VerifyCodeQuery, VerifyCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyCodeQuery, VerifyCodeQueryVariables>(VerifyCodeDocument, options);
      }
export function useVerifyCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyCodeQuery, VerifyCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyCodeQuery, VerifyCodeQueryVariables>(VerifyCodeDocument, options);
        }
export type VerifyCodeQueryHookResult = ReturnType<typeof useVerifyCodeQuery>;
export type VerifyCodeLazyQueryHookResult = ReturnType<typeof useVerifyCodeLazyQuery>;
export type VerifyCodeQueryResult = Apollo.QueryResult<VerifyCodeQuery, VerifyCodeQueryVariables>;
export const SigninDocument = gql`
    mutation Signin($input: SigninInput!) {
  signin(input: $input) {
    success
  }
}
    `;
export type SigninMutationFn = Apollo.MutationFunction<SigninMutation, SigninMutationVariables>;

/**
 * __useSigninMutation__
 *
 * To run a mutation, you first call `useSigninMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSigninMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signinMutation, { data, loading, error }] = useSigninMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSigninMutation(baseOptions?: Apollo.MutationHookOptions<SigninMutation, SigninMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SigninMutation, SigninMutationVariables>(SigninDocument, options);
      }
export type SigninMutationHookResult = ReturnType<typeof useSigninMutation>;
export type SigninMutationResult = Apollo.MutationResult<SigninMutation>;
export type SigninMutationOptions = Apollo.BaseMutationOptions<SigninMutation, SigninMutationVariables>;
export const SignupDocument = gql`
    mutation Signup($input: SignupInput!) {
  signup(input: $input) {
    id
    organizations {
      id
    }
    settings {
      showWorkspaceSetup
      showGetStarted
      welcomeDocId
    }
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    success
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const GetDocInvitationDocument = gql`
    query GetDocInvitation($docId: ID!, $email: String!, $inviteCode: String!) {
  docInvitation(docId: $docId, email: $email, inviteCode: $inviteCode) {
    docId
    docTitle
    email
    invitedBy {
      displayName
    }
    permission
  }
}
    `;

/**
 * __useGetDocInvitationQuery__
 *
 * To run a query within a React component, call `useGetDocInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocInvitationQuery({
 *   variables: {
 *      docId: // value for 'docId'
 *      email: // value for 'email'
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useGetDocInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetDocInvitationQuery, GetDocInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocInvitationQuery, GetDocInvitationQueryVariables>(GetDocInvitationDocument, options);
      }
export function useGetDocInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocInvitationQuery, GetDocInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocInvitationQuery, GetDocInvitationQueryVariables>(GetDocInvitationDocument, options);
        }
export type GetDocInvitationQueryHookResult = ReturnType<typeof useGetDocInvitationQuery>;
export type GetDocInvitationLazyQueryHookResult = ReturnType<typeof useGetDocInvitationLazyQuery>;
export type GetDocInvitationQueryResult = Apollo.QueryResult<GetDocInvitationQuery, GetDocInvitationQueryVariables>;
export const AcceptDocInvitationDocument = gql`
    mutation AcceptDocInvitation($docId: ID!, $email: String!, $inviteCode: String!) {
  acceptDocInvitation(docId: $docId, email: $email, inviteCode: $inviteCode) {
    id
  }
}
    `;
export type AcceptDocInvitationMutationFn = Apollo.MutationFunction<AcceptDocInvitationMutation, AcceptDocInvitationMutationVariables>;

/**
 * __useAcceptDocInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptDocInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptDocInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptDocInvitationMutation, { data, loading, error }] = useAcceptDocInvitationMutation({
 *   variables: {
 *      docId: // value for 'docId'
 *      email: // value for 'email'
 *      inviteCode: // value for 'inviteCode'
 *   },
 * });
 */
export function useAcceptDocInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptDocInvitationMutation, AcceptDocInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptDocInvitationMutation, AcceptDocInvitationMutationVariables>(AcceptDocInvitationDocument, options);
      }
export type AcceptDocInvitationMutationHookResult = ReturnType<typeof useAcceptDocInvitationMutation>;
export type AcceptDocInvitationMutationResult = Apollo.MutationResult<AcceptDocInvitationMutation>;
export type AcceptDocInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptDocInvitationMutation, AcceptDocInvitationMutationVariables>;
export const GetWorkspaceInvitationDocument = gql`
    query GetWorkspaceInvitation($email: String!, $inviteCode: String!, $workspaceId: String) {
  workspaceInvitation(
    email: $email
    inviteCode: $inviteCode
    workspaceId: $workspaceId
  ) {
    email
    workspaceId
    workspaceName
    invitedBy {
      displayName
    }
  }
}
    `;

/**
 * __useGetWorkspaceInvitationQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceInvitationQuery({
 *   variables: {
 *      email: // value for 'email'
 *      inviteCode: // value for 'inviteCode'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useGetWorkspaceInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>(GetWorkspaceInvitationDocument, options);
      }
export function useGetWorkspaceInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>(GetWorkspaceInvitationDocument, options);
        }
export type GetWorkspaceInvitationQueryHookResult = ReturnType<typeof useGetWorkspaceInvitationQuery>;
export type GetWorkspaceInvitationLazyQueryHookResult = ReturnType<typeof useGetWorkspaceInvitationLazyQuery>;
export type GetWorkspaceInvitationQueryResult = Apollo.QueryResult<GetWorkspaceInvitationQuery, GetWorkspaceInvitationQueryVariables>;
export const JoinWorkspaceDocument = gql`
    mutation JoinWorkspace($input: Invite!) {
  joinWorkspace(input: $input) {
    success
  }
}
    `;
export type JoinWorkspaceMutationFn = Apollo.MutationFunction<JoinWorkspaceMutation, JoinWorkspaceMutationVariables>;

/**
 * __useJoinWorkspaceMutation__
 *
 * To run a mutation, you first call `useJoinWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinWorkspaceMutation, { data, loading, error }] = useJoinWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useJoinWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<JoinWorkspaceMutation, JoinWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinWorkspaceMutation, JoinWorkspaceMutationVariables>(JoinWorkspaceDocument, options);
      }
export type JoinWorkspaceMutationHookResult = ReturnType<typeof useJoinWorkspaceMutation>;
export type JoinWorkspaceMutationResult = Apollo.MutationResult<JoinWorkspaceMutation>;
export type JoinWorkspaceMutationOptions = Apollo.BaseMutationOptions<JoinWorkspaceMutation, JoinWorkspaceMutationVariables>;