import {
  callOrReturn,
  ExtendedRegExpMatchArray,
  InputRuleFinder,
} from '@tiptap/core'
import { InputRule } from '@tiptap/react'
import { Node, NodeType } from 'prosemirror-model'
import { TextSelection } from 'prosemirror-state'

import { analytics, NodeInsertMethods, SegmentEvents } from 'modules/segment'
import { findParentNodeOfType } from 'modules/tiptap_editor/prosemirror-utils'

export const disallowParentsFromInputRule = (
  config: {
    find: RegExp
    type: NodeType
    getAttributes?: {
      [key: string]: any
    }
  },
  disallowedParentNodeTypes: NodeType[]
) => {
  const rule = textblockTypeInputRule(config)
  return new InputRule({
    find: config.find,
    handler: (props) => {
      const { state, range } = props
      const selection = new TextSelection(
        state.doc.resolve(range.from),
        state.doc.resolve(range.to)
      )
      const disallowedParent = findParentNodeOfType(disallowedParentNodeTypes)(
        selection
      )

      if (disallowedParent) {
        return null
      }
      // If we didn't find a disallowed parent, run the original rule handler.
      const handled = rule.handler(props)
      if (handled !== null) {
        analytics.track(SegmentEvents.NODE_INSERTED, {
          method: NodeInsertMethods.INPUT_RULE,
          node_name: config.type.name,
        })
      }
      return handled
    },
  })
}

/**
 * Build an input rule that changes the type of a textblock when the
 * matched text is typed into it. When using a regular expresion you’ll
 * probably want the regexp to start with `^`, so that the pattern can
 * only occur at the start of a textblock.
 * Forked from https://github.com/ueberdosis/tiptap/blob/2c1c557a8be845dbdffcb2e1d1f9a33bd0a2a4df/packages/core/src/inputRules/textblockTypeInputRule.ts#L1
 * to support retaining existing attributes
 */
export function textblockTypeInputRule(config: {
  find: InputRuleFinder
  type: NodeType
  getAttributes?:
    | Record<string, any>
    | ((match: ExtendedRegExpMatchArray) => Record<string, any>)
    | false
    | null
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }) => {
      const $start = state.doc.resolve(range.from)
      // Begin Gamma fork - keep existing node attributes when changing type
      const patchAttrs =
        callOrReturn(config.getAttributes, undefined, match) || {}
      const attributes = { ...$start.parent.attrs, ...patchAttrs }
      // End Gamma fork

      if (
        !$start
          .node(-1)
          .canReplaceWith($start.index(-1), $start.indexAfter(-1), config.type)
      ) {
        return
      }

      const { tr } = state
      tr.delete(range.from, range.to).setBlockType(
        range.from,
        range.from,
        config.type,
        attributes
      )
    },
  })
}

// Based on Tiptap's nodeInputRule, but adapted to replace the block
// rather than inserting after it
// https://github.com/ueberdosis/tiptap/blob/4bd507af56d9bd2c69cdff3e1b9bbb82fed4ef4c/packages/core/src/inputRules/nodeInputRule.ts#L11
export function blockInputRule(config: {
  find: InputRuleFinder
  type: NodeType
  getAttributes?:
    | Record<string, any>
    | ((match: ExtendedRegExpMatchArray) => Record<string, any>)
    | false
    | null
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }) => {
      const attributes =
        callOrReturn(config.getAttributes, undefined, match) || {}
      const { tr } = state
      const start = range.from
      const end = range.to

      const $from = state.doc.resolve(start)
      const parentPos = $from.before()
      tr.replaceWith(parentPos, end, config.type.create(attributes))
    },
  })
}

// Update a node attribute, if `getAttributes` returns a value for this node
export function attributeInputRule(config: {
  find: InputRuleFinder
  getAttributes?:
    | Record<string, any>
    | ((node: Node) => Record<string, any>)
    | false
    | null
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range }): void => {
      const $from = state.doc.resolve(range.from)
      const attributes = callOrReturn(
        config.getAttributes,
        undefined,
        $from.parent
      )
      if (!attributes) return

      // Remove the initial input, like "- " for a bullet
      const tr = state.tr.delete(range.from, range.to)
      // const $start = tr.doc.resolve(range.from)
      // const block = $start.parent
      Object.entries(attributes).forEach(([key, value]) => {
        tr.setNodeAttribute(range.from - 1, key, value)
      })
    },
  })
}

/**
 * Forked from Tiptap's nodeInputRule which broke at some point after this commit
 * https://github.com/gamma-app/tiptap/blob/f387ad3dd4c2b30eaea33fb0ba0b42e0cd39263b/packages/core/src/inputRules/nodeInputRule.ts
 * Build an input rule that adds a node when the
 * matched text is typed into it.
 */
export function nodeInputRule(config: {
  find: InputRuleFinder
  type: NodeType
  getAttributes?:
    | Record<string, any>
    | ((match: ExtendedRegExpMatchArray) => Record<string, any>)
    | false
    | null
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }) => {
      const attributes =
        callOrReturn(config.getAttributes, undefined, match) || {}
      const { tr } = state
      const start = range.from
      let end = range.to

      if (match[1]) {
        const offset = match[0].lastIndexOf(match[1])
        let matchStart = start + offset

        if (matchStart > end) {
          matchStart = end
        } else {
          end = matchStart + match[1].length
        }

        // insert last typed character
        const lastChar = match[0][match[0].length - 1]

        tr.insertText(lastChar, start + match[0].length - 1)

        // insert node from input rule
        tr.replaceWith(matchStart, end, config.type.create(attributes))
      } else if (match[0]) {
        tr.replaceWith(start, end, config.type.create(attributes))
      }
    },
  })
}
