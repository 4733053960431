// NB: This constants file is shared with the root middleware function
//     and thus should remain extremely pure of any external dependencies.

import {
  DEFAULT_GAMMA_LOCALE,
  FALLBACK_LOCALES as FALLBACK_LOCALE_KEYS,
  LOCALE_ROLLOUT_MAP,
  SUPPORTED_LOCALE_KEYS,
  SupportedLocaleKey,
} from '../constants'

export const getNavigatorLanguage = () => {
  if (typeof window === 'undefined') return
  return window.navigator.language.toLowerCase()
}

export const isLanguageSupported = (language = '') => {
  const localeKey = language.toLowerCase().trim()
  return (
    SUPPORTED_LOCALE_KEYS.includes(localeKey as SupportedLocaleKey) ||
    FALLBACK_LOCALE_KEYS[localeKey] !== undefined
  )
}

export const isNavigatorLanguageSupported = () =>
  isLanguageSupported(getNavigatorLanguage())

export const isNavigatorLanguageGA = () => {
  const navLang = getNavigatorLanguage()
  const localeKey = SUPPORTED_LOCALE_KEYS.includes(
    navLang as SupportedLocaleKey
  )
    ? navLang
    : FALLBACK_LOCALE_KEYS[navLang as SupportedLocaleKey]

  return LOCALE_ROLLOUT_MAP[localeKey as SupportedLocaleKey] === 'ga'
}

export const getSupportedLocaleKeyOrUseFallback = (
  locale?: string
): SupportedLocaleKey => {
  if (locale) {
    const localeKey = locale.toLowerCase() as SupportedLocaleKey
    if (SUPPORTED_LOCALE_KEYS.includes(localeKey)) {
      return localeKey
    } else if (FALLBACK_LOCALE_KEYS[localeKey] !== undefined) {
      return FALLBACK_LOCALE_KEYS[localeKey]
    }
  }
  return DEFAULT_GAMMA_LOCALE
}

export const getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback =
  (): SupportedLocaleKey => {
    return getSupportedLocaleKeyOrUseFallback(getNavigatorLanguage())
  }

// Example header format from https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language#syntax
// Multiple types, weighted with the quality value syntax:
// Accept-Language: fr-CH, fr;q=0.9, en;q=0.8, de;q=0.7, *;q=0.5
export const getPreferredLocaleFromAcceptLanguageHeader = (
  acceptLanguage = ''
) => {
  const preferredSupportedLanguage = acceptLanguage.split(',').find((lang) => {
    const [language] = lang.split(';')
    return isLanguageSupported(language.trim())
  })
  const languageString = preferredSupportedLanguage?.split(';')[0]
  return getSupportedLocaleKeyOrUseFallback(languageString?.trim())
}
