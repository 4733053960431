// NB: This constants file is shared with the root middleware function
//     and thus should remain extremely pure of any external dependencies.

const ORIGINAL_LAUNCH_DATE = '2022-11-20'
const LAUNCH_DATE_2 = '2023-11-20'
const LAUNCH_DATE_3 = '2024-11-20'

// Turn this to true to build all locales locally or in a custom version
export const __BUILD_ALL_LOCALES_IN_DEV__ = false

// Pseudo locale setup for debugging/QA purposes
export const PSEUDO_LOCALE_KEY = 'pseudo-LOCALE'
export const DEFAULT_GAMMA_LOCALE = 'en'
export const RTL_TEST_LOCALE = 'ar' // The locale used by default for testing RTL

/**
 * The status of a locale rollout.
 * - `private_beta`: You must be feature flagged to be able to choose this locale.
 *                   Reserved for early translations of new locales being developed.
 *
 * - `public_beta`:  Any user can choose this locale, and we'll show a NEW badge if it's less than 30 days from launch.
 *                   We default new users to this locale if their navigator.locale matches.
 *                   These locales are tier 2 and thus are machine translated ONLY.
 *
 * - `ga`:           We default new users to this locale if their navigator.locale matches.
 *                   These locales are tier 1 and thus are human translated.
 *
 *
 * See https://gamma.app/docs/5ubhk5zewalv11t#card-0enl3iw76n0o8w6
 */
export type LocaleRolloutStatus = 'private_beta' | 'public_beta' | 'ga'

export type SupportedLocaleKey = typeof SUPPORTED_LOCALE_KEYS[number]

export const LOCALE_ROLLOUT_MAP: Record<
  keyof typeof SUPPORTED_LOCALE_OPTIONS,
  LocaleRolloutStatus
> = {
  af: 'public_beta', // Afrikaans
  ar: 'private_beta', // Arabic
  bg: 'public_beta', // Bulgarian
  bn: 'public_beta', // Bengali
  bs: 'public_beta', // Bosnian
  ca: 'public_beta', // Catalan
  cs: 'public_beta', // Czech
  cy: 'public_beta', // Welsh
  da: 'public_beta', // Danish
  de: 'public_beta', // German
  en: 'ga', // English (source language)
  el: 'public_beta', // Greek
  es: 'ga', // Spanish
  et: 'public_beta', // Estonian
  fa: 'private_beta', // Farsi
  fi: 'public_beta', // Finnish
  fr: 'ga', // French
  gu: 'public_beta', // Gujarati
  ha: 'public_beta', // Hausa
  he: 'private_beta', // Hebrew
  hi: 'public_beta', // Hindi
  hr: 'public_beta', // Croatian
  hu: 'public_beta', // Hungarian
  id: 'public_beta', // Indonesian
  is: 'public_beta', // Icelandic
  it: 'public_beta', // Italian
  ja: 'ga', // Japanese
  kk: 'public_beta', // Kazakh
  kn: 'public_beta', // Kannada
  ko: 'ga', // Korean
  lt: 'public_beta', // Lithuanian
  lv: 'public_beta', // Latvian
  mk: 'public_beta', // Macedonian
  ml: 'public_beta', // Malayalam
  mr: 'public_beta', // Marathi
  ms: 'public_beta', // Malay
  nl: 'public_beta', // Dutch
  no: 'public_beta', // Norwegian
  pl: 'public_beta', // Polish
  'pt-br': 'ga', // Portuguese (Brazil)
  ro: 'public_beta', // Romanian
  ru: 'public_beta', // Russian
  sl: 'public_beta', // Slovenian
  sq: 'public_beta', // Albanian
  sr: 'public_beta', // Serbian
  sv: 'public_beta', // Swedish
  sw: 'public_beta', // Swahili
  ta: 'public_beta', // Tamil
  te: 'public_beta', // Telugu
  th: 'public_beta', // Thai
  tl: 'public_beta', // Tagalog
  tr: 'public_beta', // Turkish
  uk: 'public_beta', // Ukrainian
  ur: 'private_beta', // Urdu
  uz: 'public_beta', // Uzbek
  vi: 'public_beta', // Vietnamese
  yo: 'public_beta', // Yoruba
  'zh-cn': 'ga', // Simplified Chinese
  'zh-tw': 'ga', // Traditional Chinese
} as const

// Locales for which we have translations.
export const SUPPORTED_LOCALE_KEYS = [
  DEFAULT_GAMMA_LOCALE,
  'af',
  'ar',
  'bg',
  'bn',
  'bs',
  'ca',
  'cs',
  'cy',
  'da',
  'de',
  'el',
  'es',
  'et',
  'fa',
  'fi',
  'fr',
  'gu',
  'ha',
  'he',
  'hi',
  'hr',
  'hu',
  'id',
  'is',
  'it',
  'ja',
  'kk',
  'kn',
  'ko',
  'lt',
  'lv',
  'mk',
  'ml',
  'mr',
  'ms',
  'no',
  'nl',
  'pl',
  'pt-br',
  'ro',
  'ru',
  'sl',
  'sq',
  'sr',
  'sv',
  'sw',
  'ta',
  'te',
  'th',
  'tl',
  'tr',
  'ur',
  'uk',
  'uz',
  'vi',
  'yo',
  'zh-cn',
  'zh-tw',
  PSEUDO_LOCALE_KEY,
] as const

// If we don't support a locale, always fall back to English.
export const DEFAULT_FALLBACK_LOCALE = DEFAULT_GAMMA_LOCALE

// If a translation is missing, look in the default locale for the translation
// languages from http://www.lingoes.net/en/translator/langcode.htm
// No need to map English regions because the default locale is En.
export const FALLBACK_LOCALES: Record<string, SupportedLocaleKey> = {
  default: DEFAULT_FALLBACK_LOCALE,
  PSEUDO_LOCALE_KEY: DEFAULT_FALLBACK_LOCALE,

  // German
  'de-at': 'de',
  'de-be': 'de',
  'de-li': 'de',
  'de-lu': 'de',
  'de-ch': 'de',

  'ja-jp': 'ja',
  'ko-kr': 'ko',

  // French
  'fr-be': 'fr',
  'fr-ca': 'fr',
  'fr-ch': 'fr',
  'fr-fr': 'fr',
  'fr-lu': 'fr',
  'fr-mc': 'fr',

  'pt-pt': 'pt-br',

  // simplified chinese
  'zh-hans': 'zh-cn',
  'zh-sg': 'zh-cn',
  zh: 'zh-cn',

  // traditional chinese
  'zh-hant': 'zh-tw',
  'zh-hk': 'zh-tw',
  'zh-mo': 'zh-tw',

  // spanish
  'es-ar': 'es',
  'es-bo': 'es',
  'es-cl': 'es',
  'es-co': 'es',
  'es-cr': 'es',
  'es-do': 'es',
  'es-ec': 'es',
  'es-es': 'es',
  'es-gt': 'es',
  'es-hn': 'es',
  'es-mx': 'es',
  'es-ni': 'es',
  'es-pa': 'es',
  'es-pe': 'es',
  'es-pr': 'es',
  'es-py': 'es',
  'es-sv': 'es',
  'es-uy': 'es',
  'es-ve': 'es',
} as const

export type SupportedLocaleKeyWithoutPseudoLocale = Exclude<
  SupportedLocaleKey,
  typeof PSEUDO_LOCALE_KEY
>
// These will show in the language switcher.
export const SUPPORTED_LOCALE_OPTIONS: Record<
  SupportedLocaleKeyWithoutPseudoLocale,
  {
    key: SupportedLocaleKey
    displayName: string // The name of the language in the language itself
    displayNameLocalized: (t: (s: string) => string) => string // The name of the language localized to the current locale (written in English and wrapped for translation)
    contextName: string // The name of the language when used in a sentence
    launchDate:
      | typeof ORIGINAL_LAUNCH_DATE
      | typeof LAUNCH_DATE_2
      | typeof LAUNCH_DATE_3
    rtl?: boolean
  }
> = {
  // IMPORTANT! Do NOT add pseudo-LOCALE in here.
  [DEFAULT_GAMMA_LOCALE]: {
    key: DEFAULT_GAMMA_LOCALE,
    displayName: 'English',
    displayNameLocalized: (t) => t(/*i18n*/ 'English'),
    contextName: 'English',
    launchDate: ORIGINAL_LAUNCH_DATE,
  },
  af: {
    key: 'af',
    displayName: 'Afrikaans',
    displayNameLocalized: (t) => t(/*i18n*/ 'Afrikaans'),
    contextName: 'Afrikaans',
    launchDate: LAUNCH_DATE_3,
  },
  ar: {
    key: 'ar',
    displayName: 'العربية',
    displayNameLocalized: (t) => t(/*i18n*/ 'Arabic'),
    contextName: 'العربية',
    launchDate: LAUNCH_DATE_3,
    rtl: true,
  },
  bg: {
    key: 'bg',
    displayName: 'Български',
    displayNameLocalized: (t) => t(/*i18n*/ 'Bulgarian'),
    contextName: 'Български',
    launchDate: LAUNCH_DATE_3,
  },
  bn: {
    key: 'bn',
    displayName: 'বাংলা',
    displayNameLocalized: (t) => t(/*i18n*/ 'Bengali'),
    contextName: 'বাংলা',
    launchDate: LAUNCH_DATE_3,
  },
  bs: {
    key: 'bs',
    displayName: 'Bosanski',
    displayNameLocalized: (t) => t(/*i18n*/ 'Bosnian'),
    contextName: 'Bosanski',
    launchDate: LAUNCH_DATE_3,
  },
  ca: {
    key: 'ca',
    displayName: 'Català',
    displayNameLocalized: (t) => t(/*i18n*/ 'Catalan'),
    contextName: 'Català',
    launchDate: LAUNCH_DATE_3,
  },
  cs: {
    key: 'cs',
    displayName: 'Čeština',
    displayNameLocalized: (t) => t(/*i18n*/ 'Czech'),
    contextName: 'Čeština',
    launchDate: LAUNCH_DATE_3,
  },
  cy: {
    key: 'cy',
    displayName: 'Cymraeg',
    displayNameLocalized: (t) => t(/*i18n*/ 'Welsh'),
    contextName: 'Cymraeg',
    launchDate: LAUNCH_DATE_3,
  },
  da: {
    key: 'da',
    displayName: 'Dansk',
    displayNameLocalized: (t) => t(/*i18n*/ 'Danish'),
    contextName: 'Dansk',
    launchDate: LAUNCH_DATE_3,
  },
  de: {
    key: 'de',
    displayName: 'Deutsch',
    displayNameLocalized: (t) => t(/*i18n*/ 'German'),
    contextName: 'Deutsch',
    launchDate: LAUNCH_DATE_2,
  },
  es: {
    key: 'es',
    displayName: 'Español',
    displayNameLocalized: (t) => t(/*i18n*/ 'Spanish'),
    contextName: 'español',
    launchDate: ORIGINAL_LAUNCH_DATE,
  },
  el: {
    key: 'el',
    displayName: 'Ελληνικά',
    displayNameLocalized: (t) => t(/*i18n*/ 'Greek'),
    contextName: 'Ελληνικά',
    launchDate: LAUNCH_DATE_3,
  },
  et: {
    key: 'et',
    displayName: 'Eesti',
    displayNameLocalized: (t) => t(/*i18n*/ 'Estonian'),
    contextName: 'Eesti',
    launchDate: LAUNCH_DATE_3,
  },
  fa: {
    key: 'fa',
    displayName: 'فارسی',
    displayNameLocalized: (t) => t(/*i18n*/ 'Farsi'),
    contextName: 'فارسی',
    launchDate: LAUNCH_DATE_3,
    rtl: true,
  },
  fi: {
    key: 'fi',
    displayName: 'Suomi',
    displayNameLocalized: (t) => t(/*i18n*/ 'Finnish'),
    contextName: 'Suomi',
    launchDate: LAUNCH_DATE_3,
  },
  fr: {
    key: 'fr',
    displayName: 'Français',
    displayNameLocalized: (t) => t(/*i18n*/ 'French'),
    contextName: 'français',
    launchDate: ORIGINAL_LAUNCH_DATE,
  },
  gu: {
    key: 'gu',
    displayName: 'ગુજરાતી',
    displayNameLocalized: (t) => t(/*i18n*/ 'Gujarati'),
    contextName: 'ગુજરાતી',
    launchDate: LAUNCH_DATE_3,
  },
  ha: {
    key: 'ha',
    displayName: 'Hausa',
    displayNameLocalized: (t) => t(/*i18n*/ 'Hausa'),
    contextName: 'Hausa',
    launchDate: LAUNCH_DATE_3,
  },
  he: {
    key: 'he',
    displayName: 'עברית',
    displayNameLocalized: (t) => t(/*i18n*/ 'Hebrew'),
    contextName: 'עברית',
    launchDate: LAUNCH_DATE_3,
    rtl: true,
  },
  hi: {
    key: 'hi',
    displayName: 'हिन्दी',
    displayNameLocalized: (t) => t(/*i18n*/ 'Hindi'),
    contextName: 'हिन्दी',
    launchDate: LAUNCH_DATE_3,
  },
  hr: {
    key: 'hr',
    displayName: 'Hrvatski',
    displayNameLocalized: (t) => t(/*i18n*/ 'Croatian'),
    contextName: 'Hrvatski',
    launchDate: LAUNCH_DATE_3,
  },
  hu: {
    key: 'hu',
    displayName: 'Magyar',
    displayNameLocalized: (t) => t(/*i18n*/ 'Hungarian'),
    contextName: 'Magyar',
    launchDate: LAUNCH_DATE_3,
  },
  id: {
    key: 'id',
    displayName: 'Bahasa Indonesia',
    displayNameLocalized: (t) => t(/*i18n*/ 'Indonesian'),
    contextName: 'Bahasa Indonesia',
    launchDate: LAUNCH_DATE_2,
  },
  is: {
    key: 'is',
    displayName: 'Íslenska',
    displayNameLocalized: (t) => t(/*i18n*/ 'Icelandic'),
    contextName: 'Íslenska',
    launchDate: LAUNCH_DATE_3,
  },
  it: {
    key: 'it',
    displayName: 'Italiano',
    displayNameLocalized: (t) => t(/*i18n*/ 'Italian'),
    contextName: 'Italiano',
    launchDate: LAUNCH_DATE_3,
  },
  ja: {
    key: 'ja',
    displayName: '日本語',
    displayNameLocalized: (t) => t(/*i18n*/ 'Japanese'),
    contextName: '日本語',
    launchDate: ORIGINAL_LAUNCH_DATE,
  },
  kk: {
    key: 'kk',
    displayName: 'Қазақша',
    displayNameLocalized: (t) => t(/*i18n*/ 'Kazakh'),
    contextName: 'Қазақша',
    launchDate: LAUNCH_DATE_3,
  },
  kn: {
    key: 'kn',
    displayName: 'ಕನ್ನಡ',
    displayNameLocalized: (t) => t(/*i18n*/ 'Kannada'),
    contextName: 'ಕನ್ನಡ',
    launchDate: LAUNCH_DATE_3,
  },
  ko: {
    key: 'ko',
    displayName: '한국어',
    displayNameLocalized: (t) => t(/*i18n*/ 'Korean'),
    contextName: '한국어',
    launchDate: ORIGINAL_LAUNCH_DATE,
  },
  lt: {
    key: 'lt',
    displayName: 'Lietuvių',
    displayNameLocalized: (t) => t(/*i18n*/ 'Lithuanian'),
    contextName: 'Lietuvių',
    launchDate: LAUNCH_DATE_3,
  },
  lv: {
    key: 'lv',
    displayName: 'Latviešu',
    displayNameLocalized: (t) => t(/*i18n*/ 'Latvian'),
    contextName: 'Latviešu',
    launchDate: LAUNCH_DATE_3,
  },
  mk: {
    key: 'mk',
    displayName: 'Македонски',
    displayNameLocalized: (t) => t(/*i18n*/ 'Macedonian'),
    contextName: 'Македонски',
    launchDate: LAUNCH_DATE_3,
  },
  ml: {
    key: 'ml',
    displayName: 'മലയാളം',
    displayNameLocalized: (t) => t(/*i18n*/ 'Malayalam'),
    contextName: 'മലയാളം',
    launchDate: LAUNCH_DATE_3,
  },
  mr: {
    key: 'mr',
    displayName: 'मराठी',
    displayNameLocalized: (t) => t(/*i18n*/ 'Marathi'),
    contextName: 'मराठी',
    launchDate: LAUNCH_DATE_3,
  },
  ms: {
    key: 'ms',
    displayName: 'Bahasa Melayu',
    displayNameLocalized: (t) => t(/*i18n*/ 'Malay'),
    contextName: 'Bahasa Melayu',
    launchDate: LAUNCH_DATE_3,
  },
  no: {
    key: 'no',
    displayName: 'Norsk',
    displayNameLocalized: (t) => t(/*i18n*/ 'Norwegian'),
    contextName: 'Norsk',
    launchDate: LAUNCH_DATE_3,
  },
  nl: {
    key: 'nl',
    displayName: 'Nederlands',
    displayNameLocalized: (t) => t(/*i18n*/ 'Dutch'),
    contextName: 'Nederlands',
    launchDate: LAUNCH_DATE_3,
  },
  pl: {
    key: 'pl',
    displayName: 'Polski',
    displayNameLocalized: (t) => t(/*i18n*/ 'Polish'),
    contextName: 'Polski',
    launchDate: LAUNCH_DATE_3,
  },
  'pt-br': {
    key: 'pt-br',
    displayName: 'Português (Brasil)',
    displayNameLocalized: (t) => t(/*i18n*/ 'Portuguese (Brazil)'),
    contextName: 'português',
    launchDate: ORIGINAL_LAUNCH_DATE,
  },
  ro: {
    key: 'ro',
    displayName: 'Română',
    displayNameLocalized: (t) => t(/*i18n*/ 'Romanian'),
    contextName: 'Română',
    launchDate: LAUNCH_DATE_3,
  },
  ru: {
    key: 'ru',
    displayName: 'Русский',
    displayNameLocalized: (t) => t(/*i18n*/ 'Russian'),
    contextName: 'Русский',
    launchDate: LAUNCH_DATE_2,
  },
  sl: {
    key: 'sl',
    displayName: 'Slovenščina',
    displayNameLocalized: (t) => t(/*i18n*/ 'Slovenian'),
    contextName: 'Slovenščina',
    launchDate: LAUNCH_DATE_3,
  },
  sq: {
    key: 'sq',
    displayName: 'Shqip',
    displayNameLocalized: (t) => t(/*i18n*/ 'Albanian'),
    contextName: 'Shqip',
    launchDate: LAUNCH_DATE_3,
  },
  sr: {
    key: 'sr',
    displayName: 'Српски',
    displayNameLocalized: (t) => t(/*i18n*/ 'Serbian'),
    contextName: 'Српски',
    launchDate: LAUNCH_DATE_3,
  },
  sv: {
    key: 'sv',
    displayName: 'Svenska',
    displayNameLocalized: (t) => t(/*i18n*/ 'Swedish'),
    contextName: 'Svenska',
    launchDate: LAUNCH_DATE_3,
  },
  sw: {
    key: 'sw',
    displayName: 'Kiswahili',
    displayNameLocalized: (t) => t(/*i18n*/ 'Swahili'),
    contextName: 'Kiswahili',
    launchDate: LAUNCH_DATE_3,
  },
  ta: {
    key: 'ta',
    displayName: 'தமிழ்',
    displayNameLocalized: (t) => t(/*i18n*/ 'Tamil'),
    contextName: 'தமிழ்',
    launchDate: LAUNCH_DATE_3,
  },
  te: {
    key: 'te',
    displayName: 'తెలుగు',
    displayNameLocalized: (t) => t(/*i18n*/ 'Telugu'),
    contextName: 'తెలుగు',
    launchDate: LAUNCH_DATE_3,
  },
  th: {
    key: 'th',
    displayName: 'ไทย',
    displayNameLocalized: (t) => t(/*i18n*/ 'Thai'),
    contextName: 'ไทย',
    launchDate: LAUNCH_DATE_2,
  },
  tl: {
    key: 'tl',
    displayName: 'Tagalog',
    displayNameLocalized: (t) => t(/*i18n*/ 'Tagalog'),
    contextName: 'Tagalog',
    launchDate: LAUNCH_DATE_3,
  },
  tr: {
    key: 'tr',
    displayName: 'Türkçe',
    displayNameLocalized: (t) => t(/*i18n*/ 'Turkish'),
    contextName: 'Türkçe',
    launchDate: LAUNCH_DATE_2,
  },
  uk: {
    key: 'uk',
    displayName: 'Українська',
    displayNameLocalized: (t) => t(/*i18n*/ 'Ukrainian'),
    contextName: 'Українська',
    launchDate: LAUNCH_DATE_3,
  },
  ur: {
    key: 'ur',
    displayName: 'اردو',
    displayNameLocalized: (t) => t(/*i18n*/ 'Urdu'),
    contextName: 'اردو',
    launchDate: LAUNCH_DATE_3,
    rtl: true,
  },
  uz: {
    key: 'uz',
    displayName: 'O‘zbek',
    displayNameLocalized: (t) => t(/*i18n*/ 'Uzbek'),
    contextName: 'O‘zbek',
    launchDate: LAUNCH_DATE_3,
  },
  vi: {
    key: 'vi',
    displayName: 'Tiếng Việt',
    displayNameLocalized: (t) => t(/*i18n*/ 'Vietnamese'),
    contextName: 'Tiếng Việt',
    launchDate: LAUNCH_DATE_3,
  },
  yo: {
    key: 'yo',
    displayName: 'Yoruba',
    displayNameLocalized: (t) => t(/*i18n*/ 'Yoruba'),
    contextName: 'Yoruba',
    launchDate: LAUNCH_DATE_3,
  },
  'zh-cn': {
    key: 'zh-cn',
    displayName: '简体中文',
    displayNameLocalized: (t) => t(/*i18n*/ 'Chinese (Simplified)'),
    contextName: '简体中文',
    launchDate: ORIGINAL_LAUNCH_DATE,
  },
  'zh-tw': {
    key: 'zh-tw',
    displayName: '繁體中文',
    displayNameLocalized: (t) => t(/*i18n*/ 'Chinese (Traditional)'),
    contextName: '繁體中文',
    launchDate: ORIGINAL_LAUNCH_DATE,
  },
} as const

export const FORCE_LANG_QUERY_PARAM = 'lang'
