export const CARD_INNER_PADDING_X = 3.5 // In ems. Will scale with the font size
export const CARD_MOBILE_INNER_PADDING_X = 1.5 // In ems. Will scale with the font size
export const CARD_INNER_PADDING_Y = 2.75 // In ems. Will scale with the font size
export const CARD_MOBILE_INNER_PADDING_Y = 1.5 // In ems. Will scale with the font size
export const NESTED_CARD_MOBILE_INNER_PADDING_Y = 2.25 // allow for collapse and expand buttons to not cover content
export const CARD_OUTER_PADDING_Y = 2
export const CARD_OUTER_PADDING_Y_MOBILE = 1

export const CARD_OUTER_PADDING_X = 2
export const CARD_OUTER_PADDING_X_MOBILE = 0.5
export const CARD_BACKGROUND_PADDING = 8

export const CHAR_WIDTH = 0.5 // The average width of a character, relative to its height. Future: update based on font

export const NARROW_CONTENT_WIDTH_CHARS = 60
export const NORMAL_CONTENT_WIDTH_CHARS = 95
export const WIDE_CONTENT_WIDTH_CHARS = 115
export const CONTENT_WIDTH_EM = NORMAL_CONTENT_WIDTH_CHARS * CHAR_WIDTH
