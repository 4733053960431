import { config } from 'config'
import { getProductForWorkspace } from 'modules/monetization/utils'

import type { GraphqlUser } from '../user/context/UserContext'

declare global {
  interface Window {
    Canny: any
  }
}

export const cannyIdentify = ({ user }: { user: GraphqlUser }) => {
  const canny = window.Canny

  if (canny) {
    canny('identify', {
      appID: config.CANNY_APP_ID,
      user: {
        // Replace these values with the current user's data
        email: user?.email,
        name: user?.displayName,
        id: user?.id,

        // These fields are optional, but recommended:
        avatarURL: user.profileImageUrl,
        created: user ? new Date(user?.createdTime).toISOString() : undefined,

        customFields: {
          jobFunction: user?.jobFunction,
        },
        companies:
          user?.workspaceMemberships &&
          user?.workspaceMemberships.map((workspaceMembership) => {
            const workspace = workspaceMembership.workspace
            return {
              created: workspace
                ? new Date(workspace.createdTime).toISOString()
                : undefined, // optional
              customFields: {
                products: getProductForWorkspace(workspace),
              },
              id: workspace?.id,
              //   monthlySpend: workspace?.products, // TODO
              name: workspace?.name,
            }
          }),
      },
    })
  }
}

export const cannyInitChangeLog = ({ align = 'right' }) => {
  const canny = window.Canny

  if (canny) {
    canny('initChangelog', {
      appID: config.CANNY_APP_ID,
      position: 'bottom',
      align,
      theme: 'light',
    })
  }
}
