import {
  Attribute,
  callOrReturn,
  Extension,
  findChildrenInRange,
  getExtensionField,
  InputRule,
} from '@tiptap/core'
import { Node } from 'prosemirror-model'

import { attributeInputRule } from 'modules/tiptap_editor/utils/inputRules'

import { UpdateNodeAttrsAnnotationEvent } from '../Annotatable/AnnotationExtension/types'
import { getFontSizeOptions } from './constants'
import { FontSizePlugin } from './FontSizePlugin'
import { getFontSizeOption } from './utils'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontSize: {
      /**
       * Set the text size (sm, md, lg)
       */
      setFontSize: (size: FontSize) => ReturnType
    }
  }
}

// Note: to enable font sizing on a node, it needs a `fontSize` attrs and
// should have `allowFontSizes` in the node definition. It should be set to
// "body", "heading", or a combination like "body heading"

export type FontSize = string | null

export const FontSize = Extension.create({
  name: 'fontSize',

  addProseMirrorPlugins() {
    return [FontSizePlugin()]
  },

  addInputRules() {
    return Object.entries(getFontSizeOptions())
      .map(([key, { inputRegex, type }]) => {
        if (!inputRegex) return null
        return attributeInputRule({
          find: inputRegex,
          getAttributes: (node) => {
            if (
              type === 'heading' &&
              !allowedFontSizes(node).includes('heading')
            ) {
              return null
            }

            if (type === 'title' && !allowedFontSizes(node).includes('title')) {
              return null
            }

            return { fontSize: key }
          },
        })
      })
      .filter((rule): rule is InputRule => !!rule)
  },

  addKeyboardShortcuts() {
    // Match the keyboard shortcuts in https://github.com/ueberdosis/tiptap/blob/a41aa1f6e19d4ad1b9e77b8b053bf0996098355b/packages/extension-heading/src/heading.ts#L87
    const shortcuts = Object.entries(getFontSizeOptions()).reduce(
      (items, [key, { level, type }]) => {
        if (type !== 'heading' || !level) return items
        return {
          ...items,
          [`Mod-Alt-${level}`]: () => this.editor.commands.setFontSize(key),
        }
      },
      {}
    )
    return shortcuts
  },

  addCommands() {
    return {
      setFontSize:
        (size) =>
        ({ tr, dispatch, state }) => {
          if (!dispatch) return true

          tr.selection.ranges.forEach((range) => {
            const from = range.$from.pos
            const to = range.$to.pos

            const { type, level } = getFontSizeOption(size)
            const { nodes } = state.schema

            // Find all the nodes in the selection and update their attrs and type
            state.doc.nodesBetween(from, to, (node, pos): boolean | void => {
              // Don't reach inside footnotes to change sizes
              if (node.type.name === 'footnote') {
                return false
              }

              if (!node.isTextblock) return

              // Changing to a heading size
              if (
                type === 'heading' &&
                !allowedFontSizes(node).includes('heading')
              ) {
                tr.setNodeMarkup(pos, nodes.heading, {
                  ...node.attrs,
                  level,
                }).setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
                  type: 'update-node-attrs',
                  pos,
                })
                return
              }

              // Changing to a title size
              if (
                type === 'title' &&
                !allowedFontSizes(node).includes('title')
              ) {
                tr.setNodeMarkup(pos, nodes.title, {
                  ...node.attrs,
                  level,
                }).setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
                  type: 'update-node-attrs',
                  pos,
                })
                return
              }

              // Changing to a body size, when the node supports fontSize type
              if (node.type.spec.attrs?.fontSize) {
                // If this node already supports fontSize, just change it
                tr.setNodeMarkup(pos, undefined, {
                  ...node.attrs,
                  fontSize: size,
                }).setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
                  type: 'update-node-attrs',
                  pos,
                })
              } else if (['heading', 'title'].includes(node.type.name)) {
                // If it's a heading, switch to paragraph
                tr.setNodeMarkup(pos, nodes.paragraph, {
                  ...node.attrs,
                  fontSize: size,
                }).setMeta('annotationEvent', <UpdateNodeAttrsAnnotationEvent>{
                  type: 'update-node-attrs',
                  pos,
                })
              }
              // Otherwise, leave it alone
            })
          })

          return true
        },
    }
  },

  extendNodeSchema(extension) {
    return {
      allowFontSizes:
        callOrReturn(
          getExtensionField(extension, 'allowFontSizes', extension)
        ) ?? '',
    }
  },
})

// Within a selection, find which text size(s) are present
export const getSelectedFontSizes = (editor) => {
  const { state } = editor
  const { from, to } = state.selection
  const nodes = findChildrenInRange(
    state.doc,
    { from, to },
    (n) => n.isTextblock
  )

  const sizes = nodes
    .reverse()
    .map(({ node }) => node.attrs.fontSize)
    .filter((val) => val !== undefined)

  return [...new Set(sizes)]
}

export const allowedFontSizes = (node: Node): string[] => {
  return node.type.spec.allowFontSizes?.split(' ') ?? []
}

export const FontSizeAttr: Attribute = {
  default: null,
  keepOnSplit: true,
  renderHTML(attributes) {
    return {
      'data-font-size': attributes.fontSize,
    }
  },
  parseHTML(elt) {
    return elt.getAttribute('data-font-size')
  },
}
